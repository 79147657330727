import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ITicket } from '@app/core/interfaces/i-ticket';
import { IBetItem } from '@app/features/pik4/interfaces/i-bet-item';
import { convertComb } from '@app/core/utils';

/**
 * Интерфейс для ставок, сгруппированных по времени регистрации и названию тиража
 */
interface IBetsObject {
  [ts: number]: {
    [drawName: string]: Array<ITicket>;
  }
}

/**
 * Функция для получения ближайшей даты розыгрыша тиража из общего массива билетов (ставок)
 * @param tickets Общий массив билетов (ставок)
 */
const getNearestDrawTS = (tickets: Array<ITicket>): number => Math.min(
  ...tickets
    .filter((ticket: ITicket) => {
      const drawTS = (new Date(ticket.draw_date)).getTime();
      return Date.now() < drawTS;
    })
    .map((ticket: ITicket) => (new Date(ticket.draw_date)).getTime())
);

/**
 * Селектор полного набора билетов (ставок)
 */
export const selectTickets = createFeatureSelector<Array<ITicket>>('tickets');

/**
 * Селектор разыгранных билетов (ставок)
 */
export const selectPlayedBets = createSelector(selectTickets, (tickets: Array<ITicket>) => {
  const playedTickets = tickets
    .filter((ticket: ITicket) => {
      const drawTS = (new Date(ticket.draw_date)).getTime();
      const isPast = drawTS < Date.now();
      const isVisible = (Date.now() - drawTS <= 10 * 60000);
      return isPast && isVisible;
    });
  playedTickets.sort(
    (a: ITicket, b: ITicket) => (new Date(b.regdate)).getTime() - (new Date(a.regdate)).getTime()
  );
  return playedTickets.map((ticket: ITicket) => {
    const comb = ticket.bet[0].comb || '';
    let status = 'bets.processing';
    if (typeof ticket.win_sum !== 'undefined') {
      status = ticket.win_sum > 0 ? 'bets.win' : 'bets.lose';
    }
    const betItem: IBetItem = {
      table: ticket.bet[0].table,
      comb: ticket.bet[0].table === 1 ? convertComb(comb) : comb,
      bt_code: ticket.bet[0].bt_code as string,
      sum: ticket.bet[0].sum || 0,
      status
    };
    if (ticket.bet[0].table === 1) {
      betItem.coef = +(ticket.bet[0].coef ? ticket.bet[0].coef[0] : '0');
    } else {
      betItem.coef = +(ticket.bet[0].coef || '0');
    }
    return betItem;
  });
});

/**
 * Селектор не разыгранных ставок на активный тираж
 */
export const selectUnplayedBets = createSelector(selectTickets, (tickets: Array<ITicket>) => {
  const nearestDrawTS = getNearestDrawTS(tickets);
  const unplayedTickets = tickets
    .filter((ticket: ITicket) => (new Date(ticket.draw_date)).getTime() === nearestDrawTS);
  unplayedTickets.sort(
    (a: ITicket, b: ITicket) => (new Date(b.regdate)).getTime() - (new Date(a.regdate)).getTime()
  );
  return unplayedTickets
    .map((ticket: ITicket) => {
      const comb = ticket.bet[0].comb || '';
      const betItem: IBetItem = {
        table: ticket.bet[0].table,
        comb: ticket.bet[0].table === 1 ? convertComb(comb) : comb,
        bt_code: ticket.bet[0].bt_code as string,
        sum: ticket.bet[0].sum || 0,
        status: 'bets.waiting'
      };
      if (ticket.bet[0].table > 1) {
        betItem.coef = +(ticket.bet[0].coef || '0');
      }
      return betItem;
    });
});

/**
 * Селектор не разыгранных многотиражных ставок – все, которые были куплены на 2 и более
 * тиражей за один раз. В этой категории могут дублироваться ставки на активный тираж,
 * но только в том случае, если они были куплены многотиражным способом.
 */
export const selectUnplayedBetsMulti = createSelector(selectTickets, (tickets: Array<ITicket>) => {
  const unplayedTickets = tickets
    .filter((ticket: ITicket) => {
      const drawTS = (new Date(ticket.draw_date)).getTime();
      return Date.now() < drawTS;
    });

  const regDates: IBetsObject = {};
  for (let i = 0; i < unplayedTickets.length; i += 1) {
    const regDateTS = (new Date(unplayedTickets[i].regdate)).getTime();
    if (!regDates[regDateTS]) {
      regDates[regDateTS] = {};
    }
    if (!regDates[regDateTS][unplayedTickets[i].draw_name]) {
      regDates[regDateTS][unplayedTickets[i].draw_name] = [];
    }
    regDates[regDateTS][unplayedTickets[i].draw_name].push(unplayedTickets[i]);
  }

  let resultBets: Array<IBetItem> = [];
  const onlyRegDates = Object.keys(regDates);
  onlyRegDates.sort((a: string, b: string) => (new Date(b)).getTime() - (new Date(a)).getTime());
  onlyRegDates.forEach((regDate: string) => {
    const draws = Object.keys(regDates[+regDate]);
    if (draws.length > 1) {
      const bets: Array<IBetItem> = regDates[+regDate][draws[0]].map((t: ITicket) => ({
        table: t.bet[0].table,
        comb: t.bet[0].table === 1 ? convertComb(t.bet[0].comb) : t.bet[0].comb,
        coef: +(t.bet[0].coef || '0'),
        sum: t.bet[0].sum || 0,
        bt_code: t.bet[0].bt_code as string,
        status: 'bets.waiting',
        draws,
        regDate: t.regdate
      }));
      resultBets = [...resultBets, ...bets];
    }
  });

  resultBets.sort(
    (a: IBetItem, b: IBetItem) => (new Date(b.regDate || '')).getTime() - (new Date(a.regDate || '')).getTime()
  );

  return resultBets;
});
