import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IErrorsDialog } from '@app/core/interfaces/i-errors-dialog';

/**
 * Селектор диалогового окна об ошибке
 */
export const selectErrorsDialog = createFeatureSelector<IErrorsDialog>('errorsDialog');

/**
 * Селектор видимости диалогового окна об ошибке
 */
export const selectErrorsDialogVisibility = createSelector(
  selectErrorsDialog,
  (errorsDialog: IErrorsDialog) => errorsDialog.isShown
);

/**
 * Селектор данных диалогового окна об ошибке
 */
export const selectErrorsDialogData = createSelector(
  selectErrorsDialog,
  (errorsDialog: IErrorsDialog) => ({
    lottCode: errorsDialog.lottCode,
    errorCode: errorsDialog.errorCode,
    title: errorsDialog.title,
    content: errorsDialog.content,
    buttonText: errorsDialog.buttonText
  })
);
