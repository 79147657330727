<nav class="menu">
  <ul class="menu__list">
    <li class="menu__item" data-cy="main-menu-item">
      <div class="menu__link menu__link_active" data-cy="main-menu-link">
        <span class="menu__icon icon icon_pik4"></span>
        <span data-cy="main-menu-title" class="menu__title menu__title_active menu__title_pik4">{{ 'main-menu.pik4' | translate }}</span>
        <time *ngIf="nearest[30] && !this.translations[30]?.isPreTranslation && !this.translations[30]?.isTranslation"
              class="menu__time"
        >{{ nearest[30] | timeToEnd }}</time>
      </div>
    </li>
<!--    <li class="menu__item" data-cy="main-menu-item" *ngIf="!isMobile">
      <a target="_blank" href="{{ config.externalLotteries[52] || '/kare/' }}" class="menu__link" data-cy="main-menu-link">
        <span data-cy="main-menu-title" class="menu__title">{{ 'main-menu.kare' | translate }}</span>
        <time *ngIf="nearest[52]" class="menu__time">{{ nearest[52] | timeToEnd }}</time>
      </a>
    </li>-->
  </ul>
</nav>
