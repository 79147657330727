import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп, возвращающий количество и расположение шаров
 * true - шар есть на позиции
 * false - шара нет
 */
@Pipe({
  name: 'balls'
})
export class BallsPipe implements PipeTransform {
  /**
   * Функция преобразования
   * @param value Преобразуемое значение (код ставки)
   */
  // eslint-disable-next-line class-methods-use-this
  transform(value: string): Array<boolean> {
    const table = +value.substring(0, 1);
    const metric = +value.substring(2, 3);
    if (table === 2) {
      return value.toLowerCase() === '2b2y' ? [true, true, true, true] : (new Array(metric)).fill(false);
    }
    const ans = (new Array(4)).fill(false);
    ans[metric - 1] = true;
    return ans;
  }
}
