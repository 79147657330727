import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IResponse } from '@app/core/interfaces/i-response';
import { ApiService } from '@app/core/services/api.service';
import { of } from 'rxjs';
import { ErrorsService } from '@app/core/services/errors.service';

/**
 * Эффекты для получения данных по лотерее (типов ставок)
 */
@Injectable()
export class BetTypesEffects {
  /**
   * Наблюдаемая переменная в результате создания эффекта (не используется)
   */
  loadLottData$ = createEffect(() => this.actions$.pipe(
    ofType('[Bet Types List] Get Lott Data'),
    mergeMap((action: {lottCode: number}) => this.apiService.getLottData(action.lottCode)
      .pipe(
        map((LottDataResponse: IResponse) => ({ type: '[Bet Types List] Get Lott Data Success', betTypes: LottDataResponse.betTypes })),
        catchError((error: Error) => {
          this.errorsService.handleError(error);
          return of({ type: '[Bet Types List] Get Lott Data Error' });
        })
      ))
  ));

  /**
   * Конструктор эффекта
   * @param actions$ Наблюдаемая переменная действий
   * @param apiService Экземпляр API-сервиса
   * @param errorsService Экземпляр сервиса обработки ошибок
   */
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly errorsService: ErrorsService
    // eslint-disable-next-line no-empty-function
  ) { }
}
