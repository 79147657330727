import { Injectable } from '@angular/core';

/**
 * Объект DOM-окна
 */
declare const window: Window;

/**
 * Сервис по работе с внешним окном (окном сайта)
 */
@Injectable({
  providedIn: 'root'
})
export class SiteService {
  /**
   * Этап загрузки приложения
   */
  static loadingStage = 0;

  /**
   * Показать диалог входа с сайта
   */
  static showLoginDialog(): void {
    const event = new CustomEvent('signIn');
    window?.parent?.document.dispatchEvent(event);
  }

  /**
   * Обновить баланс на сайте
   */
  static updateBalance(): void {
    const event = new CustomEvent('updateBalance');
    window?.parent?.document.dispatchEvent(event);
  }

  /**
   * Метод для наблюдения за высотой контента в фрейме
   * и подстройки высоты фрейма под контент
   */
  static watchForContentHeight(): void {
    if (window?.parent) {
      const anchor = window.parent.document.getElementById('fast-frame') as HTMLIFrameElement;
      if (anchor) {
        anchor.style.overflow = 'hidden';
        anchor.setAttribute('scrolling', 'no');
        const htmlParentElem = window.parent.document.querySelector('html');
        const htmlElem = window.document.querySelector('html');
        const siteContentHeight = (htmlParentElem?.offsetHeight || 0)
          - (htmlElem?.offsetHeight || 0);
        const iframePreloadingHeight = window.innerHeight > siteContentHeight
          ? window.innerHeight - siteContentHeight : 640;
        anchor.style.height = `${iframePreloadingHeight}px`;

        const resizeObserver = new ResizeObserver((entries) => {
          if (this.loadingStage >= 2) {
            // eslint-disable-next-line no-restricted-syntax
            for (const entry of entries) {
              if (entry.contentBoxSize) {
                // Firefox implements `contentBoxSize` as a single content rect,
                // rather than an array
                const contentBoxSize = Array.isArray(entry.contentBoxSize)
                  ? entry.contentBoxSize[0] : entry.contentBoxSize;
                anchor.style.height = `${contentBoxSize.blockSize}px`;
              } else if (entry.contentRect) {
                anchor.style.height = `${entry.contentRect.height}px`;
              }
            }
          }
        });

        resizeObserver.observe(htmlElem as HTMLElement);
      }
    }
  }
}
