import { createReducer, on } from '@ngrx/store';
import { getDrawsExtra, getDrawsExtraError, getDrawsExtraSuccess } from '@app/store/actions/draws-extra.actions';

/**
 * Начальное состояние текущих тиражей по лотереям
 */
export const initialState: {[lottCode: number]: Array<Date>} = {};

/**
 * Редуктор для текущих тиражей по лотереям
 */
export const drawsExtraReducer = createReducer(
  initialState,
  on(getDrawsExtra, (state) => state),
  on(getDrawsExtraSuccess, (state, { extra }) => {
    const newState = { ...state };
    if (extra) {
      for (let i = 0; i < extra.length; i += 1) {
        if (!newState[extra[i].lottCode]) {
          newState[extra[i].lottCode] = [];
        }
        for (let j = 0; j < extra[i].history.length; j += 1) {
          if (!newState[extra[i].lottCode]
            .find((d: Date) => (new Date(d)).getTime()
              === (new Date(extra[i].history[j])).getTime())) {
            newState[extra[i].lottCode] = newState[extra[i].lottCode].concat(extra[i].history[j]);
          }
        }
      }
    }
    return newState;
  }),
  on(getDrawsExtraError, (state) => state)
);
