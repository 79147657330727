import { Inject, Injectable } from '@angular/core';
import {
  BehaviorSubject, of, Subject, timer
} from 'rxjs';
import { ISocketMessage } from '@app/core/interfaces/i-socket-message';
import { mockWebSocket, MockWebSocket } from '@app/core/mocks/mock-web-socket';
import { IConfig } from '@app/core/interfaces/i-config';
import { APP_CONFIG, MAX_TIMER_INTEGER } from '@app/core/utils';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '../../../environments/environment';

/**
 * Начальная задержка для переподключения к сокету
 */
const RECONNECTION_DELAY = 2000;

/**
 * Коэффициент для прогрессивного увеличения задержки
 * для повторных попыток подключения
 */
const DELAY_GROW_FACTOR = 1.3;

/**
 * Сервис-обертка для работы с веб-сокетом или с mock-данными
 */
@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  /**
   * Объект веб-сокета
   * @private
   */
  private ws: WebSocketSubject<any> | MockWebSocket | undefined;

  /**
   * Текущая задержка для переподключения к веб-сокету
   * @private
   */
  private reconnectionDelay = RECONNECTION_DELAY;

  /**
   * Наблюдаемая переменная для получения ответов от веб-сокета
   */
  socket$$: Subject<ISocketMessage> = new Subject<ISocketMessage>();

  /**
   * Статус подключения к веб-сокету
   * 0 - не подключен, 1 - подключается, 2 - подключен
   */
  isConnected$$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  /**
   * Конструктор сервиса
   * @param config Объект конфигурации приложения
   */
  constructor(@Inject(APP_CONFIG) private readonly config: IConfig) {
    this.initConnection();
  }

  /**
   * Метод для отправки сообщения на веб-сокет
   * @param data
   */
  send(data: ISocketMessage): void {
    this.ws?.next(data);
  }

  /**
   * Метод для подписки на получение сообщений от веб-сокета
   * @param subMsg Функция, которая возвращает сообщение для подписки
   * @param unsubMsg Функция, которая возвращает сообщение для отписки
   * @param messageFilter Фильтр сообщений, которые приходят по данной подписке
   */
  multiplex(subMsg: () => any, unsubMsg: () => any, messageFilter: (value: any) => boolean) {
    return this.ws?.multiplex(subMsg, unsubMsg, messageFilter) || of(null);
  }

  /**
   * Метод для инициализации соединения с веб-сокетом
   */
  initConnection(): void {
    this.isConnected$$.next(1);
    // console.log('Подключаемся...', this.reconnectionDelay);
    this.ws = environment.mocks ? mockWebSocket() : webSocket(this.config.api);
    this.isConnected$$.next(2);
    // console.log('Подключились');
    this.ws.subscribe({
      next: (v) => {
        this.socket$$.next(v);
        this.reconnectionDelay = RECONNECTION_DELAY;
      },
      error: () => {
        // console.log('Ошибка подключения');
        this.isConnected$$.next(0);
        timer(this.reconnectionDelay)
          .subscribe(this.initConnection.bind(this));
        this.reconnectionDelay *= DELAY_GROW_FACTOR;
        if (this.reconnectionDelay > MAX_TIMER_INTEGER) {
          this.reconnectionDelay = MAX_TIMER_INTEGER;
        }
      },
      complete: () => {
        // console.log('Сокет закрылся');
        this.isConnected$$.next(0);
      }
    });
  }

  /**
   * Метод для закрытия соединения
   */
  closeConnection(): void {
    this.ws?.complete();
  }
}
