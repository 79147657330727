import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IHelpDialog } from '@app/core/interfaces/i-help-dialog';

/**
 * Селектор диалогового окна справки
 */
const selectHelpDialog = createFeatureSelector<IHelpDialog>('helpDialog');

/**
 * Селектор видимости диалогового справки
 */
export const selectHelpDialogVisibility = createSelector(
  selectHelpDialog,
  (helpDialog: IHelpDialog) => helpDialog.isShown
);

/**
 * Селектор данных диалогового справки
 */
export const selectHelpDialogData = createSelector(selectHelpDialog, (helpDialog: IHelpDialog) => ({
  title: helpDialog.title,
  content: helpDialog.content
}));
