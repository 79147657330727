/**
 * Перечисление API-действий по обращению к серверу
 *
 * {@link getDraws} - действие по получению тиражей игры
 *
 * {@link getLottData} - действие по получению коэффициентов игры
 *
 * {@link regBet} - действие по регистрации ставки
 *
 * {@link getResults} - действие по получению результатов
 *
 * {@link getStats} - действие по получению статистики
 */
export enum ApiAction {
  getDraws = 'getDraws',
  getLottData = 'getLottData',
  regBet = 'regBet',
  getResults = 'getResults',
  getDrawsArchive = 'getDrawsArchive',
  getDrawsExtra = 'getDrawsExtra',
  getTicketList = 'getTicketList',
  getStat = 'getStat'
}
