import {
  Component, EventEmitter, Input, Output
} from '@angular/core';
import { IBetItem } from '@app/features/pik4/interfaces/i-bet-item';
import { IDraw } from '@app/core/interfaces/i-draw';

/**
 * Спойлер для раздела ставок
 */
@Component({
  selector: 'app-bets-spoiler',
  templateUrl: './bets-spoiler.component.html',
  styleUrls: ['./bets-spoiler.component.scss']
})
export class BetsSpoilerComponent {
  /**
   * Массив ставок
   * @private
   */
  private betItems: Array<IBetItem> = [];

  /**
   * Проверка массива ставок на изменения
   * @param v Переданный массив ставок
   * @private
   */
  private checkBetItemsForChanges(v: Array<IBetItem>): boolean {
    if (this.betItems.length !== v.length) {
      return true;
    }

    if (v[0]?.draws?.length !== this.betItems[0]?.draws?.length) {
      return true;
    }

    if (this.title === 'bets.played-bets') {
      for (let i = 0; i < v.length; i += 1) {
        if (v[i].status !== this.betItems[i].status) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Заголовок спойлера
   */
  @Input() title = '';

  /**
   * Геттер для получения массива ставок
   */
  get bets(): Array<IBetItem> {
    return this.betItems;
  }

  /**
   * Сеттер для присвоения массива ставок
   * @param v Массив ставок
   */
  @Input() set bets(v: Array<IBetItem>) {
    if (this.checkBetItemsForChanges(v)) {
      this.betItems = v;
    }
  }

  /**
   * Текущий тираж
   */
  @Input() draw: IDraw | null | undefined = undefined;

  /**
   * Состояние спойлера
   */
  @Input() spoilerState = false;

  /**
   * Переменная, выводящая в событие смену состояния спойлера
   */
  @Output() spoilerStateChanged = new EventEmitter<boolean>();

  /**
   * Вычисление количества ставок в спойлере
   */
  get ticketsLength(): number {
    if (this.betItems.length && this.betItems[0].draws && this.betItems[0].draws.length > 1) {
      let ticketsCount = 0;
      for (let i = 0; i < this.betItems.length; i += 1) {
        ticketsCount += (this.betItems[i].draws?.length || 0);
      }
      return ticketsCount;
    }
    return this.betItems.length;
  }

  /**
   * Случайное число для уникальности ID DOM-элементов
   */
  uid = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
}
