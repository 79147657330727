import { ApiAction } from '@app/core/enums/api-action';
import {getDraws} from "@app/core/mocks/get-draws";
import {getLottData} from "@app/core/mocks/get-lott-data";
import {MOCK_SEQ, MOCK_TOKEN} from "@app/core/mocks/utils";
import {getDrawsExtra} from "@app/core/mocks/get-draws-extra";
import {getStat} from "@app/core/mocks/get-stat";

/**
 * Mock-данные
 */
export const MOCK_DATA = [
  ...getDraws,
  ...getLottData,
  {
    request: {
      action: ApiAction.getTicketList,
      lottCode: 30,
      token: MOCK_TOKEN,
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getTicketList,
      R: 0,
      lottCode: 30,
      tickets: [],
      seq: MOCK_SEQ
    }
  },
  ...getDrawsExtra,
  ...getStat
];
