import { createAction, props } from '@ngrx/store';

/**
 * Действие для открытия диалога справки
 */
export const openHelpDialog = createAction('[Help Dialog] Open help dialog', props<{ alias: string }>());

/**
 * Действие при успешном открытии диалогового окна
 */
export const openHelpDialogSuccess = createAction('[Help Dialog] Open help dialog success', props<{ title: string, content: string }>());

/**
 * Действие при закрытии диалогового окна (при нажатии на кнопку закрыть или на свободную область)
 */
export const closeHelpDialog = createAction('[Help Dialog] Close help dialog');
