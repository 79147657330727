import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ITranslationState } from '@app/core/interfaces/i-translation-state';

/**
 * Селектор получения статуса трансляции по всем играм
 */
export const selectTranslations = createFeatureSelector<{[lottCode: number]: ITranslationState}>('translationStatus');

/**
 * Селектор получения статуса трансляции по одной игре
 * @param lottCode Код игры
 */
export const selectTranslation = (lottCode: number) => createSelector(
  selectTranslations,
  (translations) => translations[lottCode]
);
