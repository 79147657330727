<div appFixedInFrame
     top="270px"
     class="dialog dialog_lottery_{{ (dialogData$ | async)?.lottCode }}"
     data-cy="dialog"
>
  <button type="button"
          class="dialog__close"
          data-cy="dialog-close"
          (click)="closeDialog($event)"
  ></button>
  <h2 class="dialog__header"
      data-cy="dialog-header"
  >{{ (dialogData$ | async)?.title }}</h2>
  <div class="dialog__content dialog__content_{{ (dialogData$ | async)?.errorCode }}"
       [innerHTML]="(dialogData$ | async)?.content"
       data-cy="dialog-content"
  ></div>
  <button type="button"
          class="button dialog__action" (click)="onAction()"
          data-cy="dialog-action"
  >
      <span class="button__inner"
            data-cy="button-inner"
      >
        <span class="button__corner button__corner_lb"
              data-cy="button-corner"
        ></span>
        {{ (dialogData$ | async)?.buttonText }}
        <ng-content></ng-content>
        <span class="button__corner button__corner_rt"
              data-cy="button-corner"
        ></span>
      </span>
  </button>
  <div class="shadow"
       data-cy="shadow"
  ></div>
</div>

