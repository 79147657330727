import {
  Component, ElementRef, Input, ViewChild
} from '@angular/core';
import { ICarouselBet } from '@app/features/pik4/interfaces/i-carousel-bet';
import { timer } from 'rxjs';

/**
 * Объект пустой ячейки в карусели
 */
const BLANK = {
  color: 'none', value: '', count: 0, position: 0
};

/**
 * Компонент карусели ставок
 */
@Component({
  selector: 'app-bets-carousel',
  templateUrl: './bets-carousel.component.html',
  styleUrls: ['./bets-carousel.component.scss']
})
export class BetsCarouselComponent {
  /**
   * Ставки в карусели
   */
  viewBets: Array<ICarouselBet> = [];

  /**
   * Прокручиваемый элемент в карусели
   */
  @ViewChild('scroller') scroller: ElementRef<HTMLDivElement> | undefined;

  /**
   * Ставки для показа в карусели
   * @param v Массив ставок
   */
  @Input() set bets(v: Array<ICarouselBet>) {
    // Сколько пустых в имеющимся массиве
    let blanksCount = this.viewBets.filter((b) => b.color === 'none').length;
    // Сколько заполненных
    const oldBetsCount = this.viewBets.length - blanksCount;
    // Сколько будет пустых с учетом нового массива
    if (v.length > oldBetsCount) {
      blanksCount -= v.length - oldBetsCount;
    }
    const blanks = blanksCount <= 0 ? [] : (new Array(blanksCount)).fill(BLANK);
    this.viewBets = [...v, ...blanks];
    timer(200)
      .subscribe(() => {
        if (this.scroller) {
          this.scroller.nativeElement.scrollTo({
            left: this.scroller.nativeElement.scrollWidth,
            behavior: 'smooth'
          });
        }
      });
  }

  /**
   * Имеет ли карусель скроллинг?
   */
  get hasScrolling(): boolean {
    return window.innerWidth < 1200 ? this.viewBets.length > 3 : this.viewBets.length > 4;
  }

  /**
   * Метод, возвращающий массив чисел от 1 до count
   * @param count Последнее число в массиве
   */
  // eslint-disable-next-line class-methods-use-this
  range(count: number): Array<number> {
    return (new Array<number>(count))
      .fill(0)
      .map((v, i) => i + 1);
  }

  /**
   * Обработчик, в котором добавляется пустая ячейка в карусель ставок
   */
  onBlankAdd(): void {
    if (this.viewBets.length < 16) {
      this.viewBets.push(BLANK);
    }
  }

  /**
   * Обработчик проведения по экрану, при котором скроллится контейнер
   * @param event Передаваемое значение
   */
  onSwipe(event: any): void {
    this.scroller?.nativeElement.scrollBy({
      left: -event.deltaX,
      behavior: 'smooth'
    });
  }
}
