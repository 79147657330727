/**
 * Имя mock-хоста
 */
import {IDraw} from "@app/core/interfaces/i-draw";

/**
 * Имя mock-хоста
 */
export const MOCK_HOSTNAME = 'localhost';

/**
 * Значение mock-идентификатора запроса и ответа
 */
export const MOCK_SEQ = 165572938208102;

/**
 * mock-токен
 */
export const MOCK_TOKEN = 'e490a2f13ee37bd786b6db61e4c15e9813b6bd92208bb40fc16221207b956da8';

/**
 * Устаревший mock-токен
 */
export const MOCK_TOKEN_OUTDATED = '1234567890';

/**
 * Устаревший mock-токен 2
 */
export const MOCK_TOKEN_OUTDATED2 = '12345678901234567890';

/**
 * mock-токен пользователя с недостаточным балансом
 */
export const MOCK_TOKEN_NO_MONEY = 'mock-token-no-money';

/**
 * mock-токен пользователя с бонусами, но ими оплачивать нельзя
 */
export const MOCK_TOKEN_NO_BONUSES_PAY = 'mock-token-no-bonuses-pay';

/**
 * mock-токен, при котором вернется внутренняя ошибка сервера
 */
export const MOCK_TOKEN_ISE = 'mock-token-internal-server-error';

/**
 * Максимальная сумма ставки в копейках
 */
export const MAX_BET_SUM = 1000000;

/**
 * Длительность приема ставок на тираж (в миллисекундах)
 */
export const DRAW_DURATION = 310 * 1000;

/**
 * Длительность розыгрыша тиража (в миллисекундах)
 */
export const DRAW_RESULT_DURATION = 20 * 1000;

/**
 * Количество прошедших тиражей до текущего
 */
export const DRAWS_BEFORE_CURRENT = 5;

/**
 * Цвета шаров
 */
const BALL_COLORS = ['R', 'B', 'Y', 'G'];

/**
 * Сгенерировать выигрышную комбинацию
 */
export function generateWinComb(): string {
  const winComb = [];
  for (let i = 0; i < 4; i += 1) {
    /**
     * Цвет шара
     */
    const ballColor = BALL_COLORS[Math.floor(Math.random() * 4)];
    /**
     * Номер шара
     */
    const ballNum = Math.floor(Math.random() * 10) + 1;
    winComb.push(ballColor + ballNum);
  }
  return winComb.join(',');
}

/**
 * Получить номер тиража и количество миллисекунд с начала дня для указанной даты
 * @param dateTime Дата
 */
export function getDrawNum(dateTime: Date): {msFromDayStart: number; drawNum: number;} {
  /**
   * Количество миллисекунд с начала текущего дня
   */
  const msFromDayStart = dateTime.getHours() * 60 * 60 * 1000
    + dateTime.getMinutes() * 60 * 1000
    + dateTime.getSeconds() * 1000
    + dateTime.getMilliseconds();
  /**
   * Номер тиража
   */
  const drawNum = Math.floor(msFromDayStart / DRAW_DURATION) + 1;
  return {msFromDayStart, drawNum};
}

/**
 * Получить левую часть номера тиража, относящуюся к дню
 * @param drawStartDate Дата и время начала тиража
 */
export function getDrawNumLeftPart(drawStartDate: Date): string {
  /**
   * День искомого тиража
   */
  const nDay = drawStartDate.getDate();
  /**
   * Месяц искомого тиража
   */
  const nMonth = drawStartDate.getMonth() + 1;
  /**
   * Год искомого тиража
   */
  const nYear = drawStartDate.getFullYear();
  /**
   * День искомого тиража в виде строки
   */
  const sDay = nDay.toString().padStart(2, '0');
  /**
   * Месяц искомого тиража в виде строки
   */
  const sMonth = nMonth.toString().padStart(2, '0');
  /**
   * Год искомого тиража в виде строки
   */
  const sYear = nYear.toString().substring(2);
  return `${sDay}${sMonth}${sYear}`;
}

/**
 * Сгенерировать тираж
 * @param offset Смещение относительно текущего тиража
 */
export function generateDraw(offset = 0): IDraw {
  /**
   * Текущая дата
   */
  const currentDate = new Date();
  /**
   * Количество миллисекунд с начала текущего дня и номер текущего тиража (offset = 0)
   */
  const {msFromDayStart, drawNum: currentDrawNum} = getDrawNum(currentDate);
  /**
   * Таймстамп старта текущего тиража (offset = 0)
   */
  const currentDrawStartTS = Date.now() - msFromDayStart + (currentDrawNum - 1) * DRAW_DURATION;
  /**
   * Таймстамп начала искомого тиража
   */
  const drawStartTS = currentDrawStartTS + offset * DRAW_DURATION;
  /**
   * Дата начала искомого тиража
   */
  const drawStartDate = new Date(drawStartTS);
  /**
   * Количество тиражей в дне
   */
  const drawsInDay = Math.floor(24 * 60 * 60 * 1000 / DRAW_DURATION);
  /**
   * Номер искомого тиража в дне
   */
  const dayDrawNum = (currentDrawNum - 1 + offset + drawsInDay) % drawsInDay + 1;
  /**
   * Номер искомого тиража в дне в виде строки
   */
  const sDayDrawNum = dayDrawNum.toString().padStart(3, '0');
  /**
   * Элемент истории (тираж)
   */
  return {
    id: dayDrawNum,
    number: `${getDrawNumLeftPart(drawStartDate)}/${sDayDrawNum}`,
    start: drawStartDate,
    end: new Date(drawStartTS + DRAW_DURATION),
    game_start: new Date(drawStartTS + DRAW_DURATION + 1000),
    game_end: new Date(drawStartTS + DRAW_DURATION + 1000 + DRAW_RESULT_DURATION),
    betData: {
      bet_sum: 500, min_bet: 500, max_bet: 250000, max_win: 0
    }
  };
}
