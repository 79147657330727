import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп для отделения цвета от ставки
 */
@Pipe({
  name: 'color'
})
export class ColorPipe implements PipeTransform {
  /**
   * Функция преобразования
   * @param value Преобразуемое значение
   */
  // eslint-disable-next-line class-methods-use-this
  transform(value: string): string {
    return value.toLowerCase() === '2b2y' ? 'b2y2' : value.substring(1, 2).toLowerCase();
  }
}
