import { Component, HostListener, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectHelpDialogData } from '@app/store/selectors/help-dialog.selectors';

/**
 * Компонент диалогового окна справки
 */
@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpDialogComponent {
  /**
   * Наблюдаемая переменная для выбора данных диалогового окна справки
   */
  dialogData$ = this.store.select(selectHelpDialogData);

  /**
   * Слушатель кликов по документу
   * @param event Событие клика
   */
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    this.closeDialog(event);
  }

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   */
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(private store: Store) { }

  /**
   * Обработчик нажатия кнопки закрытия диалогового окна
   * или клика по свободной области
   */
  closeDialog(event: Event): void {
    const elem = event.target as HTMLElement;
    if (!elem.closest('.dialog') || elem.className.includes('dialog__close')) {
      this.store.dispatch({ type: '[Help Dialog] Close help dialog' });
    }
  }
}
