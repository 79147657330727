import { createAction, props } from '@ngrx/store';
import { IBet } from '@app/core/interfaces/i-bet';
import { ITicket } from '@app/core/interfaces/i-ticket';

/**
 * Действие для запроса регистрации ставки
 */
export const regBet = createAction('[Bet Registration] Reg Bet', props<{lottCode: number, bet: IBet}>());

/**
 * Действие при успешной регистрации ставки
 */
export const regBetSuccess = createAction('[Bet Registration] Reg Bet Success', props<{tickets: Array<ITicket>}>());

/**
 * Действие при ошибке регистрации ставки
 */
export const regBetError = createAction('[Bet Registration] Reg Bet Error');

/**
 * Действие для запроса получения списка ставок по лотерее
 */
export const getTicketsList = createAction('[Tickets List] Get List Of Tickets', props<{ lottCode: number }>());

/**
 * Действие при успешном получении списка ставок
 */
export const getTicketsListSuccess = createAction('[Tickets List] Get List Of Tickets Success', props<{ tickets: Array<ITicket> }>());

/**
 * Действие при ошибке получения списка ставок
 */
export const getTicketsListError = createAction('[Tickets List] Get List Of Tickets Error');
