import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pik4RoutingModule } from '@app/features/pik4/pik4-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { SwiperModule } from 'swiper/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GameMenuComponent } from '@app/shared/components/game-menu/game-menu.component';
import { MainComponent } from '@app/features/pik4/components/main/main.component';
import { TimeToEndPipe } from '@app/shared/pipes/time-to-end.pipe';
import { OnlyNumberPipe } from '@app/shared/directives/only-number.pipe';
import { ReelComponent } from './components/reel/reel.component';
import { BetsCarouselComponent } from './components/bets-carousel/bets-carousel.component';
import { TableComponent } from './components/table/table.component';
import { BallsColorsComponent } from './components/balls-colors/balls-colors.component';
import { BetsInfoComponent } from './components/bets-info/bets-info.component';
import { StatsComponent } from './components/stats/stats.component';
import { ResultsComponent } from './components/results/results.component';
import { SortingMenuComponent } from './components/sorting-menu/sorting-menu.component';
import { BetItemComponent } from './components/bet-item/bet-item.component';
import { ColorPipe } from './pipes/color.pipe';
import { BallsPipe } from './pipes/balls.pipe';
import { BetsSpoilerComponent } from './components/bets-spoiler/bets-spoiler.component';
import { CombPipe } from './pipes/comb.pipe';
import { CaptionComponent } from './components/caption/caption.component';
import { FreakyButtonComponent } from './components/freaky-button/freaky-button.component';
import { TablesComponent } from './components/tables/tables.component';

@NgModule({
  declarations: [
    MainComponent,
    ReelComponent,
    BetsCarouselComponent,
    GameMenuComponent,
    TableComponent,
    BallsColorsComponent,
    BetsInfoComponent,
    StatsComponent,
    ResultsComponent,
    SortingMenuComponent,
    BetItemComponent,
    ColorPipe,
    BallsPipe,
    BetsSpoilerComponent,
    CombPipe,
    CaptionComponent,
    FreakyButtonComponent,
    TablesComponent,
    OnlyNumberPipe
  ],
  imports: [
    CommonModule,
    Pik4RoutingModule,
    SharedModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    OnlyNumberPipe
  ],
  providers: [
    // {
    //   provide: DATE_PIPE_DEFAULT_TIMEZONE,
    //   useValue: '+0300'
    // },
    TimeToEndPipe
  ]
})
export class Pik4Module { }
