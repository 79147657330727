<app-bets-carousel class="bets-carousel" [bets]="tableCarousel"></app-bets-carousel>

<div class="bets-grid">
  <ng-container *ngIf="table === 2">
    <div class="bets-grid__q">
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
    </div>
    <div class="bets-grid__q">
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
    </div>
    <div class="bets-grid__q">
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
    </div>
    <div class="bets-grid__q">
      <div class="bets-grid__ball"></div>
    </div>
  </ng-container>

  <ng-container *ngIf="table === 3">
    <div class="bets-grid__q">
      <div class="bets-grid__ball bets-grid__ball_active"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
    </div>
    <div class="bets-grid__q">
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball bets-grid__ball_active"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>

    </div>
    <div class="bets-grid__q">
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball bets-grid__ball_active"></div>
      <div class="bets-grid__ball"></div>

    </div>
    <div class="bets-grid__q">
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball"></div>
      <div class="bets-grid__ball bets-grid__ball_active"></div>
    </div>

  </ng-container>


  <ng-container *ngFor="let color of ['R', 'B', 'Y', 'G']">
    <ng-container *ngFor="let mv of metric[table]">
      <ng-container *ngIf="betsData && betsData[color] && betsData[color][mv]">
        <input [id]="color + '_' + table + '_' + mv"
               class="bets-grid__control" type="checkbox"
               [(ngModel)]="betsData[color][mv].checked"
               (ngModelChange)="onTableBetChange(color, mv.toString(), betsData[color][mv].bt_coef, $event)"
        />
        <label [for]="color + '_' + table + '_' + mv"
               class="bets-grid__item bets-item bets-item_{{ color | lowercase }}"
               [attr.data-cy]="'bets-item-'+ color.toLowerCase()"
        ><span class="bets-item__value" data-cy="bets-item-value">x {{ betsData[color][mv].bt_coef }}</span></label>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<div *ngIf="table === 2" class="table-4" data-cy="table-4">
  <div class="table-4__inner">
    <h3 class="table-4__title">"{{ 'pik4.colors-of-victory' | translate }}"</h3>
    <div class="table-4__desc"
         [innerHTML]="'pik4.table-4-desc' | translate:{X: victoryOdd}"
    ></div>
    <div class="table-4__bottom">
      <div class="squares">
        <div class="square square_b"></div>
        <div class="square square_b"></div>
        <div class="square square_y"></div>
        <div class="square square_y"></div>
      </div>
      <input id="v_colors"
             class="table-4__control"
             type="checkbox"
             [(ngModel)]="colorsOfVictory"
             (ngModelChange)="onPatrioticBetChange()"
      />
      <label class="table-4__select" for="v_colors">
        {{ colorsOfVictory ? ('pik4.table-4-selected' | translate) : ('pik4.table-4-select' | translate) }}
      </label>
    </div>
  </div>
  <!--<div class="table-4__note">{{ 'pik4.table-4-note' | translate }}</div>-->
</div>
