<input id="bets_{{ uid }}"
       class="spoiler-control"
       type="checkbox"
       [(ngModel)]="spoilerState"
       (ngModelChange)="spoilerStateChanged.emit(spoilerState)"
/>
<label for="bets_{{ uid }}" class="spoiler-header">
  <span class="spoiler-header__text"
        [innerHTML]="(title | translate) + '&nbsp;(' + ticketsLength + ') ' + (draw ? ('bets.draw-no' | translate) + draw.number : '')"
  ></span>
</label>
<div class="spoiler-body" [style.max-height.px]="spoilerState ? inner.offsetHeight + 640 : 0">
  <div #inner class="spoiler-body__inner">
    <app-bet-item *ngFor="let bet of bets"
                  class="bet-item"
                  [bet]="bet"
    ></app-bet-item>
  </div>
</div>
