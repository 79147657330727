import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

/**
 * Маршрут для перехода, если страница не найдена
 */
const URL_NOT_FOUND = 'not-found';

/**
 * Список маршрутов
 */
const routes: Routes = [
  // -----------------------------
  //  Путь не найден
  // -----------------------------
  {
    path: '',
    loadChildren: () => import('@app/features/pik4/pik4.module')
      .then((m) => m.Pik4Module),
    pathMatch: 'prefix'
  },

  {
    path: URL_NOT_FOUND,
    loadChildren: () => import('@app/features/not-found/not-found.module')
      .then((m) => m.NotFoundModule)
  },

  {
    path: '**',
    redirectTo: URL_NOT_FOUND,
    pathMatch: 'full'
  }

];

/**
 * Корневой модуль маршрутизации
 */
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {}
