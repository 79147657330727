import { createAction, props } from '@ngrx/store';
import { IStats } from '@app/core/interfaces/i-stats';

/**
 * Действие для запроса получения статистики розыгрышей по коду игры и типу статистики
 */
export const getStats = createAction('[Stats] Get Stats', props<{ lottCode: number; t: number; }>());

/**
 * Действие при успешном получении статистики
 */
export const getStatsSuccess = createAction('[Stats] Get Stats Success', props<{ t: number; stats: IStats }>());

/**
 * Действие при ошибке получения статистики
 */
export const getStatsError = createAction('[Stats] Get Stats Error');
