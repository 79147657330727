import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '@app/features/pik4/components/main/main.component';
import { ResultsComponent } from '@app/features/pik4/components/results/results.component';
import { TablesComponent } from '@app/features/pik4/components/tables/tables.component';
import { BetsInfoComponent } from '@app/features/pik4/components/bets-info/bets-info.component';
import { StatsComponent } from '@app/features/pik4/components/stats/stats.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: '',
        component: TablesComponent
      }, {
        path: 'bets',
        component: BetsInfoComponent
      }, {
        path: 'stats',
        component: StatsComponent
      }, {
        path: 'results',
        component: ResultsComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class Pik4RoutingModule {}
