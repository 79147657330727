import {
  Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild
} from '@angular/core';

import {
  ActivatedRoute, NavigationEnd, Router, UrlTree
} from '@angular/router';
import { selectHelpDialogVisibility } from '@app/store/selectors/help-dialog.selectors';
import { interval, Observable, of, Subject } from 'rxjs';
import { ModalsDirective } from '@app/core/directives/modals.directive';
import { HelpDialogComponent } from '@app/core/components/help-dialog/help-dialog.component';
import { SiteService } from '@app/core/services/site.service';
import { TranslateService } from '@ngx-translate/core';
import { MobileService } from '@app/core/services/mobile.service';
import { selectErrorsDialogVisibility } from '@app/store/selectors/errors-dialog.selectors';
import { ErrorsDialogComponent } from '@app/core/components/errors-dialog/errors-dialog.component';
import {
  debounceTime, filter, take, takeUntil
} from 'rxjs/operators';
import { IDraw } from '@app/core/interfaces/i-draw';
import {DRAWS_INTERVALS, inIframe, TRAN_DURATION} from '@app/core/utils';
import { TranslationData } from '@app/shared/interfaces/translation-data';
import {
  selectLastPlayedDraw,
  selectLastPlayedDraws,
  selectPlayingDraw,
  selectResultsForTranslation
} from '@app/store/selectors/draws.selectors';
import { TimeToEndPipe } from '@app/shared/pipes/time-to-end.pipe';
import { DOCUMENT } from '@angular/common';
import { selectLoadingStage } from '@app/store/selectors/preloader.selectors';
import { ErrorsService } from '@app/core/services/errors.service';
import { Store } from '@ngrx/store';
import { WebSocketService } from '@app/core/services/web-socket.service';
import { ITranslationResultsData } from '@app/core/interfaces/translation-results-data';
import { Actions } from '@ngrx/effects';
import { environment } from '../environments/environment';
import { ApiService } from './core/services/api.service';
import {MOCK_TOKEN} from "@app/core/mocks/utils";

/**
 * Главный компонент приложения
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  /**
   * Этап загрузки приложения
   */
  loadingStage$: Observable<number>;

  /**
   * Показан ли диалог справки?
   */
  helpDialogShown$: Observable<boolean>;

  /**
   * Показан ли диалог ошибки?
   */
  errorsDialogShown$: Observable<boolean | undefined>;

  /**
   *
   * Флаг фазы пре-трансляции (когда обратный отсчет)
   */
  isPreTranslation = false;

  /**
   *
   * Флаг фазы трансляции
   */
  isTranslation = false;

  /**
   *
   * Время до начала трансляции на кнопке с обратным отсчетом
   */
  videoButtonTime = '';

  /**
   *
   * Открыт ли плеер с трансляцией
   */
  playerOpened = false;

  /**
   *
   * Должен ли быть открыт плеер при следующей трансляции
   */
  playerMustOpen = false;

  /**
   *
   * Текущий маршрут в приложении
   */
  currentRoute = '';

  /**
   *
   * Наблюдаемая переменная для текущего тиража
   */
  playingDraw$: Observable<IDraw | undefined> = of(undefined);

  /**
   *
   * Текущий тираж
   */
  playingDraw: IDraw | undefined;

  /**
   * Последний разыгранный тираж, без задержки получения
   */
  lastPlayedDraw$: Observable<IDraw | undefined> = of(undefined);

  /**
   * Последние разыгранные тиражи с комбинацией
   */
  lastPlayedDraws$: Observable<Array<IDraw>> = of([]);

  /**
   * Последний известный тираж с комбинацией
   */
  lastDrawWithComb: IDraw | undefined;

  /**
   * Наблюдаемая переменная с данными для трансляции из результатов тиражей
   */
  translationResultsData$: Observable<ITranslationResultsData | undefined>;

  /**
   * Последние данные для трансляции
   */
  lastTRD: ITranslationResultsData | undefined;

  /**
   * Директива для показа модальных окон
   */
  @ViewChild(ModalsDirective, { static: true }) modals!: ModalsDirective;

  /**
   * Ссылка на фрейм медиа-плеера
   */
  @ViewChild('mediaFrame') mediaFrame: ElementRef<HTMLIFrameElement> | undefined;

  /**
   * Переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Конструктор главного компонента
   * @param store NgRx-хранилище приложения
   * @param apiService Сервис по обращению к API
   * @param route Текущий активированный маршрут
   * @param router Объект модуля маршрутизации
   * @param translateService Сервис переводов
   * @param timeToEndPipe Пайп, выводящий время до указанной даты
   * @param errorsService Сервис обработки ошибок
   * @param webSocketService Сервис по работе с веб-сокетом
   * @param getDrawArchive$ Переменная, наблюдающая за получением архива тиражей
   * @param doc Текущий DOM-документ
   */
  constructor(
    private readonly store: Store,
    private readonly apiService: ApiService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly timeToEndPipe: TimeToEndPipe,
    readonly errorsService: ErrorsService,
    private readonly webSocketService: WebSocketService,
    private readonly getDrawArchive$: Actions,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.helpDialogShown$ = store.select(selectHelpDialogVisibility);
    this.errorsDialogShown$ = store.select(selectErrorsDialogVisibility);
    this.playingDraw$ = store.select(selectPlayingDraw);
    this.lastPlayedDraw$ = store.select(selectLastPlayedDraw);
    this.lastPlayedDraws$ = store.select(selectLastPlayedDraws);
    this.loadingStage$ = store.select(selectLoadingStage);
    this.translationResultsData$ = store.select(selectResultsForTranslation);
    translateService.onDefaultLangChange.pipe(
      take(1)
    ).subscribe(() => {
      this.store.dispatch({ type: '[Preloader] Increase Stage' });
    });

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentURLObj = new URL(`https://fake-host${router.url}`);
        this.currentRoute = currentURLObj.pathname.split('/').filter((v) => !!v).join('-') || 'main';
        if (inIframe()) {
          window.parent.scrollTo(0, 0);
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  /**
   * Обработчик для показа медиа-плеера
   */
  onShowVideoHandler(): void {
    this.playerOpened = true;
    this.playerMustOpen = true;
    (this.doc.getElementsByClassName('top-block')[0] as HTMLDivElement).style.marginBottom = '267px';
  }

  /**
   * Обработчик для закрытия медиа-плеера
   * @param event Вызванное событие
   */
  onCloseVideoHandler(event: Event): void {
    if ((event.target as HTMLElement).classList.contains('player__control_size_part')
      || (event.target as HTMLElement).classList.contains('player')) {
      this.playerOpened = false;
      this.playerMustOpen = false;
      (this.doc.getElementsByClassName('top-block')[0] as HTMLDivElement).style.marginBottom = '';
    }
  }

  /**
   * Обработчик изменения текущего тиража
   * @param playingDraw Текущий тираж
   * @private
   */
  private onPlayingDrawHandler(playingDraw: IDraw | undefined): void {
    let isPreTranslation = false;
    if (playingDraw) {
      const transDiff = Math.floor(
        ((new Date(playingDraw.game_end)).getTime() - Date.now()) / 1000
      ) % DRAWS_INTERVALS[30];
      this.videoButtonTime = this.timeToEndPipe.transform(playingDraw.game_end);
      isPreTranslation = (transDiff >= 0) && (transDiff <= 21);
      this.playingDraw = playingDraw;
    } else if (this.playerOpened) {
      this.playerOpened = false;
      (this.doc.getElementsByClassName('top-block')[0] as HTMLDivElement).style.marginBottom = '';
      this.playerMustOpen = true;
    }
    this.store.dispatch({
      type: '[Translation] Set State',
      lottCode: 30,
      isPreTranslation
    });
    this.isPreTranslation = isPreTranslation;
  }

  /**
   * Обработчик начала трансляции
   * @param td Данные для трансляции
   * @private
   */
  private onStartTranslation(td: TranslationData): void {
    this.store.dispatch({
      type: '[Translation] Set State',
      lottCode: td.lotteryCode,
      isPreTranslation: false,
      isTranslation: true
    });
    if (this.playerMustOpen) {
      this.playerOpened = true;
      const topBlocks = this.doc.getElementsByClassName('top-block');
      if (topBlocks.length) {
        (topBlocks[0] as HTMLDivElement).style.marginBottom = '267px';
      }
      this.playerMustOpen = false;
    }
    this.isPreTranslation = false;
    this.isTranslation = true;
  }

  /**
   * Обработчик остановки трансляции
   * @param td Данные для трансляции
   */
  private onStopTranslation(td: TranslationData): void {
    this.store.dispatch({
      type: '[Translation] Set State',
      lottCode: td.lotteryCode,
      isTranslation: false
    });
    this.playerOpened = false;
    const topBlocks = this.doc.getElementsByClassName('top-block');
    if (topBlocks.length) {
      (topBlocks[0] as HTMLDivElement).style.marginBottom = '';
    }
    this.isTranslation = false;
  }

  /**
   * Функция, которая проверяет, должна ли сейчас быть трансляция и если должна, то запускает ее
   * @private
   */
  private checkForTranslation(): void {
    if (this.lastTRD && this.lastDrawWithComb) {
      const gameEndTS = (new Date(this.lastDrawWithComb.game_end)).getTime();
      // Отнимает секунду для перестраховки, чтоб трансляция вдруг не повторилась сама
      if ((Date.now() - gameEndTS) < (TRAN_DURATION - 1000)) {
        const playerFrame: any = this.mediaFrame?.nativeElement.contentWindow;
        if (playerFrame?.startPick4Game) {
          this.onStartTranslation({ lotteryCode: 30 });
          playerFrame.startPick4Game(this.lastTRD).then(() => {
            this.onStopTranslation({ lotteryCode: 30 });
          });
        }
      }
    }
  }


  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    const thisIframe = window.parent?.document.getElementById('fast-frame') as HTMLIFrameElement;
    const urlObj = new URL(thisIframe ? thisIframe.src : document.location.href);
    if (urlObj.pathname !== '/mediaplayer/') {
      const parsedUrl: UrlTree = this.router.parseUrl(`${urlObj.pathname}${urlObj.search}`);

      // проверить признак нативного приложения с мобильного телефона
      if (parsedUrl.queryParamMap.has('mobile') && parsedUrl.queryParamMap.get('mobile')) {
        environment.isMobile = true;
        this.apiService.token = MobileService.getAuthToken();
        this.apiService.termCode = parsedUrl.queryParamMap.has('term_code')
          ? +(parsedUrl.queryParamMap.get('term_code') || '59020') : 59020;
      } else {
        environment.isMobile = false;
      }

      // использовать язык из URL
      if (parsedUrl.queryParamMap.has('lang')) {
        const lang = parsedUrl.queryParamMap.get('lang')?.toLowerCase() || this.translateService.defaultLang;
        this.translateService.use(lang);
      } else {
        this.translateService.use(this.translateService.defaultLang);
      }

      // проверить токен в URL
      if (parsedUrl.queryParamMap.has('token')) {
        this.apiService.token = parsedUrl.queryParamMap.get('token') || '';
      }

      // проверить на mock-режим
      if (environment.mocks && !parsedUrl.queryParamMap.has('token')) {
        this.apiService.token = MOCK_TOKEN;
      }

      this.helpDialogShown$.subscribe((isShown: boolean) => {
        if (this.modals) {
          const { viewContainerRef } = this.modals;
          viewContainerRef.clear();
          if (isShown) {
            viewContainerRef.createComponent<HelpDialogComponent>(HelpDialogComponent);
          }
        }
      });
      this.errorsDialogShown$.subscribe((isShown: boolean | undefined) => {
        if (this.modals) {
          const { viewContainerRef } = this.modals;
          viewContainerRef.clear();
          if (isShown) {
            viewContainerRef.createComponent<ErrorsDialogComponent>(ErrorsDialogComponent);
          }
        }
      });

      this.playingDraw$.pipe(
        takeUntil(this.destroy$)
      ).subscribe(this.onPlayingDrawHandler.bind(this));

      this.lastPlayedDraws$
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe((draws: Array<IDraw>) => {
          this.lastDrawWithComb = draws[0];
        });

      interval(300)
        .pipe(
          takeUntil(this.destroy$)
        ).subscribe(() => {
          if (!this.isTranslation) {
            this.checkForTranslation();
          }
      });

      (window as any).startTranslation = this.onStartTranslation.bind(this);
      (window as any).stopTranslation = this.onStopTranslation.bind(this);

      this.loadingStage$.pipe(
        takeUntil(this.destroy$)
      ).subscribe((lStage: number) => {
        if (thisIframe && lStage === 2) {
          thisIframe.style.height = `${document.body.offsetHeight}px`;
        }
        SiteService.loadingStage = lStage;
      });

      this.webSocketService.isConnected$$
        .pipe(
          debounceTime(3000)
        )
        .subscribe((wsState: number) => {
          this.errorsService.showErrorScreen = wsState < 2;
        });

      // Последние данные для медиа-плеера
      this.translationResultsData$.pipe(
        filter((v) => !!v),
        takeUntil(this.destroy$)
      ).subscribe((trd: ITranslationResultsData | undefined) => {
        this.lastTRD = trd;
      });

      const docForVisibilityWatch = inIframe() ? window.parent.document : document;
      docForVisibilityWatch.onvisibilitychange = () => {
        if (!docForVisibilityWatch.hidden) {
          this.checkForTranslation();
        }
      };

      SiteService.watchForContentHeight();
    }
  }

  /**
   * Обработчик события уничтожения компонента
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

