import { createReducer, on } from '@ngrx/store';
import { closeHelpDialog, openHelpDialog, openHelpDialogSuccess } from '@app/store/actions/help-dialog.actions';
import { IHelpDialog } from '@app/core/interfaces/i-help-dialog';

/**
 * Начальное состояние для диалога справки
 */
export const initialState: IHelpDialog = {
  isShown: false,
  title: '',
  content: ''
};

/**
 * Редуктор для диалога справки
 */
export const helpDialogReducer = createReducer(
  initialState,
  on(openHelpDialog, (state) => state),
  on(openHelpDialogSuccess, (state, { title, content }) => ({
    title,
    content,
    isShown: true
  })),
  on(closeHelpDialog, (state) => ({
    ...state,
    isShown: false
  }))
);
