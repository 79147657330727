import { Component, forwardRef, ViewEncapsulation } from '@angular/core';
import { Swiper } from 'swiper';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { timer } from 'rxjs';

/**
 * Компонент прокручиваемого барабана на столе чисел
 */
@Component({
  selector: 'app-reel',
  templateUrl: './reel.component.html',
  styleUrls: ['./reel.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => ReelComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ReelComponent implements ControlValueAccessor {
  /**
   * Объект карусели
   */
  swiper: Swiper | undefined;

  /**
   * Текущее выбранное значение компонента
   * @private
   */
  private val = 0;

  /**
   * Унаследованное событие от базового класса.
   * Вызывается в коллбеке при изменении значения UI-элемента
   * @param v Передаваемое значение
   */
  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  onChange: any = (v: number) => {};

  /**
   * Унаследованное событие от базового класса
   */
  // eslint-disable-next-line class-methods-use-this
  onTouched: any = () => {};

  /**
   * Сеттер для установки значения
   * @param val Значение
   */
  set value(val: number) {
    this.val = val;
    // this.onChange(val);
    // this.onTouched();
  }

  /**
   * Метод для программной установки слайда (цифры)
   * @param value Цифра
   * @private
   */
  private setSlide(value: number): void {
    const allSlides = this.swiper?.slides;
    if (allSlides) {
      let groupNum = 0;
      for (let i = 0; i < allSlides.length; i += 1) {
        if (allSlides[i].textContent === value.toString()) {
          groupNum += 1;
        }
        if (groupNum === 2) {
          if (this.swiper?.activeIndex !== i) {
            this.swiper?.slideTo(i);
          }
          break;
        }
      }
    }
  }

  /**
   * Метод для программного присвоения значения компоненту
   * @param value Значение
   */
  writeValue(value: number) {
    if (Number.isInteger(value)) {
      this.val = value;
      timer(200)
        .subscribe(() => {
          this.setSlide(value);
        });
    }
  }

  /**
   * Метод, который вызывается при изменении значения UI-элемента
   * @param fn Передаваемая callback-функция
   */
  registerOnChange(fn: (v: number) => void): void {
    this.onChange = fn;
  }

  /**
   * Метод, который вызывается при касании к UI-элементу
   * @param fn Передаваемая callback-функция
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Обработчик события инициализации карусели
   * @param event
   */
  onSwiper(event: any): void {
    this.swiper = event;
  }

  /**
   * Обработчик события смены слайда (цифры)
   */
  onSlideChange(): void {
    const currentIndex = this.swiper?.activeIndex;
    const currentSlide = this.swiper?.slides[currentIndex || 1];
    this.val = +(currentSlide?.textContent || '0');
    if (this.val) {
      this.onChange(this.val);
    }
  }

  /**
   * Обработчик события клика на слайде (цифре)
   */
  onSlideClick(event: [Swiper, PointerEvent | MouseEvent | TouchEvent]): void {
    if (event[1]?.target) {
      const slideValue = +(event[1].target as HTMLElement).innerText;
      this.setSlide(slideValue);
    }
  }
}
