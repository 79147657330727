import {
  Component, Inject, OnDestroy, OnInit
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { IDraw } from '@app/core/interfaces/i-draw';
import { Store } from '@ngrx/store';
import {
  selectAvailableDraws,
  selectCurrentDraw,
  selectLastPlayedDraws
} from '@app/store/selectors/draws.selectors';
import { map, takeUntil } from 'rxjs/operators';
import { APP_CONFIG, TRAN_DELAY } from '@app/core/utils';
import { IConfig } from '@app/core/interfaces/i-config';
import { selectBetTypes } from '@app/store/selectors/bet-types.selectors';
import { IBetType } from '@app/core/interfaces/i-bet-type';

/**
 * Компонент, содержащий все столы игры П4
 */
@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit, OnDestroy {
  /**
   * Смещение для киевской временной зоны
   */
  timeZoneOffset: number;

  /**
   * Наблюдаемая переменная с доступными тиражами
   */
  availableDraws$: Observable<Array<IDraw>>;

  /**
   * Последние разыгранные тиражи
   */
  lastPlayedDraws: Array<IDraw> = [];

  /**
   * Наблюдаемая переменная с последними разыгранными тиражами
   */
  lastPlayedDraws$: Observable<Array<IDraw>>;

  /**
   * Наблюдаемая переменная с текущим тиражом
   */
  currentDraw$: Observable<IDraw | undefined> = of(undefined);

  betTypes$: Observable<Array<IBetType>> = of([]);

  /**
   * Минимальная ставка (в грн)
   */
  minBet = 1;

  /**
   * Максимальная ставка (в грн)
   */
  maxBet = 1000;

  /**
   * Максимальное количество тиражей, доступных для регистрации ставки
   */
  maxDraws = 1;

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   * @param config
   */
  constructor(
    private readonly store: Store,
    @Inject(APP_CONFIG) private readonly config: IConfig
  ) {
    this.timeZoneOffset = config.timezoneOffset || 2;
    this.availableDraws$ = store.select(selectAvailableDraws);
    this.lastPlayedDraws$ = store.select(selectLastPlayedDraws);
    this.currentDraw$ = store.select(selectCurrentDraw);
    this.betTypes$ = this.store.select(selectBetTypes);
  }

  /**
   * Обработчик, вызываемый при получении доступных тиражей из наблюдаемой переменной
   * @param draws Массив доступных тиражей
   * @private
   */
  private onDrawsAvailable(draws: Array<IDraw>): void {
    this.minBet = Math.max(...draws.map((draw: IDraw) => draw.betData.min_bet));
    this.maxBet = Math.min(...draws.map((draw: IDraw) => draw.betData.max_bet));
    this.maxDraws = draws.length;
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    this.availableDraws$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(this.onDrawsAvailable.bind(this));

    this.lastPlayedDraws$.pipe(
      map((lpDraws: Array<IDraw>) => lpDraws
        .filter((lpDraw: IDraw) => {
          const gameEndTS = (new Date(lpDraw.game_end)).getTime();
          return Date.now() - gameEndTS > TRAN_DELAY;
        })
        .slice(0, 3)),
      takeUntil(this.destroy$)
    ).subscribe((lpDraws: Array<IDraw>) => {
      this.lastPlayedDraws = lpDraws;
    });
  }

  /**
   * Обработчик события уничтожения компонента
   */
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
