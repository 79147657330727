import {
  Component, EventEmitter, Output
} from '@angular/core';
import { IGameMenu } from '@app/shared/interfaces/i-game-menu';
import { environment } from '../../../../environments/environment';

/**
 * Состояние кнопок мобильного меню
 */
const MENU_STATE: IGameMenu = {
  bets: false,
  stats: false,
  results: false
};

/**
 * Компонент мобильного нижнего меню
 */
@Component({
  selector: 'app-game-menu',
  templateUrl: './game-menu.component.html',
  styleUrls: ['./game-menu.component.scss']
})
export class GameMenuComponent {
  /**
   * Развернуто ли меню
   */
  expanded = false;

  /**
   * Открыто ли веб-приложение через мобильное приложение
   */
  isMobile = environment.isMobile;

  /**
   * Переменная, выводящая в событие новое состояние меню
   */
  @Output() selected: EventEmitter<IGameMenu> = new EventEmitter<IGameMenu>();

  /**
   * Обработчик выбора элемента меню
   * @param item Элемент меню
   */
  onSelectItem(item: keyof IGameMenu): void {
    this.expanded = false;
    this.selected.emit({
      ...MENU_STATE,
      [item]: true
    });
  }
}
