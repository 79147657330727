<h2 class="caption"
    [class.caption_effects]="effects"
    data-cy="caption"
    (click)="onCaptionClick()">
  <div class="caption__triangle"
       [class.caption__triangle_visible]="(spoiler && spoilerExpanded) || cornerColor"
       [style.border-left-color]="cornerColor"
  ></div>
  <div class="caption__inner"
       [class.caption__inner_spoiler]="spoiler"
       [class.caption__inner_spoiler_expanded]="spoiler && spoilerExpanded"
       [style.padding]="padding"
       data-cy="caption-inner"
  >
    <div class="caption__corner caption__corner_lt hidden"
         [class.desktop-visible]="corners"
         [class.tablet-visible]="cornersTablet"
         [class.mobile-visible]="cornersMobile"
    ></div>
    {{ title }}
    <ng-content></ng-content>
    <div class="caption__corner caption__corner_rb hidden"
         [class.desktop-visible]="corners"
         [class.tablet-visible]="cornersTablet"
         [class.mobile-visible]="cornersMobile"
    ></div>
  </div>
</h2>
<div *ngIf="effects" class="shadow"></div>
