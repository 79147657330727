import {
  Component, EventEmitter, Input, Output
} from '@angular/core';

/**
 * Компонент причудливой кнопки со срезанными углами
 */
@Component({
  selector: 'app-freaky-button',
  templateUrl: './freaky-button.component.html',
  styleUrls: ['./freaky-button.component.scss']
})
export class FreakyButtonComponent {
  /**
   * Текст кнопки
   */
  @Input() title = '';

  /**
   * Недоступна ли кнопка?
   */
  @Input() isDisabled = false;

  /**
   * Показана ли анимация занятости каким-либо действием?
   */
  @Input() isBusy = false;

  /**
   * Переменная, выводящая в событие клик по кнопке
   */
  @Output() press = new EventEmitter<Event>();
}
