import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  combineLatest, interval, Observable, Subject, timer
} from 'rxjs';
import { Store } from '@ngrx/store';
import { selectCounters } from '@app/store/selectors/draws-extra.selectors';
import { takeUntil } from 'rxjs/operators';
import { ITranslationState } from '@app/core/interfaces/i-translation-state';
import { selectTranslations } from '@app/store/selectors/translation.selectors';
import { Actions, ofType } from '@ngrx/effects';
import {APP_CONFIG, DRAWS_INTERVALS, MAX_TIMER_INTEGER} from '@app/core/utils';
import { environment } from '../../../../environments/environment';
import {IConfig} from "@app/core/interfaces/i-config";

/**
 * Начальная задержка для повторного запроса
 * при невозможности получить данные для счетчиков
 */
const START_DELAY = 2000;

/**
 * Коэффициент для прогрессивного увеличения задержки
 * для повторных попыток получить данные для счетчиков
 */
const DELAY_ODD = 1.3;

/**
 * Интерфейс для счетчика
 */
interface ICounter {
  /**
   * Код лотереи в качестве индекса,
   * дата окончания текущего тиража по ней - в качестве значения
   */
  [lottCode: number]: Date;
}

/**
 * Интерфейс для счетчиков
 */
interface ICounters {
  /**
   * Код лотереи в качестве индекса,
   * даты окончания приема ставок на тиражи по ней - в качестве значения
   */
  [lottCode: number]: Array<Date>;
}

/**
 * Компонент главного меню в приложении
 */
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  /**
   * Выполняется ли сейчас запрос получения данных для счетчиков?
   * @private
   */
  private isLoading = false;

  /**
   * Значение прогрессивной задержки между попытками получить данные
   * @private
   */
  private progressiveDelay = START_DELAY;

  /**
   * Наблюдаемая переменная с данными для счетчиков
   */
  counters$: Observable<ICounters>;

  /**
   * Наблюдаемая переменная с данными для состояния трансляций
   */
  translations$: Observable<{[lottCode: number]: ITranslationState}>;

  /**
   * Переменная с данными для состояния трансляций
   */
  translations: {[lottCode: number]: ITranslationState} = {};

  /**
   * Ближайшая дата, до которой должен тикать счетчик
   */
  nearest: ICounter = {};

  /**
   * Работает ли веб-приложение внутри мобильного приложения?
   */
  isMobile = environment.isMobile;

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   * @param getDrawsExtra$ Наблюдаемая переменная по успешному получению данных
   * @param config
   */
  constructor(
    private readonly store: Store,
    private readonly getDrawsExtra$: Actions,
    @Inject(APP_CONFIG) readonly config: IConfig
  ) {
    this.counters$ = store.select(selectCounters);
    this.translations$ = store.select(selectTranslations);
    getDrawsExtra$.pipe(
      ofType('[Draws Extra] Get Extra Draws Success'),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.isLoading = false;
    });
  }

  /**
   * Метод, вызывающий запрос на получения данных для счетчиков
   * @private
   */
  private getCountersDraws(): void {
    if (!this.isLoading) {
      this.isLoading = true;
      timer(this.progressiveDelay).subscribe(() => {
        this.store.dispatch({ type: '[Draws Extra] Get Extra Draws', extra: [30] });
      });
      this.progressiveDelay *= DELAY_ODD;
      if (this.progressiveDelay > MAX_TIMER_INTEGER) {
        this.progressiveDelay = MAX_TIMER_INTEGER;
      }
    }
  }

  /**
   * Метод для получения ближайших дат окончания приема ставок
   * на текущие тиражи по каждой лотерее. Даты получает из хранилища,
   * при необходимости подгружая их еще, если в хранилище закончились
   * @param counters
   * @private
   */
  private countersTick([counters]: [ICounters, number]): void {
    let needToAddDraws = false;
    Object.keys(counters).forEach((lottCode: string) => {
      const ts = Date.now();
      const diffs = counters[+lottCode]
        .map((d: Date) => (new Date(d)).getTime() - ts)
        .filter((v) => v >= 0);
      if (diffs.length <= 1) {
        needToAddDraws = true;
      }
      const minDiff = Math.min(...diffs) % (DRAWS_INTERVALS[+lottCode] * 1000);
      this.nearest[+lottCode] = new Date(ts + minDiff);
    });
    if (needToAddDraws) {
      this.getCountersDraws();
    } else {
      this.progressiveDelay = START_DELAY;
    }
  }

  /**
   * Событие инициализации компонента
   */
  ngOnInit(): void {
    this.translations$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((translations) => {
      this.translations = translations;
    });
    combineLatest([this.counters$, interval(500)])
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe(this.countersTick.bind(this));
    this.getCountersDraws();
  }

  /**
   * Событие уничтожения компонента
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
