import {
  Component, Inject, OnDestroy, OnInit
} from '@angular/core';
import { IDraw } from '@app/core/interfaces/i-draw';
import { selectCurrentDraw } from '@app/store/selectors/draws.selectors';
import {
  combineLatest, Observable, of, Subject
} from 'rxjs';
import {
  filter, finalize, take, timeout
} from 'rxjs/operators';
import { IBetType } from '@app/core/interfaces/i-bet-type';
import { selectBetTypes } from '@app/store/selectors/bet-types.selectors';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ApiService } from '@app/core/services/api.service';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import { ErrorsService } from '@app/core/services/errors.service';
import { APP_CONFIG } from '@app/core/utils';
import { IConfig } from '@app/core/interfaces/i-config';
import { DOCUMENT } from '@angular/common';

/**
 * Главный компонент игры П4
 */
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  /**
   * Наблюдаемая переменная, содержащая текущий тираж
   */
  currentDraw$: Observable<IDraw | undefined> = of(undefined);

  /**
   * Наблюдаемая переменная, содержащая возможные типы ставок
   */
  betTypes$: Observable<Array<IBetType>> = of([]);

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   * @param getTicketsList$ Наблюдаемая переменная действий при получении списка билетов
   * @param translateService Сервис переводов
   * @param router Маршрутизатор приложения
   * @param apiService API-сервис
   * @param errorsService Сервис обработки ошибок
   * @param config
   * @param doc
   */
  constructor(
              private readonly store: Store,
              private readonly getTicketsList$: Actions,
              readonly translateService: TranslateService,
              readonly router: Router,
              private readonly apiService: ApiService,
              private readonly errorsService: ErrorsService,
              @Inject(APP_CONFIG) private readonly config: IConfig,
              @Inject(DOCUMENT) private doc: Document
  ) {
    this.currentDraw$ = this.store.select(selectCurrentDraw);
    this.betTypes$ = this.store.select(selectBetTypes);
  }

  get ticketsCheckURL(): string {
    if (this.config.ticketsCheckLink?.url) {
      return this.config.ticketsCheckLink.url;
    }
    return `https://win.${this.doc.location.host}/`;
  }

  get ticketsCheckTitle(): string {
    if (this.config.ticketsCheckLink?.title) {
      if (this.config.ticketsCheckLink?.title[this.translateService.currentLang]) {
        return this.config.ticketsCheckLink.title[this.translateService.currentLang];
      }
    }
    if (this.config.ticketsCheckLink?.url) {
      const urlObject = new URL(this.config.ticketsCheckLink.url);
      return urlObject.hostname;
    }
    return `win.${this.doc.location.hostname}`;
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    combineLatest([
      this.currentDraw$.pipe(
        filter((v) => !!v)
      ),
      this.betTypes$.pipe(
        filter((v) => !!v.length)
      )
    ]).pipe(
      timeout(10000),
      take(1),
      finalize(() => {
        this.store.dispatch({ type: '[Preloader] Increase Stage' });
      })
    ).subscribe(() => {
      this.errorsService.showErrorScreen = false;
    }, () => {
      this.errorsService.showErrorScreen = true;
    });

    this.store.dispatch({ type: '[Draws List] Get List Of Draws', lottCode: 30 });
    this.store.dispatch({ type: '[Bet Types List] Get Lott Data', lottCode: 30 });

    if (this.apiService.token) {
      this.store.dispatch({ type: '[Tickets List] Get List Of Tickets', lottCode: 30 });
    }
  }

  /**
   * Обработчик события уничтожения компонента
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
