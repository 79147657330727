import { Injectable } from '@angular/core';
import { MobileAction } from '@app/core/enums/mobile-action';

/**
 * Модель, используемая для идентификации-модуля страницы сторонними приложениями.
 */
export interface ModuleRouteId {
  /**
   * URL (маршрут) модуля
   */
  moduleUrl: string;

  /**
   * Код игры
   */
  gameCode: number;
}

/**
 * Модель объекта мобильного приложения.
 */
export interface IAndroid {
  /**
   * Выполнить какое-либо действие, определенное в МП
   * @param action Действие
   * @param data Параметры для действия
   */
  doAction(action: MobileAction, data: string): void;

  /**
   * Получить какие-либо данные из МП
   * @param action Действие
   */
  getData(action: MobileAction): string;
}

/**
 * Сервис по взаимодействию с мобильным приложением
 */
@Injectable({
  providedIn: 'root'
})
export class MobileService {
  /**
   * Метод для получения токена авторизации из МП
   */
  static getAuthToken(): string {
    return (window as any).android.getData(MobileAction.GetAuthToken || '');
  }

  /**
   * Метод для показа окна входа в мобильном приложении
   */
  static refreshAuthToken(): void {
    (window as any).android.doAction(MobileAction.RefreshAuthToken, '');
  }

  /**
   * Метод, указывающий МП, что в веб-приложении перешли на другой маршрут
   * @param moduleIdFrom С какого маршрута
   * @param moduleIdTo На какой
   */
  static changeLocation(moduleIdFrom: ModuleRouteId, moduleIdTo: ModuleRouteId): void {
    const data = JSON.stringify({
      from: moduleIdFrom,
      to: moduleIdTo
    });
    (window as any).android.doAction(MobileAction.ChangeLocation, data);
  }
}
