import { createAction, props } from '@ngrx/store';
import { IBetType } from '@app/core/interfaces/i-bet-type';

/**
 * Действие для запроса получения данных по лотерее
 */
export const getLottData = createAction('[Bet Types List] Get Lott Data', props<{lottCode: number}>());

/**
 * Действие для успешного получения данных по лотерее
 */
export const getLottDataSuccess = createAction('[Bet Types List] Get Lott Data Success', props<{betTypes: Array<IBetType>}>());

/**
 * Действие для ошибки получения данных по лотерее
 */
export const getLottDataError = createAction('[Bet Types List] Get Lott Data Error');
