import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError, map, switchMap
} from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorsService } from '@app/core/services/errors.service';
import { ApiService } from '@app/core/services/api.service';
import { IArchiveResponse } from '@app/core/interfaces/i-archive-response';

/**
 * Эффекты для работы с архивом тиражей
 */
@Injectable()
export class DrawsArchiveEffects {
  /**
   * Наблюдаемая переменная в результате создания эффекта получения архива тиражей
   * Сама переменная не используется
   */
  loadDrawsArchive$ = createEffect(() => this.actions$.pipe(
    ofType('[Draws Archive] Get Draws Archive'),
    switchMap((action: {lottCode: number, dateStart: string}) => this.apiService.getDrawsArchive(
      action.lottCode,
      action.dateStart
    )
      .pipe(
        map((archiveResponse: IArchiveResponse) => ({
          type: '[Draws Archive] Get Draws Archive Success',
          lottCode: action.lottCode,
          dateStart: action.dateStart,
          draws: archiveResponse.history
        })),
        catchError((error: Error) => {
          this.errorsService.handleError(error);
          return of({ type: '[Draws Archive] Get Draws Archive Error' });
        })
      ))
  ));

  /**
   * Конструктор эффекта
   * @param actions$ Наблюдаемая переменная действий
   * @param apiService Экземпляр API-сервиса
   * @param errorsService Экземпляр сервиса обработки ошибок
   */
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly errorsService: ErrorsService
    // eslint-disable-next-line no-empty-function
  ) { }
}
