import {
  Component, EventEmitter, Inject, OnInit, Output
} from '@angular/core';
import { ISortingMenu } from '@app/features/pik4/interfaces/i-sorting-menu';
import { Observable } from 'rxjs';
import { IDraw } from '@app/core/interfaces/i-draw';
import { Store } from '@ngrx/store';
import { selectCurrentDraw } from '@app/store/selectors/draws.selectors';
import { selectPlayedBets, selectUnplayedBets, selectUnplayedBetsMulti } from '@app/store/selectors/bets.selectors';
import { IBetItem } from '@app/features/pik4/interfaces/i-bet-item';
import { ApiService } from '@app/core/services/api.service';
import { APP_CONFIG } from '@app/core/utils';
import { IConfig } from '@app/core/interfaces/i-config';

/**
 * Компонент активных ставок
 */
@Component({
  selector: 'app-bets-info',
  templateUrl: './bets-info.component.html',
  styleUrls: ['./bets-info.component.scss']
})
export class BetsInfoComponent implements OnInit {
  /**
   * Переменная, выводящая в событие клик по кнопке "Назад к ставкам"
   */
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Видимый ли блок сортировки разделов ставок
   */
  sortingBlockVisible = false;

  /**
   * Элементы в меню сортировки разделов ставок
   */
  menuItems: Array<ISortingMenu> = [{
    title: 'bets.unplayed-first',
    ordering: [1, 2, 3]
  }, {
    title: 'bets.unplayed-multi-first',
    ordering: [2, 1, 3]
  }, {
    title: 'bets.played-first',
    ordering: [2, 3, 1]
  }];

  /**
   * Текущий выбранный элемент в меню сортировки
   */
  currentMenuItem: ISortingMenu = this.menuItems[0];

  /**
   * Состояние спойлеров для разделов ставок: true - открыт, false - закрыт
   */
  spoilersStates = [true, false, true];

  /**
   * Текущий тираж (наблюдаемая переменная)
   */
  currentDraw$: Observable<IDraw | undefined>;

  /**
   * Разыгранные ставки (наблюдаемая переменная)
   */
  playedBets$: Observable<Array<IBetItem>>;

  /**
   * Не разыгранные ставки (наблюдаемая переменная)
   */
  unplayedBets$: Observable<Array<IBetItem>>;

  /**
   * Не разыгранные многотиражные ставки (наблюдаемая переменная)
   */
  unplayedBetsMulti$: Observable<Array<IBetItem>>;

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   * @param apiService API-сервис
   * @param config Объект конфигурации приложения
   */
  constructor(
    private store: Store,
    readonly apiService: ApiService,
    @Inject(APP_CONFIG) readonly config: IConfig
  ) {
    this.playedBets$ = this.store.select(selectPlayedBets);
    this.unplayedBets$ = this.store.select(selectUnplayedBets);
    this.unplayedBetsMulti$ = this.store.select(selectUnplayedBetsMulti);
    this.currentDraw$ = this.store.select(selectCurrentDraw);
  }

  /**
   * Обработчик смены состояния спойлера для раздела ставок
   * @param index Индекс раздела
   * @param state Состояние
   */
  onSpoilerStateChange(index: number, state: boolean): void {
    this.spoilersStates[index] = state;
    localStorage.setItem('pick-4_bets-spoilers', JSON.stringify(this.spoilersStates));
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    const savedStates = localStorage.getItem('pick-4_bets-spoilers');
    if (savedStates) {
      this.spoilersStates = JSON.parse(savedStates);
    }
  }
}
