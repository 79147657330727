import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IBetType } from '@app/core/interfaces/i-bet-type';

/**
 * Селектор всех типов ставок
 */
export const selectBetTypes = createFeatureSelector<Array<IBetType>>('betTypes');

/**
 * Селектор всех типов ставок, кроме первого стола
 */
export const selectBetTypesNot1 = createSelector(
  selectBetTypes,
  (betTypes: Array<IBetType>) => betTypes.filter((btType: IBetType) => btType.table !== 1)
);
