<div *ngIf="gameParams"
     class="game-control game-control_theme_pik4"
>
  <label for="bet_value_{{ uid }}" class="c-title c-title_bet" data-cy="c-title-bet">
    {{ 'game-control.bet' | translate }}
    <span *ngIf="isFinite(minBet) && isFinite(maxBet)">({{ 'game-control.from-to' | translate:{min: minBet, max: maxBet} }})</span>
  </label>
  <div class="bet">
    <div data-cy="bet-control" class="bet__control">
      <button class="bet__btn bet__btn_minus"
              type="button"
              [attr.disabled]="this.gameParams.bet <= this.minBet || null"
              (click)="onBetDecrease()"
      ></button>
      <input id="bet_value_{{ uid }}"
             class="bet__value"
             type="number"
             inputmode="numeric"
             pattern="\d+"
             data-cy="bet-value"
             [min]="minBet"
             [max]="maxBet"
             [(ngModel)]="gameParams.bet"
             (ngModelChange)="onBetModelChange()"
             (input)="onBetFieldInput($event)"
             (keydown)="onBetFieldKeyDown($event)"
             (blur)="onBetFieldBlur($event)"
      />
      <button class="bet__btn bet__btn_plus"
              type="button"
              [attr.disabled]="this.gameParams.bet >= this.maxBet || null"
              (click)="onBetIncrease()"
      ></button>
    </div>
    <button data-cy="bet-x2" type="button" class="bet__x2" (click)="onBetDouble()">x2</button>
  </div>

  <div data-cy="bet-buttons" class="bet-buttons">
    <button data-cy="bet-button" type="button" class="bet-button" (click)="onAddToBet(2)">+2</button>
    <button data-cy="bet-button" type="button" class="bet-button" (click)="onAddToBet(5)">+5</button>
    <button data-cy="bet-button" type="button" class="bet-button" (click)="onAddToBet(10)">+10</button>
    <button data-cy="bet-button" type="button" class="bet-button" (click)="onAddToBet(50)">+50</button>
    <button data-cy="bet-button" type="button" class="bet-button" (click)="onAddToBet(100)">+100</button>
    <button data-cy="bet-button" type="button" class="bet-button" (click)="onAddToBet(500)">+500</button>
  </div>

  <div data-cy="draws" class="draws">
    <label data-cy="draws-title" class="draws__title c-title" for="draws_count_{{ uid }}">{{ 'game-control.draws-count' | translate }}</label>
    <div class="draws__setting">
      <div class="draws__current"
           [style.left.%]="percentage"
           [style.transform]="'translateX(-' + percentage + '%)'"
      >{{ gameParams.drawsCount }}</div>
      <input type="range"
             id="draws_count_{{ uid }}"
             class="draws__count"
             min="{{ minDraws || 1 }}"
             max="{{ maxDraws || 1000 }}"
             [(ngModel)]="gameParams.drawsCount"
             (ngModelChange)="onChange(gameParams)"
      />
      <div class="draws__selection" [style.width.%]="percentage"></div>
      <div class="draws__track"></div>
      <div class="draws__min">{{ minDraws }}</div>
      <div class="draws__max">{{ maxDraws }}</div>
    </div>
  </div>

  <ng-content></ng-content>
</div>
