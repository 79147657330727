import { Component, forwardRef, Input } from '@angular/core';
import { ISortingMenu } from '@app/features/pik4/interfaces/i-sorting-menu';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Компонент меню сортировки
 */
@Component({
  selector: 'app-sorting-menu',
  templateUrl: './sorting-menu.component.html',
  styleUrls: ['./sorting-menu.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => SortingMenuComponent),
      multi: true
    }
  ]
})
export class SortingMenuComponent implements ControlValueAccessor {
  /**
   * Элементы в меню сортировки
   */
  @Input() items: Array<ISortingMenu> = [];

  /**
   * Видимое ли меню сортировки?
   */
  sortingMenuVisible = false;

  /**
   * Текущее выбранное значение
   */
  val: ISortingMenu = { ...this.items[0] };

  /**
   * Случайное число для уникальности ID DOM-элементов
   */
  uid = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

  /**
   * Унаследованное событие от базового класса.
   * Вызывается в коллбеке при изменении значения UI-элемента
   * @param v Передаваемое значение
   */
  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  onChange: any = (v: ISortingMenu) => {};

  /**
   * Унаследованное событие от базового класса
   */
  // eslint-disable-next-line class-methods-use-this
  onTouched: any = () => {};

  /**
   * Метод для программного присвоения значения компоненту
   * @param value Значение
   */
  writeValue(value: ISortingMenu) {
    this.val = { ...value };
  }

  /**
   * Метод, который вызывается при изменении значения UI-элемента
   * @param fn Передаваемая callback-функция
   */
  registerOnChange(fn: (v: ISortingMenu) => void): void {
    this.onChange = fn;
  }

  /**
   * Метод, который вызывается при касании к UI-элементу
   * @param fn Передаваемая callback-функция
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Обработчик выбора элемента меню сортировки
   * @param menuItem Элемент меню сортировки
   */
  onSortingMenuChange(menuItem: ISortingMenu): void {
    this.sortingMenuVisible = false;
    this.val = { ...menuItem };
    this.onChange(this.val);
  }
}
