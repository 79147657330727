import { createReducer, on } from '@ngrx/store';
import { IStats } from '@app/core/interfaces/i-stats';
import { getStats, getStatsError, getStatsSuccess } from '@app/store/actions/stats.actions';

/**
 * Начальное состояние хранилища статистики
 */
export const initialState: { [t: number]: IStats | undefined } = {};

/**
 * Редуктор для хранилища статистики
 */
export const statsReducer = createReducer(
  initialState,
  on(getStats, (state) => state),
  on(getStatsSuccess, (state, { t, stats }) => ({
    ...state,
    [t]: stats
  })),
  on(getStatsError, (state) => state)
);
