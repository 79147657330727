<div class="draw-info draw-info_theme_pik4">

    <h2 class="draw-title caption" data-cy="draw-title-caption">
      <div class="draw-title__inner caption__inner" data-cy="caption-inner">
        <svg class="draw-title__logo" width="93" height="48" viewBox="0 0 93 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.3757 6.3823L20.67 6.58993L21.0291 6.56221L88.004 1.39159L91.542 3.97863L89.8212 18.9454L65.1702 20.7876L64.3559 20.8485L64.2528 21.6586L61.2802 45.0115L39.2296 46.6126L28.2547 39.6639L29.8315 28.2188L29.9232 27.5536L29.3421 27.217L24.3483 24.3246L24.08 24.1692L23.7709 24.1928L6.39769 25.5201L1.46323 22.0442L3.57092 4.78092L16.7242 3.80632L20.3757 6.3823Z" fill="white" stroke="#003399" stroke-width="2" stroke-miterlimit="10"/>
          <path d="M16.1393 8.60413L14.6195 19.6247L11.3989 19.881L12.6654 10.9474L9.51714 11.1671C9.44477 11.0572 9.33621 11.0206 9.19146 11.0206C9.0829 11.0206 9.01053 11.0572 8.90197 11.1671C8.8296 11.2403 8.79341 11.3501 8.79341 11.46C8.79341 11.5698 8.8296 11.6796 8.90197 11.7163C8.97434 11.7895 9.0829 11.8261 9.19146 11.8261C9.33621 11.8261 9.48096 11.7163 9.55333 11.5698L10.8199 11.46L9.62571 20.0641L6.40508 20.3204L7.59925 11.3501L6.3689 11.4233L6.58602 10.1419L7.81637 10.0687V9.88559L11.0008 9.6293V9.81236L12.8463 9.66591L12.9187 8.86042L16.1393 8.60413Z" fill="#003399"/>
          <path d="M23.2319 10.8741L22.6167 12.0824L20.4455 12.2288L18.5276 12.3387C18.4553 12.2288 18.3467 12.1922 18.2381 12.1922C18.1296 12.1922 18.0572 12.2288 17.9848 12.3021C17.9125 12.3753 17.8763 12.4485 17.8763 12.5584C17.8763 12.6682 17.9125 12.7414 17.9848 12.8146C18.0572 12.8879 18.1296 12.9245 18.2381 12.8879C18.3829 12.8879 18.4914 12.8146 18.5638 12.6316L19.6856 12.5584L19.3599 14.6819L22.0739 14.4622V15.6339L19.179 15.8535L19.0704 16.659L22.1101 16.4394L22.0739 17.611L15.9946 18.087L16.2841 16.1098L15.9584 16.1465V14.9748L16.465 14.9382L16.9716 11.3867L23.2319 10.8741Z" fill="#003399"/>
          <path d="M31.3016 10.2883L30.6502 15.0481C30.1798 15.0847 29.4922 15.1213 28.5514 15.1945C27.6105 15.2677 26.923 15.3043 26.4525 15.341L26.0545 18.087L23.1595 18.3066L24.209 10.8009C25.4031 10.7277 26.5973 10.6178 27.7553 10.5446L31.3016 10.2883ZM28.2257 11.643L26.8506 11.7529L25.765 11.8261C25.6926 11.7162 25.5841 11.643 25.4755 11.643C25.3669 11.643 25.2946 11.6796 25.2222 11.7529C25.1498 11.8261 25.1136 11.8993 25.1136 12.0092C25.1136 12.119 25.1498 12.1922 25.2222 12.2654C25.2946 12.3387 25.3669 12.3753 25.4755 12.3387C25.6202 12.3387 25.7288 12.2654 25.765 12.1556L26.8868 12.0458L26.5973 14.1693L27.8638 14.0595L28.2257 11.643Z" fill="#003399"/>
          <path d="M38.5751 9.73914L37.9237 10.9474L35.7525 11.0938L33.8346 11.2037C33.7623 11.0938 33.6537 11.0572 33.5451 11.0572C33.4366 11.0572 33.3642 11.0938 33.2918 11.1671C33.2195 11.2403 33.1833 11.3135 33.1833 11.4233C33.1833 11.5332 33.2195 11.6064 33.2918 11.6796C33.3642 11.7529 33.4366 11.7895 33.5451 11.7529C33.6899 11.7529 33.7985 11.6796 33.8708 11.4966L34.9926 11.4233L34.6669 13.5469L37.3809 13.3272V14.4989L34.486 14.7185L34.3774 15.524L37.4171 15.3044L37.3809 16.476L31.3016 16.9519L31.5911 14.9748L31.2654 15.0114V13.8398L31.772 13.8032L32.2786 10.2517L38.5751 9.73914Z" fill="#003399"/>
          <path d="M47.2237 10.3249L45.5953 14.1693L45.342 15.8535L43.3155 15.9999L43.605 14.0594L42.8451 10.5079L41.0358 10.6178C40.9634 10.5079 40.8548 10.4713 40.7463 10.4713C40.6377 10.4713 40.5653 10.5079 40.493 10.5812C40.4206 10.6544 40.3844 10.7642 40.3844 10.8375C40.3844 10.9473 40.4206 11.0205 40.493 11.0937C40.5653 11.167 40.6377 11.2036 40.7463 11.2036C40.891 11.2036 40.9996 11.1304 41.0358 11.0205L42.1938 10.9107L41.47 16.183L38.5751 16.4027L39.5521 9.66583L43.9307 9.33631L44.7268 11.0937L47.1152 9.08002L50.8424 8.78711L49.7568 16.2928L46.8618 16.5125L47.7665 10.1418L47.2237 10.3249Z" fill="#003399"/>
          <path d="M59.1653 8.20129C58.9844 9.33631 58.8397 10.4347 58.6587 11.5697L58.1521 14.9381L51.0233 15.4507L52.0004 8.71388L59.1653 8.20129ZM56.0895 9.55599C55.619 9.5926 55.1486 9.62921 54.7144 9.66582C54.5696 9.66582 54.3887 9.70244 54.1354 9.70244C53.8821 9.70244 53.7011 9.70244 53.5564 9.73905C53.484 9.62921 53.3755 9.5926 53.2669 9.5926C53.1583 9.5926 53.086 9.62921 53.0136 9.70244C52.9412 9.77566 52.905 9.84889 52.905 9.95873C52.905 10.0686 52.9412 10.1418 53.0136 10.215C53.086 10.2883 53.1583 10.3249 53.2669 10.2883C53.4116 10.2883 53.5202 10.215 53.5926 10.0686L54.6782 9.95873L54.0992 14.0228L55.4743 13.913L56.0895 9.55599Z" fill="#003399"/>
          <path d="M71.3966 7.24939L71.0709 9.18989L69.2615 11.5331L70.6366 12.4119L70.4195 14.0228L67.5246 14.2425L67.8141 12.2654L66.439 12.3752L65.3534 12.4119C65.281 12.302 65.1724 12.2654 65.0639 12.2654C64.9553 12.2654 64.883 12.302 64.8106 12.3752C64.7382 12.4485 64.702 12.5217 64.702 12.6315C64.702 12.7414 64.7382 12.8146 64.8106 12.8878C64.883 12.9611 64.9553 12.9977 65.0639 12.9611C65.2086 12.9611 65.281 12.8878 65.3534 12.778L66.5114 12.6315L66.2942 14.2791L63.3993 14.4988L64.4125 6.88325L67.3075 6.66357L66.6923 11.4233L68.1398 10.1418L68.5378 7.43245L71.3966 7.24939Z" fill="#003399"/>
          <path d="M63.7973 12.5583L62.4222 12.6682L62.1327 14.6087L59.2378 14.8284L59.4549 13.254L61.0833 12.1556L59.9615 9.88557L60.2148 8.16475L63.146 7.94507L62.7117 10.6545L63.8335 11.5698" fill="#003399"/>
          <path d="M79.828 6.62695L78.851 13.3638L75.956 13.5835L76.2455 11.6064L73.7848 11.7528C73.7125 11.643 73.6039 11.6064 73.4953 11.6064C73.3868 11.6064 73.3144 11.643 73.242 11.7162C73.1697 11.7894 73.1335 11.8627 73.1335 11.9725C73.1335 12.0823 73.1697 12.1556 73.242 12.2288C73.3144 12.302 73.3868 12.302 73.4953 12.302C73.6401 12.302 73.7486 12.2288 73.821 12.0457L74.9428 11.9725L74.7257 13.6567L71.8307 13.8764L72.0841 11.8993L71.7946 11.9359V10.7643L72.2288 10.7276L72.7354 7.13954L75.6304 6.91986L75.1237 10.508L76.3903 10.3981L76.9331 6.81002L79.828 6.62695Z" fill="#003399"/>
          <path d="M88.0063 6.04114L87.0654 12.778L84.1705 12.9977L84.46 11.0205L83.0487 11.1304L82.7592 13.0709L79.8643 13.2906L80.8413 6.59034L88.0063 6.04114ZM84.9304 7.43244L83.5191 7.54228L82.3973 7.61551C82.325 7.50567 82.2164 7.46905 82.1078 7.46905C81.9993 7.46905 81.9269 7.50567 81.8545 7.5789C81.7822 7.65212 81.746 7.72535 81.746 7.83519C81.746 7.94503 81.7822 8.01825 81.8545 8.09148C81.9269 8.16471 81.9993 8.20132 82.1078 8.16471C82.2526 8.16471 82.325 8.09148 82.3973 7.94503L83.4829 7.8718L83.1934 9.95876L84.6047 9.84892L84.9304 7.43244Z" fill="#003399"/>
          <path d="M57.935 19.0754L55.0763 40.8603L45.8125 41.8489L46.681 35.8443L33.2195 37.1258L35.9335 22.2974L44.8716 21.7116L43.3156 31.524L40.1312 31.7436C39.914 31.3409 39.6245 31.1578 39.1903 31.1578C38.8646 31.1944 38.6113 31.3043 38.3942 31.524C38.1771 31.7436 38.0685 32.0365 38.0685 32.3661C38.0685 32.6956 38.1771 32.9519 38.3942 33.1349C38.6113 33.3546 38.8646 33.4278 39.1903 33.4278C39.6245 33.3912 39.9502 33.2082 40.1673 32.842C40.6378 32.8054 41.1082 32.7688 41.6148 32.6956C42.0852 32.6224 42.5919 32.5857 43.0623 32.5491L47.2961 32.2562L49.0693 19.6613L57.935 19.0754Z" fill="#003399"/>
        </svg>

        <div class="draw-title__draw-no"
             data-cy="draw-no"
        >{{ currentDraw ? ('draw-info.draw-no' | translate) + currentDraw.number : ('draw-info.warning' | translate) }}</div>
      </div>
      <div class="caption__triangle caption__triangle_visible"></div>
    </h2>

    <div class="status-info" data-cy="status-info">
      <div data-cy="status-info-status"
           class="status-info__status"
      >{{ 'draw-info.playing-state' | translate }}</div>
      <div data-cy="status-info-title"
           class="status-info__title"
           [class.status-info__title_error]="!currentDraw"
      >{{ currentDraw ? ('draw-info.bets-accepting' | translate) : ('draw-info.no-draws' | translate) }}</div>
    </div>
</div>
