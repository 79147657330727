import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDraw } from '@app/core/interfaces/i-draw';
import {IPlayerDrawResult, ITranslationResultsData} from "@app/core/interfaces/translation-results-data";

/**
 * Функция преобразования тиража в вид, нужный для передачи в медиа-плеер
 * @param draw Архивный тираж
 */
const toPlayerDrawResult = (draw: IDraw): IPlayerDrawResult => ({
  name: draw.number,
  combination: draw.comb?.split(',').map((v) => v.trim().toUpperCase()) || []
});


/**
 * Селектор полного массива тиражей
 */
export const selectDraws = createFeatureSelector<Array<IDraw>>('draws');

/**
 * Селектор тиражей, доступных для ставок
 */
export const selectAvailableDraws = createSelector(selectDraws, (draws: Array<IDraw>) => {
  const currentTimestamp = Date.now();
  return draws.filter((draw: IDraw) => {
    const endTimestamp = (new Date(draw.end)).getTime();
    return currentTimestamp <= endTimestamp;
  });
});

/**
 * Селектор текущего тиража (тот, на который идет прием ставок)
 */
export const selectCurrentDraw = createSelector(selectDraws, (draws: Array<IDraw>) => {
  const currentTimestamp = Date.now();
  return draws.find((draw: IDraw) => {
    const startTimestamp = (new Date(draw.start)).getTime();
    const endTimestamp = (new Date(draw.end)).getTime();
    return startTimestamp <= currentTimestamp && currentTimestamp <= endTimestamp;
  });
});

/**
 * Селектор ближайшего разыгрываемого тиража. Может иногда совпадать с текущим
 */
export const selectPlayingDraw = createSelector(selectDraws, (draws: Array<IDraw>) => {
  const currentTimestamp = Date.now();
  const gameEnds = draws.map((draw: IDraw) => {
    const endTimestamp = (new Date(draw.game_end)).getTime();
    return endTimestamp - currentTimestamp;
  }).filter((v) => v >= 0);
  const minDiff = Math.min(...gameEnds);
  return draws.find((draw: IDraw) => {
    const endTimestamp = (new Date(draw.game_end)).getTime();
    return endTimestamp - currentTimestamp === minDiff;
  });
});

/**
 * Селектор старых тиражей (на которые больше не принимаются ставки)
 */
export const selectOldDraws = createSelector(selectDraws, (draws: Array<IDraw>) => {
  const currentTimestamp = Date.now();
  return draws.filter((draw: IDraw) => {
    const endTimestamp = (new Date(draw.end)).getTime();
    return endTimestamp < currentTimestamp;
  });
});

/**
 * Селектор последних разыгранных тиражей с известной комбинацией
 */
export const selectLastPlayedDraws = createSelector(
  selectDraws,
  (draws: Array<IDraw>) => draws.filter((draw: IDraw) => !!draw.comb)
);

/**
 * Селектор последнего разыгранного тиража (может быть без комбинации)
 */
export const selectLastPlayedDraw = createSelector(
  selectDraws,
  (draws: Array<IDraw>) => {
    const currentTimestamp = Date.now();
    const gameEnds = draws.map((draw: IDraw) => {
      const endTimestamp = (new Date(draw.game_end)).getTime();
      return currentTimestamp - endTimestamp;
    }).filter((v) => v >= 0);
    const minDiff = Math.min(...gameEnds);
    return draws.find((draw: IDraw) => {
      const endTimestamp = (new Date(draw.game_end)).getTime();
      return currentTimestamp - endTimestamp === minDiff;
    });
  }
);

/**
 * Селектор результатов тиражей для трансляции
 */
export const selectResultsForTranslation = createSelector(
  selectDraws,
  (draws: Array<IDraw>): ITranslationResultsData | undefined => {
    const drawsWithCombs = draws.filter((draw: IDraw) => !!draw.comb);
    if (drawsWithCombs.length < 5) {
      return undefined;
    }

    const drawResult = toPlayerDrawResult(drawsWithCombs[0]);
    const prevResults = [];
    for (let i = 1; i <= 4; i += 1) {
      prevResults.push(toPlayerDrawResult(drawsWithCombs[i]));
    }
    return {
      drawResult,
      prevResults
    };
  }
);
