import { Directive, ViewContainerRef } from '@angular/core';

/**
 * Директива для показа модальных окон в приложении
 */
@Directive({
  selector: '[appModals]'
})
export class ModalsDirective {
  /**
   * Конструктор директивы
   * @param viewContainerRef Ссылка на контейнер для модальных окон
   */
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(public viewContainerRef: ViewContainerRef) { }
}
