import { Component, Input } from '@angular/core';
import { IDraw } from '@app/core/interfaces/i-draw';

/**
 * Компонент информации о текущем тираже
 * (тираже, на который идет прием ставок)
 */
@Component({
  selector: 'app-draw-info',
  templateUrl: './draw-info.component.html',
  styleUrls: ['./draw-info.component.scss']
})
export class DrawInfoComponent {
  /**
   * Тираж
   */
  @Input() currentDraw: IDraw | undefined = undefined;
}
