/**
 * Объект внутренней конфигурации приложения для окружения тестов и mock-режима
 */
export const environment = {
  /**
   * URL для обращения к API (не используется, может быть что угодно)
   */
  api: 'wss://dev.msl.emict.net/ws-fdl/',
  /**
   * Флаг прод-режима
   */
  production: false,
  /**
   * Подставлять ли mock-данные
   */
  mocks: true,
  /**
   * URLы справки для обоих языков
   */
  helpURLs: {
    ru: '/fastdraws/ru/page-info',
    uk: '/fastdraws/uk/page-info'
  },
  /**
   * Соль для подписи запросов к сайту
   */
  salt: 'y_3ts@fyfib4.acajnarcepsyeuzp0J!',
  /**
   * Открыто ли веб-приложение через мобильное приложение
   */
  isMobile: false,
  /**
   * Даты старта каждой лотереи
   */
  startDates: {
    30: 'Wed Jun 01 2022 15:30:00 GMT+0300 (Восточная Европа, летнее время)'
  },
  /**
   * Ссылка на пополнение баланса
   */
  fillBalanceURL: 'https://dev.megalot.emict.net/account/deposit/new',
  /**
   * Ссылка на кабинет игрока
   */
  cabinetURL: 'https://dev.megalot.emict.net/account/profile/common',
  /**
   * Ссылка на архив ставок
   */
  betsArchive: 'https://dev.megalot.emict.net/account/bets#fastdraws',
  /**
   * Перечень внешних лотерей из верхнего меню
   */
  externalLotteries: {
    52: '/kare/'
  },
  /**
   * Временное смещение для киевской тайм-зоны
   */
  timezoneOffset: 2,
  /**
   * Ссылка на страницу проверки билетов
   */
  ticketsCheckLink: {
    url: 'https://win.msl.kiev.ua/',
    title: {
      ru: 'win.msl.kiev.ua',
      ua: 'win.msl.kiev.ua'
    }
  }
};
