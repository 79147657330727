import {
  ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators
} from '@angular/forms';
import { notEmptyArray } from '@app/features/pik4/validators/not-empty-array';
import { IBet } from '@app/core/interfaces/i-bet';
import { delay, takeUntil, tap } from 'rxjs/operators';
import {
  Subject, timer
} from 'rxjs';
import { ITicket } from '@app/core/interfaces/i-ticket';
import { Actions, ofType } from '@ngrx/effects';
import { betInRange } from '@app/features/pik4/validators/bet-in-range';
import { SiteService } from '@app/core/services/site.service';
import { ApiService } from '@app/core/services/api.service';
import { MobileService } from '@app/core/services/mobile.service';
import { IBetType } from '@app/core/interfaces/i-bet-type';
import { environment } from '../../../../../environments/environment';

/**
 * Интерфейс параметров стола, отправляемых из формы
 */
interface ITableFormParams {
  /**
   * Значение 1-го барабана
   */
  reel1?: number;
  /**
   * Значение 2-го барабана
   */
  reel2?: number;
  /**
   * Значение 3-го барабана
   */
  reel3?: number;
  /**
   * Значение 4-го барабана
   */
  reel4?: number;
  /**
   * Массив кодов выбранных ставок
   */
  bt_code?: Array<string>;
  /**
   * Ставка (в грн)
   */
  bet: number;
  /**
   * Количество тиражей, на которые ставим
   */
  drawsCount: number;
}

/**
 * Компонент стола в игре П4
 */
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {
  /**
   * Номер стола
   * @private
   */
  private nTable = 1;

  /**
   * Минимальная ставка (в грн)
   * @private
   */
  private nMinBet = 1;

  /**
   * Максимальная ставка (в грн)
   * @private
   */
  private nMaxBet = 2;

  /**
   * Максимальное количество тиражей
   * @private
   */
  private nMaxDraws = 1;

  /**
   * Параметры формы со стола
   * @private
   */
  private tableFormParams: ITableFormParams = {
    reel1: 0,
    reel2: 0,
    reel3: 0,
    reel4: 0,
    bt_code: [],
    bet: this.nMinBet,
    drawsCount: 1
  };

  /**
   * Выполняется ли запрос регистрации ставки
   */
  isLoading = false;

  /**
   * Показывается ли анимация на кнопке Оплатить
   */
  isAnimating = false;

  /**
   * Раскрыт ли спойлер стола
   */
  isExpanded = true;

  /**
   * Геттер номера стола
   */
  get table(): number {
    return this.nTable;
  }

  /**
   * Сеттер номера стола
   * @param v Номер стола
   */
  @Input() set table(v: number) {
    this.nTable = v;
    const sParams = localStorage.getItem(`pick-4_table-${this.table}`);
    if (sParams) {
      this.tableFormParams = JSON.parse(sParams);
    } else {
      this.tableFormParams = {
        reel1: 0,
        reel2: 0,
        reel3: 0,
        reel4: 0,
        bt_code: [],
        bet: this.nMinBet,
        drawsCount: 1
      };
    }
    const sIsExpanded = localStorage.getItem(`pick-4_table-${this.table}_expanded`);
    if (sIsExpanded) {
      this.isExpanded = sIsExpanded === 'true';
    }
    this.gamePlayForm.addControl('gameParams', new UntypedFormControl({
      bet: this.tableFormParams.bet,
      drawsCount: this.tableFormParams.drawsCount
    }, betInRange(this.nMinBet, this.nMaxBet)));

    if (v === 1) {
      this.gamePlayForm.addControl('reel1', new UntypedFormControl(this.tableFormParams.reel1, Validators.compose([Validators.min(1), Validators.max(10)])));
      this.gamePlayForm.addControl('reel2', new UntypedFormControl(this.tableFormParams.reel2, Validators.compose([Validators.min(1), Validators.max(10)])));
      this.gamePlayForm.addControl('reel3', new UntypedFormControl(this.tableFormParams.reel3, Validators.compose([Validators.min(1), Validators.max(10)])));
      this.gamePlayForm.addControl('reel4', new UntypedFormControl(this.tableFormParams.reel4, Validators.compose([Validators.min(1), Validators.max(10)])));
    } else {
      this.gamePlayForm.addControl('bt_code', new UntypedFormControl(this.tableFormParams.bt_code, notEmptyArray()));
    }
    if (sParams) {
      this.gamePlayForm.markAsDirty();
    }
  }

  /**
   * Геттер минимальной ставки
   */
  get minBet(): number {
    return this.nMinBet;
  }

  /**
   * Сеттер минимальной ставки
   * @param v Минимальная ставка
   */
  @Input() set minBet(v: number) {
    this.nMinBet = v;
    this.gamePlayForm.controls.gameParams.setValidators([betInRange(this.nMinBet, this.nMaxBet)]);
    this.gamePlayForm.controls.gameParams.updateValueAndValidity();
    if (this.tableFormParams.bet < v) {
      this.tableFormParams.bet = v;
      this.updateForm();
    }
  }

  /**
   * Геттер максимальной ставки
   */
  get maxBet(): number {
    return this.nMaxBet;
  }

  /**
   * Сеттер максимальной ставки
   * @param v Максимальная ставка
   */
  @Input() set maxBet(v: number) {
    this.nMaxBet = v;
    this.gamePlayForm.controls.gameParams.setValidators([betInRange(this.nMinBet, this.nMaxBet)]);
    this.gamePlayForm.controls.gameParams.updateValueAndValidity();
    if (this.tableFormParams.bet > v) {
      this.tableFormParams.bet = v;
      this.updateForm();
    }
  }

  get colorsOfVictoryOdd(): number {
    let oddsSum = 0;
    const btIndex = this.betTypes
      .findIndex((bt) => (bt.bt_code === '2B2Y') && (bt.table === 4));
    if (btIndex > -1) {
      oddsSum += parseFloat(this.betTypes[btIndex].bt_coef);
    }
    return oddsSum;
  }

  /**
   * Геттер максимально возможного количества тиражей
   */
  get maxDraws(): number {
    return this.nMaxDraws;
  }

  /**
   * Сеттер максимально возможного количества тиражей
   * @param v Количество тиражей
   */
  @Input() set maxDraws(v: number) {
    this.nMaxDraws = v;
    if (this.tableFormParams.drawsCount > v && v > 0) {
      this.tableFormParams.drawsCount = v;
      this.updateForm();
    }
  }

  /**
   * Заголовок стола
   */
  @Input() title = 'pik4.numbers';

  /**
   * Подсказка игроку на столе
   */
  @Input() hint = 'pik4.select-numbers';

  /**
   * Алиас страницы справки на сайте для получения текста справки
   */
  @Input() helpAlias = 'pick4_number_of_colors';

  /**
   * Форма с параметрами для регистрации ставки
   */
  gamePlayForm: UntypedFormGroup;

  /**
   * Ссылка на DOM-элемент анимированной капли
   */
  @ViewChild('drop') drop: ElementRef<HTMLDivElement> | undefined;

  /**
   * Наблюдаемая переменная, содержащая возможные типы ставок
   */
  @Input() betTypes: Array<IBetType> = [];

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   * @param regBet$ Наблюдаемая переменная для действия по регистрации ставки
   * @param formBuilder Построитель форм
   * @param cdr Детектор обнаружения изменений
   * @param apiService API-сервис
   */
  constructor(
    private readonly store: Store,
    private readonly regBet$: Actions,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly apiService: ApiService
  ) {
    this.gamePlayForm = this.formBuilder.group({});
    regBet$.pipe(
      ofType('[Bet Registration] Reg Bet Success'),
      takeUntil(this.destroy$)
    ).subscribe(this.onRegBetSuccess.bind(this));
    regBet$.pipe(
      ofType('[Bet Registration] Reg Bet Error'),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.isLoading = false;
      this.isAnimating = false;
    });
  }

  /**
   * Геттер для суммы ставки
   */
  get gameSum(): number {
    const betsCount = this.table === 1 ? 1 : this.gamePlayForm.controls.bt_code.value.length;
    const gameParams = this.gamePlayForm.controls.gameParams.value;
    return betsCount * gameParams.bet * gameParams.drawsCount;
  }

  /**
   * Обработчик показа диалогового окна справки
   * @param alias Алиас страницы справки на сайте для получения текста справки
   */
  onShowHelpDialog(alias: string): void {
    this.store.dispatch({ type: '[Help Dialog] Open help dialog', alias });
  }

  /**
   * Обработчик нажатия кнопки авто-выбора чисел на первом столе
   */
  onAutoClick(): void {
    this.tableFormParams.reel1 = Math.floor(Math.random() * 10) + 1;
    this.tableFormParams.reel2 = Math.floor(Math.random() * 10) + 1;
    this.tableFormParams.reel3 = Math.floor(Math.random() * 10) + 1;
    this.tableFormParams.reel4 = Math.floor(Math.random() * 10) + 1;
    this.updateForm();
  }

  /**
   * Обработчик регистрации ставки
   */
  onGamePlayHandler(): void {
    if (this.apiService.token) {
      if (!this.isAnimating) {
        this.isLoading = true;
        this.isAnimating = true;
        const bet: IBet = {
          table: this.table,
          sum: this.gamePlayForm.controls.gameParams.value.bet,
          bt_code: this.table === 1 ? '1' : this.gamePlayForm.controls.bt_code.value,
          draws: this.gamePlayForm.controls.gameParams.value.drawsCount
        };
        if (this.table === 1) {
          bet.comb = [
            this.gamePlayForm.controls.reel1.value,
            this.gamePlayForm.controls.reel2.value,
            this.gamePlayForm.controls.reel3.value,
            this.gamePlayForm.controls.reel4.value
          ];
        }
        this.store.dispatch({ type: '[Bet Registration] Reg Bet', lottCode: 30, bet });
      }
    } else if (environment.isMobile) {
      MobileService.refreshAuthToken();
    } else {
      SiteService.showLoginDialog();
    }
  }

  /**
   * Обработчик изменения состояния спойлера стола
   * @param expanded Состояние спойлера (true - развернут, false - свернут)
   */
  onSpoilerChangeState(expanded: boolean): void {
    this.isExpanded = expanded;
    localStorage.setItem(`pick-4_table-${this.table}_expanded`, expanded ? 'true' : 'false');
  }

  /**
   * Метод для очистки формы
   */
  resetForm(): void {
    this.tableFormParams = {
      reel1: 0,
      reel2: 0,
      reel3: 0,
      reel4: 0,
      bt_code: [],
      bet: this.nMinBet,
      drawsCount: 1
    };
    this.updateForm(true);
  }

  /**
   * Метод для обновления формы
   * @param reset Очистить ли форму?
   * @private
   */
  private updateForm(reset = false): void {
    if (this.table === 1) {
      this.gamePlayForm.patchValue({
        reel1: this.tableFormParams.reel1,
        reel2: this.tableFormParams.reel2,
        reel3: this.tableFormParams.reel3,
        reel4: this.tableFormParams.reel4
      }, { emitEvent: false });
    } else {
      this.gamePlayForm.patchValue({
        bt_code: this.tableFormParams.bt_code
      }, { emitEvent: false });
    }
    this.gamePlayForm.patchValue({
      gameParams: {
        bet: this.tableFormParams.bet,
        drawsCount: this.tableFormParams.drawsCount
      }
    }, { emitEvent: false });
    if (reset) {
      localStorage.removeItem(`pick-4_table-${this.nTable}`);
    } else {
      localStorage.setItem(`pick-4_table-${this.nTable}`, JSON.stringify(this.tableFormParams));
    }
    this.cdr.detectChanges();
  }

  /**
   * Обработчик успешной регистрации ставки
   * @param v Ответ, содержащий зарегистрированные ставки и тип действия
   * @private
   */
  private onRegBetSuccess(v: {tickets: Array<ITicket>, type: string}): void {
    if (
      v.tickets?.length
      && v.tickets[0].bet?.length
      && (v.tickets[0].bet[0].table === this.nTable
        || (v.tickets[0].bet[0].table === 4 && this.nTable === 2))
    ) {
      this.isLoading = false;
      this.drop?.nativeElement.classList.remove('drop_1');
      this.drop?.nativeElement.classList.add('drop_2');
      timer(600)
        .pipe(
          tap(() => {
            if (this.drop) {
              // this.drop.nativeElement.style.top = 'calc(100% - 15px)';
              this.drop.nativeElement.classList.add('drop_3');
            }
          }),
          delay(600),
          tap(() => {
            if (this.drop) {
              const dropDOMRect = this.drop.nativeElement.getBoundingClientRect();
              const plusDOMRect = document.getElementsByClassName('menu-button_main')[0].getBoundingClientRect();
              const tableBody = this.drop.nativeElement.closest('.table-body') as HTMLDivElement;
              tableBody.style.overflow = 'visible';
              const tablesBlock = this.drop.nativeElement.closest('.tables-block') as HTMLElement;
              tablesBlock.style.overflow = 'visible';

              this.drop.nativeElement.style.transition = 'left 0.3s linear 0s, top 0.3s linear 0s';
              this.drop.nativeElement.style.top = `${this.drop.nativeElement.offsetTop + plusDOMRect.top + plusDOMRect.height / 2 - dropDOMRect.top}px`;
              this.drop.nativeElement.style.left = `${this.drop.nativeElement.offsetLeft + plusDOMRect.left + plusDOMRect.width / 2 - dropDOMRect.left}px`;
            }
          }),
          delay(600),
          tap(() => {
            if (this.drop) {
              this.drop.nativeElement.style.display = 'none';
              this.drop.nativeElement.classList.remove('drop_2', 'drop_3');
              const tableBody = this.drop.nativeElement.closest('.table-body') as HTMLDivElement;
              tableBody.style.overflow = 'hidden';
              const tablesBlock = this.drop.nativeElement.closest('.tables-block') as HTMLElement;
              tablesBlock.style.overflow = 'hidden';
              this.drop.nativeElement.style.transition = '';
              this.drop.nativeElement.style.top = '';
              this.drop.nativeElement.style.left = '';
            }
          }),
          delay(600)
        )
        .subscribe(() => {
          if (this.drop) {
            this.drop.nativeElement.style.display = '';
          }
          this.resetForm();
          SiteService.updateBalance();
          this.isAnimating = false;
        });
    }
  }

  /**
   * Обработчик изменения значения формы
   * @private
   */
  private onFormValueChange(): void {
    if (this.nTable === 1) {
      this.tableFormParams.reel1 = this.gamePlayForm.controls.reel1.value;
      this.tableFormParams.reel2 = this.gamePlayForm.controls.reel2.value;
      this.tableFormParams.reel3 = this.gamePlayForm.controls.reel3.value;
      this.tableFormParams.reel4 = this.gamePlayForm.controls.reel4.value;
    } else {
      this.tableFormParams.bt_code = this.gamePlayForm.controls.bt_code.value;
    }
    this.tableFormParams.bet = this.gamePlayForm.controls.gameParams.value.bet;
    this.tableFormParams.drawsCount = this.gamePlayForm.controls.gameParams.value.drawsCount;
    localStorage.setItem(`pick-4_table-${this.table}`, JSON.stringify(this.tableFormParams));
    this.cdr.detectChanges();
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    this.gamePlayForm.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(this.onFormValueChange.bind(this));
  }

  /**
   * Обработчик события уничтожения компонента
   */
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
