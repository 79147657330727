import {
  Component, Input
} from '@angular/core';
import { IBetItem } from '@app/features/pik4/interfaces/i-bet-item';

/**
 * Компонент ставок в блоке активных ставок
 */
@Component({
  selector: 'app-bet-item',
  templateUrl: './bet-item.component.html',
  styleUrls: ['./bet-item.component.scss']
})
export class BetItemComponent {
  /**
   * Ставка
   */
  @Input() bet: IBetItem | undefined = undefined;

  /**
   * Состояние спойлера с тиражами в многотиражной ставке: false - закрыт, true - открыт
   */
  spoilerState = false;

  /**
   * Названия столов, на которых была сделана ставка
   */
  tableNames = ['pik4.numbers', 'pik4.color-and-count', 'pik4.color-and-position', 'pik4.colors-of-victory'];

  /**
   * Случайное число для уникальности ID DOM-элементов
   */
  uid = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
}
