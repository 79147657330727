import { AbstractControl, ValidatorFn } from '@angular/forms';

/**
 * Валидатор принадлежности ставки заданному диапазону
 * @param minBet Минимальная ставка
 * @param maxBet Максимальная ставка
 */
export function betInRange(minBet: number, maxBet: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => (
    (control.value.bet >= minBet) && (control.value.bet <= maxBet)
      ? null : { betNotInRange: control.value.bet }
  );
}
