import {
  Directive, ElementRef, Input, OnInit, Renderer2
} from '@angular/core';
import { inIframe, parentScrollTop } from '@app/core/utils';

/**
 * Директива фиксации модальных окон во фрейме
 * на одинаковой позиции от краев родительского окна
 */
@Directive({
  selector: '[appFixedInFrame]'
})
export class FixedInFrameDirective implements OnInit {
  /**
   * Ссылка на объект фрейма, в котором открыто веб-приложение
   * @private
   */
  private fastFrame: HTMLElement | null;

  /**
   * Отступ от верха родительского окна
   */
  @Input() top = '';

  /**
   * Отступ от низа родительского окна
   */
  @Input() bottom = '';

  /**
   * Применять ли директиву в десктоп-разрешении?
   */
  @Input() applyOnDesktop = true;

  /**
   * Конструктор директивы
   * @param elementRef Ссылка на элемент, к которому применяется директива
   * @param renderer2 Объект специального класса для работы с DOM в Angular
   */
  constructor(private readonly elementRef: ElementRef, private readonly renderer2: Renderer2) {
    this.fastFrame = null;
  }

  /**
   * Метод для обновления позиции элемента, к которому применяется директива
   * @private
   */
  private placeSelf(): void {
    if ((window.innerWidth < 1200) || (this.applyOnDesktop)) {
      const pxToTop = this.bottom ? window.parent.innerHeight - parseInt(this.bottom, 10)
        - this.elementRef.nativeElement.offsetHeight : parseInt(this.top, 10);
      let topOfTopEdge = parentScrollTop() - (this.fastFrame?.offsetTop || 0)
        + pxToTop;
      if (topOfTopEdge < 0) {
        topOfTopEdge = 0;
      }
      if (topOfTopEdge + this.elementRef.nativeElement.offsetHeight > window.innerHeight) {
        topOfTopEdge = window.innerHeight - this.elementRef.nativeElement.offsetHeight;
      }
      this.renderer2.setStyle(this.elementRef.nativeElement, 'top', `${topOfTopEdge}px`);
    } else {
      this.renderer2.setStyle(this.elementRef.nativeElement, 'top', '');
      this.renderer2.setStyle(this.elementRef.nativeElement, 'position', '');
    }
  }

  /**
   * Инициализировать слушатели событий, при которых обновляется позиция элемента директивы
   * @private
   */
  private initListeners(): void {
    this.renderer2.setStyle(this.elementRef.nativeElement, 'position', 'absolute');
    this.placeSelf();
    window.parent.addEventListener('scroll', this.placeSelf.bind(this));
    window.parent.addEventListener('resize', this.placeSelf.bind(this));
    window.addEventListener('resize', this.placeSelf.bind(this));
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    this.fastFrame = window.parent.document.getElementById('fast-frame');
    const allStyles = window.getComputedStyle(this.elementRef.nativeElement);
    if (inIframe() && this.fastFrame && allStyles.position === 'fixed') {
      this.initListeners();
    }
  }
}
