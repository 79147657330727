import { Injectable } from '@angular/core';
import {
  HttpClient, HttpContext, HttpHeaders, HttpParams
} from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Сервис-обертка для работы с http-запросами
 * TODO: Добавить mock-http-клиент
 */
@Injectable({
  providedIn: 'root'
})
export class HttpService {
  /**
   * Конструктор сервиса
   * @param httpClient Объект модуля для работы с http-запросами
   */
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(private readonly httpClient: HttpClient) { }

  /**
   * Constructs a `POST` request that interprets the body as a JSON object
   * and returns an observable of the response.
   *
   * @param url The endpoint URL.
   * @param body The content to replace with.
   * @param options HTTP options
   *
   * @return  An `Observable` of the `HttpResponse` for the request, with a response body in the
   * requested type.
   */
  post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.httpClient.post(url, body, options) as Observable<T>;
  }

  /**
   * Constructs a `GET` request that interprets the body as a JSON object and returns
   * the response body in a given type.
   *
   * @param url     The endpoint URL.
   * @param options The HTTP options to send with the request.
   *
   * @return An `Observable` of the `HttpResponse`, with a response body in the requested type.
   */
  get<T>(url: string, options?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
    context?: HttpContext;
    observe?: 'body';
    params?: HttpParams | {
      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.httpClient.get(url, options) as Observable<T>;
  }
}
