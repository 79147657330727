import {
  Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import { ISortingMenu } from '@app/features/pik4/interfaces/i-sorting-menu';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { IStats } from '@app/core/interfaces/i-stats';
import { selectStats } from '@app/store/selectors/stats.selectors';
import { takeUntil } from 'rxjs/operators';
import { APP_CONFIG } from '@app/core/utils';
import { IConfig } from '@app/core/interfaces/i-config';
import { TranslateService } from '@ngx-translate/core';

/**
 * Интерфейс элемента статистики для вывода в шаблон
 */
interface IStatsOutputItem {
  /**
   * Код комбинации
   */
  itemCode: string;
  /**
   * Количество выпадений каждого из чисел от начала запуска лотереи
   */
  s: number;
  /**
   * Количество розыгрышей, когда число не выпадало после последнего своего появления в тираже
   */
  l: number;
  /**
   * Значение высоты или ширины в % столбцов для параметра s
   */
  sp: number;
  /**
   * Значение высоты или ширины в % столбцов для параметра l
   */
  lp: number;
  /**
   * Затемнены ли столбцы, не участвующие в фильтре?
   */
  grayed: boolean;
}

/**
 * Интерфейс для статистики или ее части
 */
interface IStatsSlice {
  /**
   * Первый стол
   */
  1: Array<IStatsOutputItem>;
  /**
   * Второй стол
   */
  2: Array<IStatsOutputItem>;
  /**
   * Третий стол
   */
  3: Array<IStatsOutputItem>;
}

/**
 * Интерфейс статистики за период
 */
interface IPeriodicStats {
  /**
   * Период
   * 1 - Статистика за все время с начала старта лотереи
   * 2 - Статистика с начала текущего месяца
   * 3 - Статистика с начала текущей недели
   * 4 - Статистика за вчерашний день
   * 5 - Статистика за сегодняшний день
   */
  [t: number]: IStats | undefined;
}

/**
 * Компонент статистики
 */
@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatsComponent implements OnInit, OnDestroy {
  /**
   * Переменная, выводящая в событие клик по кнопке "Назад"
   */
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Элементы для меню сортировки статистики по второму столу
   */
  sortingItems2: Array<ISortingMenu> = [{
    title: 'stats.comb',
    ordering: [1, 2, 3]
  }, {
    title: 'stats.number-of-falls',
    ordering: [2, 1, 3]
  }, {
    title: 'stats.no-falls',
    ordering: [3, 1, 2]
  }];

  /**
   * Элементы для меню сортировки статистики по третьему столу
   */
  sortingItems3: Array<ISortingMenu> = [{
    title: 'stats.pos',
    ordering: [1, 2, 3]
  }, {
    title: 'stats.number-of-falls',
    ordering: [2, 1, 3]
  }, {
    title: 'stats.no-falls',
    ordering: [3, 1, 2]
  }];

  /**
   * Элементы для меню сортировки статистики по первому столу
   */
  sortingItems1: Array<ISortingMenu> = [{
    title: 'stats.ball-num',
    ordering: [1, 2, 3]
  }, {
    title: 'stats.number-of-falls',
    ordering: [2, 1, 3]
  }, {
    title: 'stats.no-falls',
    ordering: [3, 1, 2]
  }];

  /**
   * Выбранный тип сортировки для первого стола
   */
  sortingItem1 = { ...this.sortingItems1[0] };

  /**
   * Выбранный тип сортировки для второго стола
   */
  sortingItem2 = { ...this.sortingItems2[0] };

  /**
   * Выбранный тип сортировки для третьего стола
   */
  sortingItem3 = { ...this.sortingItems3[0] };

  /**
   * Стол, для которого показать статистику в мобильной версии
   */
  table = 2;

  /**
   * Открыта ли страница статистики в мобильном разрешении?
   */
  isMobile = window.innerWidth < 768;

  /**
   * Наблюдаемая переменная со статистикой за период
   */
  stats$: Observable<IPeriodicStats>;

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Переменная со статистикой за период
   */
  stats: IPeriodicStats = {};

  /**
   * Срез статистики
   */
  statsSlice: IStatsSlice = { 1: [], 2: [], 3: [] };

  /**
   * Специальный элемент статистики для вывода в шаблон (патриотическая ставка)
   */
  specialItem: IStatsOutputItem = {
    itemCode: 'B2Y2',
    s: 0,
    l: 0,
    sp: 0,
    lp: 0,
    grayed: false
  };

  /**
   * Фильтр для 1-го стола
   * 0 - вся статистика
   * 1, 2, 3, 4 - фильтры для метрики по каждому столу
   */
  filters1 = 0;

  /**
   * Фильтр для 2-го стола
   * 0 - вся статистика
   * 1, 2, 3, 4 - фильтры для метрики по каждому столу
   */
  filters2 = 0;

  /**
   * Фильтр для 3-го стола
   * 0 - вся статистика
   * 1, 2, 3, 4 - фильтры для метрики по каждому столу
   */
  filters3 = 0;

  /**
   * Цвета шаров для статистики по 1-му столу
   */
  ballsColors = ['r', 'b', 'b', 'y', 'y', 'y', 'g', 'g', 'g', 'g'];

  /**
   * Период статистики
   */
  period = 5;

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   * @param config Объект конфигурации приложения
   * @param translateService Сервис переводов
   */
  constructor(
              private readonly store: Store,
              @Inject(APP_CONFIG) readonly config: IConfig,
              readonly translateService: TranslateService
  ) {
    this.stats$ = store.select(selectStats);
  }

  /**
   * Обработчик изменения периода статистики
   * @param t Период статистики
   * 1 - Статистика за все время с начала старта лотереи
   * 2 - Статистика с начала текущего месяца
   * 3 - Статистика с начала текущей недели
   * 4 - Статистика за вчерашний день
   * 5 - Статистика за сегодняшний день
   */
  changePeriod(t: number): void {
    if (this.stats[t] && (t !== 5)) {
      this.filterStats();
    } else {
      this.store.dispatch({ type: '[Stats] Get Stats', lottCode: 30, t });
    }
  }

  /**
   * Обработчик фильтрации статистики (по соответствующим метрикам для каждого стола)
   */
  filterStats(): void {
    if (this.stats) {
      const statsPeriod = this.stats[this.period] || null;
      if (statsPeriod) {
        this.statsSlice = { 1: [], 2: [], 3: [] };

        const t2Keys = Object.keys(statsPeriod[2]).filter((v) => v.length === 2);
        for (let i = 0; i < t2Keys.length; i += 1) {
          this.statsSlice[2].push({
            itemCode: `2${t2Keys[i]}`,
            l: statsPeriod[2][t2Keys[i]].l,
            s: statsPeriod[2][t2Keys[i]].s,
            lp: 0,
            sp: 0,
            grayed: !!this.filters2 && +t2Keys[i].substring(1) !== this.filters2
          });
        }

        const sMax2 = Math.max(...this.statsSlice[2].map((v) => v.s));
        const lMax2 = Math.max(...this.statsSlice[2].map((v) => v.l));

        this.statsSlice[2] = this.statsSlice[2].map((v) => ({
          ...v,
          sp: (v.s / sMax2) * 115,
          lp: (v.l / lMax2) * 115
        }));

        const specMax = Math.max(statsPeriod[2].B2Y2?.s, statsPeriod[2].B2Y2?.l) || 1;
        this.specialItem = {
          itemCode: 'B2Y2',
          s: statsPeriod[2].B2Y2?.s,
          l: statsPeriod[2].B2Y2?.l,
          sp: ((statsPeriod[2].B2Y2?.s || 0) / specMax) * 163,
          lp: ((statsPeriod[2].B2Y2?.l || 0) / specMax) * 163,
          grayed: false
        };

        // Сортировки для 2-го стола
        if (this.sortingItem2.title === 'stats.comb') {
          this.statsSlice[2].sort((a: IStatsOutputItem, b: IStatsOutputItem) => {
            const colorA = a.itemCode.substring(1, 2).toLowerCase();
            const colorB = b.itemCode.substring(1, 2).toLowerCase();
            if (colorA === colorB) {
              const metricA = +a.itemCode.substring(2);
              const metricB = +b.itemCode.substring(2);
              return metricB - metricA;
            }
            return this.ballsColors.indexOf(colorA) - this.ballsColors.indexOf(colorB);
          });
        } else if (this.sortingItem2.title === 'stats.number-of-falls') {
          this.statsSlice[2].sort((a: IStatsOutputItem, b: IStatsOutputItem) => b.s - a.s);
        } else if (this.sortingItem2.title === 'stats.no-falls') {
          this.statsSlice[2].sort((a: IStatsOutputItem, b: IStatsOutputItem) => b.l - a.l);
        }

        const t3Keys = Object.keys(statsPeriod[3]).filter((v) => v.length === 2);
        for (let i = 0; i < t3Keys.length; i += 1) {
          this.statsSlice[3].push({
            itemCode: `3${t3Keys[i]}`,
            l: statsPeriod[3][t3Keys[i]].l,
            s: statsPeriod[3][t3Keys[i]].s,
            lp: 0,
            sp: 0,
            grayed: !!this.filters3 && +t3Keys[i].substring(1) !== this.filters3
          });
        }

        const sMax3 = Math.max(...this.statsSlice[3].map((v) => v.s));
        const lMax3 = Math.max(...this.statsSlice[3].map((v) => v.l));

        this.statsSlice[3] = this.statsSlice[3].map((v) => ({
          ...v,
          sp: (v.s / sMax3) * 115,
          lp: (v.l / lMax3) * 115
        }));

        const t1Keys = Object.keys(statsPeriod[1]);
        for (let i = 0; i < t1Keys.length; i += 1) {
          this.statsSlice[1].push({
            itemCode: t1Keys[i],
            l: this.filters1 ? statsPeriod[1][+t1Keys[i]].l[this.filters1 - 1]
              : Math.min(...statsPeriod[1][+t1Keys[i]].l),
            s: this.filters1 ? statsPeriod[1][+t1Keys[i]].s[this.filters1 - 1]
              : statsPeriod[1][+t1Keys[i]].s[0] + statsPeriod[1][+t1Keys[i]].s[1]
              + statsPeriod[1][+t1Keys[i]].s[2] + statsPeriod[1][+t1Keys[i]].s[3],
            lp: 0,
            sp: 0,
            grayed: false
          });
        }

        // Сортировки для 3-го стола
        if (this.sortingItem3.title === 'stats.pos') {
          this.statsSlice[3].sort((a: IStatsOutputItem, b: IStatsOutputItem) => {
            const colorA = a.itemCode.substring(1, 2).toLowerCase();
            const colorB = b.itemCode.substring(1, 2).toLowerCase();
            if (colorA === colorB) {
              const metricA = +a.itemCode.substring(2);
              const metricB = +b.itemCode.substring(2);
              return metricA - metricB;
            }
            return this.ballsColors.indexOf(colorA) - this.ballsColors.indexOf(colorB);
          });
        } else if (this.sortingItem3.title === 'stats.number-of-falls') {
          this.statsSlice[3].sort((a: IStatsOutputItem, b: IStatsOutputItem) => b.s - a.s);
        } else if (this.sortingItem3.title === 'stats.no-falls') {
          this.statsSlice[3].sort((a: IStatsOutputItem, b: IStatsOutputItem) => b.l - a.l);
        }

        const sMax1 = Math.max(...this.statsSlice[1].map((v) => v.s));
        const lMax1 = Math.max(...this.statsSlice[1].map((v) => v.l));

        this.statsSlice[1] = this.statsSlice[1].map((v) => ({
          ...v,
          sp: (v.s / sMax1) * 115,
          lp: (v.l / lMax1) * 115
        }));

        // Сортировки для первого стола
        if (this.sortingItem1.title === 'stats.ball-num') {
          this.statsSlice[1].sort(
            (a: IStatsOutputItem, b: IStatsOutputItem) => +a.itemCode - +b.itemCode
          );
        } else if (this.sortingItem1.title === 'stats.number-of-falls') {
          this.statsSlice[1].sort((a: IStatsOutputItem, b: IStatsOutputItem) => b.s - a.s);
        } else if (this.sortingItem1.title === 'stats.no-falls') {
          this.statsSlice[1].sort((a: IStatsOutputItem, b: IStatsOutputItem) => b.l - a.l);
        }
      }
    }
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    window.onresize = () => {
      this.isMobile = window.innerWidth < 768;
    };
    this.stats$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((stats: { [t: number]: IStats | undefined }) => {
      this.stats = stats;
      this.filterStats();
    });
    this.changePeriod(this.period);
  }

  /**
   * Обработчик события уничтожения компонента
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
