<swiper
        [slidesPerView]="5"
        [spaceBetween]="8"
        [direction]="'vertical'"
        [centeredSlides]="true"
        [loop]="true"
        [loopAdditionalSlides]="10"
        (swiper)="onSwiper($event)"
        (slideChange)="onSlideChange()"
        (click)="onSlideClick($event)"
>
  <ng-template swiperSlide>1</ng-template>
  <ng-template swiperSlide>2</ng-template>
  <ng-template swiperSlide>3</ng-template>
  <ng-template swiperSlide>4</ng-template>
  <ng-template swiperSlide>5</ng-template>
  <ng-template swiperSlide>6</ng-template>
  <ng-template swiperSlide>7</ng-template>
  <ng-template swiperSlide>8</ng-template>
  <ng-template swiperSlide>9</ng-template>
  <ng-template swiperSlide>10</ng-template>
</swiper>
