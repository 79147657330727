/**
 * Список экшенов, доступных для общения с мобильным телефоном.
 *
 * - {@link GetAuthToken} - Получить token, необходимый для работы приложения в рамках webView.
 * Возвращает значение текущего token сохраненного в мобильном приложении, если токена нет, буден возвращен null
 * (обрабатывается методом android.getData(action: string): string).
 *
 * - {@link RefreshAuthToken} - Обновить token. Данный экшен используется для получения (обновления) токена из ЦС.
 * Возвращаемых параметров нет, в связи с тем, что получение токена будет происходить в мобильном приложении асинхронно
 * с открытием активити содержащей форму ввода логина и пароля и взаимодействия с пользователем,
 * что подразумевает остановку работы webView и его уничтожение. После удачного получения токена webView, будет перезапущено.
 * обрабатывается методом  "android.doAction(action: string, data: string)"
 *
 * @see https://confluence.emict.net/pages/viewpage.action?pageId=209846332
 */
export enum MobileAction {
	GetAuthToken        = 'get_auth_token',
	RefreshAuthToken    = 'refresh_auth_token',
	ChangeLocation      = 'change_location'
}
