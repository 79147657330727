import {
  ChangeDetectorRef,
  Component, forwardRef, Input, OnDestroy, OnInit
} from '@angular/core';
import { ICarouselBet } from '@app/features/pik4/interfaces/i-carousel-bet';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IBetType } from '@app/core/interfaces/i-bet-type';
import { Store } from '@ngrx/store';
import { selectBetTypesNot1 } from '@app/store/selectors/bet-types.selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Метрика для столов 2 и 3.
 * Для стола 2 - это количество шаров
 * Для стола 3 - номер позиции шара
 */
interface Metric {
  /**
   * Индекс - номер стола
   */
  [table: number]: Array<number>;
}

/**
 * Интерфейс для данных по ставке
 */
interface IBetsData {
  /**
   * Цвет шаров
   */
  [color: string]: {
    /**
     * Метрика
     */
    [metric: string]: {
      /**
       * Коэффициент ставки
       */
      bt_coef: number;
      /**
       * Выбрана ли ставка
       */
      checked: boolean;
    }
  }
}

/**
 * Компонент для вывода вариантов ставок на столах 2 и 3
 */
@Component({
  selector: 'app-balls-colors',
  templateUrl: './balls-colors.component.html',
  styleUrls: ['./balls-colors.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => BallsColorsComponent),
      multi: true
    }
  ]
})
export class BallsColorsComponent implements ControlValueAccessor, OnInit, OnDestroy {
  /**
   * Номер стола
   */
  @Input() table: keyof Metric = 2;

  @Input() victoryOdd = 31;

  /**
   * Наблюдаемая переменная с массивом типов ставок
   * @private
   */
  private betTypes$: Observable<Array<IBetType>>;

  /**
   * Текущее значение компонента (массив выбранных ставок)
   * @private
   */
  private val: Array<string> = [];

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   * @private
   */
  private destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Возможность выбора патриотической ставки (цвета победы)
   */
  colorsOfVictory = false;

  /**
   * Метрики для 2-го и 3-го стола
   */
  metric: Metric = {
    2: [4, 3, 2, 1],
    3: [1, 2, 3, 4]
  };

  /**
   * Данные по ставкам
   */
  betsData: IBetsData = {
    R: {
      1: { checked: false, bt_coef: 0 },
      2: { checked: false, bt_coef: 0 },
      3: { checked: false, bt_coef: 0 },
      4: { checked: false, bt_coef: 0 }
    },
    B: {
      1: { checked: false, bt_coef: 0 },
      2: { checked: false, bt_coef: 0 },
      3: { checked: false, bt_coef: 0 },
      4: { checked: false, bt_coef: 0 }
    },
    Y: {
      1: { checked: false, bt_coef: 0 },
      2: { checked: false, bt_coef: 0 },
      3: { checked: false, bt_coef: 0 },
      4: { checked: false, bt_coef: 0 }
    },
    G: {
      1: { checked: false, bt_coef: 0 },
      2: { checked: false, bt_coef: 0 },
      3: { checked: false, bt_coef: 0 },
      4: { checked: false, bt_coef: 0 }
    },
    B2Y2: {
      2: { checked: false, bt_coef: this.victoryOdd }
    }
  };

  /**
   * Массив ставок в карусели
   */
  tableCarousel: Array<ICarouselBet> = [];

  /**
   * Унаследованное событие от базового класса.
   * Вызывается в коллбеке при изменении значения UI-элемента
   * @param v Передаваемое значение
   */
  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  onChange: any = (v: Array<string>) => {};

  /**
   * Унаследованное событие от базового класса
   */
  // eslint-disable-next-line class-methods-use-this
  onTouched: any = () => {};

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   * @param cdr Ссылка на детектор изменений
   */
  constructor(private readonly store: Store, private readonly cdr: ChangeDetectorRef) {
    this.betTypes$ = this.store.select(selectBetTypesNot1);
  }

  /**
   * Метод для программного присвоения значения компоненту
   * @param value Значение
   */
  writeValue(value: Array<string>) {
    this.val = value || [];
    this.tableCarousel = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const c of ['R', 'B', 'Y', 'G']) {
      if (!this.betsData[c]) {
        this.betsData[c] = {};
      }
      for (let i = 1; i <= 4; i += 1) {
        if (this.betsData[c][i.toString()]) {
          this.betsData[c][i.toString()].checked = false;
        }
      }
    }
    this.betsData.B2Y2[2].checked = false;
    this.updateTableCarousel();
  }

  /**
   * Метод, который вызывается при изменении значения UI-элемента
   * @param fn Передаваемая callback-функция
   */
  registerOnChange(fn: (v: Array<string>) => void): void {
    this.onChange = fn;
  }

  /**
   * Метод, который вызывается при касании к UI-элементу
   * @param fn Передаваемая callback-функция
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Обработчик изменения патриотической ставки (4-й стол)
   */
  onPatrioticBetChange(): void {
    if (this.colorsOfVictory) {
      this.tableCarousel.push({
        color: 'b2y2',
        count: 2,
        position: 0,
        value: this.victoryOdd.toString()
      });
    } else {
      const unselectedIndex = this.tableCarousel
        .findIndex((v: ICarouselBet) => v.color === 'b2y2');
      if (Number.isInteger(unselectedIndex)) {
        this.tableCarousel.splice(unselectedIndex, 1);
      }
    }
    this.tableCarousel = this.tableCarousel.concat([]);
    this.betsData.B2Y2 = {
      2: {
        checked: this.colorsOfVictory,
        bt_coef: this.victoryOdd
      }
    };
    this.val = this.tableCarousel.map((v: ICarouselBet) => (v.color === 'b2y2'
      ? '2B2Y' : `${this.table}${v.color.toUpperCase()}${this.table === 2 ? v.count : v.position}`));
    this.onChange(this.val);
  }

  /**
   * Обработчик изменения ставки на 2-м или 3-м столе
   */
  onTableBetChange(name: string, metric: string, value: number, selected: boolean): void {
    const color = name.toLowerCase();
    if (selected) {
      this.tableCarousel.push({
        color,
        count: this.table === 2 ? +metric : 4,
        position: this.table === 3 ? +metric : 0,
        value: value.toString()
      });
    } else {
      const unselectedIndex = this.tableCarousel
        .findIndex((v: ICarouselBet) => (v.color.toLowerCase() === color)
          && (this.table === 2 ? v.count === +metric : v.position === +metric));
      if (Number.isInteger(unselectedIndex) && unselectedIndex > -1) {
        this.tableCarousel.splice(unselectedIndex, 1);
      }
    }
    this.tableCarousel = this.tableCarousel.concat([]);
    this.betsData[name][metric].checked = selected;
    this.val = this.tableCarousel.map((v: ICarouselBet) => (v.color === 'b2y2'
      ? '2B2Y' : `${this.table}${v.color.toUpperCase()}${this.table === 2 ? v.count : v.position}`));
    this.onChange(this.val);
  }

  /**
   * Метод для обновления карусели ставок
   * @private
   */
  private updateTableCarousel(): void {
    this.tableCarousel = [];
    this.colorsOfVictory = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const v of this.val) {
      if (v.toUpperCase() === '2B2Y') {
        this.betsData.B2Y2 = {
          2: {
            checked: true,
            bt_coef: this.victoryOdd
          }
        };
        this.tableCarousel = this.tableCarousel.concat({
          color: 'b2y2',
          count: 2,
          position: 0,
          value: this.victoryOdd.toString()
        });
        this.colorsOfVictory = true;
      } else {
        const color = v.substring(1, 2);
        const metric = v.substring(2, 3);
        this.betsData[color][metric].checked = true;
        this.tableCarousel = this.tableCarousel.concat({
          color,
          count: this.table === 2 ? +metric : 4,
          position: this.table === 3 ? +metric : 0,
          value: this.betsData[color][metric].bt_coef.toString()
        });
      }
    }
    this.cdr.detectChanges();
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    this.betTypes$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((value: Array<IBetType>) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const betType of value) {
          if ((betType.table === this.table) && (betType.bt_code.length === 3)) {
            const color = betType.bt_code.substring(1, 2);
            const metric = betType.bt_code.substring(2, 3);
            if (!this.betsData[color]) {
              this.betsData[color] = {};
            }
            if (this.betsData[color][metric]) {
              this.betsData[color][metric].bt_coef = +betType.bt_coef;
            } else {
              this.betsData[color][metric] = {
                checked: false,
                bt_coef: +betType.bt_coef
              };
            }
          }
          if (betType.table === 4) {
            this.victoryOdd = +betType.bt_coef;
          }
        }
        this.updateTableCarousel();
      });
  }

  /**
   * Обработчик события уничтожения компонента
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
