<app-caption class="table-title"
             [class.expanded]="isExpanded"
             [title]="title | translate"
             [spoiler]="true"
             [corners]="true"
             [cornersTablet]="true"
             (changeState)="onSpoilerChangeState($event)"
></app-caption>
<div class="table-body" data-cy="table-body">
  <div class="table-body__inner" data-cy="table-body-inner">
    <form class="table-body__form table-form" [formGroup]="gamePlayForm" (ngSubmit)="onGamePlayHandler()">
      <div class="table-form__form-main">

        <div class="user-hint">
          <h4 class="user-hint__title" data-cy="user-hint-title">{{ hint | translate }}</h4>
          <button type="button" class="user-hint__help" (click)="onShowHelpDialog(helpAlias)"></button>
        </div>

        <div *ngIf="table === 1" class="table-form__table-1">
          <div class="user-choice">
            <div data-cy="user-choice-number" class="user-choice__number">{{ gamePlayForm.controls['reel1'].value || '' }}</div>
            <div data-cy="user-choice-number" class="user-choice__number">{{ gamePlayForm.controls['reel2'].value || '' }}</div>
            <div data-cy="user-choice-number" class="user-choice__number">{{ gamePlayForm.controls['reel3'].value || '' }}</div>
            <div data-cy="user-choice-number" class="user-choice__number">{{ gamePlayForm.controls['reel4'].value || '' }}</div>
          </div>

          <div class="reels" data-cy="reels">
            <div class="reels__current"></div>
            <app-reel formControlName="reel1" class="reel"></app-reel>
            <app-reel formControlName="reel2" class="reel"></app-reel>
            <app-reel formControlName="reel3" class="reel"></app-reel>
            <app-reel formControlName="reel4" class="reel"></app-reel>
          </div>

          <div class="bet-actions">
            <app-freaky-button class="bet-actions__auto"
                               [title]="'auto' | translate"
                               (press)="onAutoClick()"
            ></app-freaky-button>

            <app-freaky-button class="bet-actions__refresh"
                               (press)="onAutoClick()"
            ><div class="bet-actions__refresh-icon"></div></app-freaky-button>
          </div>
        </div>

        <app-balls-colors *ngIf="table !== 1"
                          class="balls-colors"
                          formControlName="bt_code"
                          [table]="table"
                          [victoryOdd]="colorsOfVictoryOdd"
        ></app-balls-colors>
      </div>

      <app-game-control class="game-control"
                        formControlName="gameParams"
                        data-cy="game-control"
                        [maxDraws]="maxDraws"
                        [minBet]="minBet"
                        [maxBet]="maxBet"
      >
        <div class="game-actions">
          <app-freaky-button class="game-actions__reset"
                             [isDisabled]="gamePlayForm.pristine || gamePlayForm.invalid"
                             (press)="resetForm()"
                             data-cy="game-actions-reset"
          ><div class="game-actions__reset-icon"></div></app-freaky-button>

          <app-freaky-button class="game-actions__pay"
                             [isDisabled]="gamePlayForm.invalid || !maxDraws"
                             [isBusy]="isAnimating"
                             (press)="onGamePlayHandler()"
                             data-cy="game-actions-pay"
          >
            <div *ngIf="isAnimating"
                 class="game-actions__processing"
                 [class.game-actions__processing_animated]="isLoading"
            >
              <svg *ngIf="!isLoading" class="game-actions__success" width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.7732 0L5.43299 10.5069L1.2268 6.23288L0 7.52397L5.43299 13L17 1.24658L15.7732 0Z" fill="#00C408"/>
              </svg>
              {{ isLoading ? ('pik4.processing' | translate) : ('pik4.paid' | translate) }}
                <div [style.display]="isLoading ? 'inline' : 'none'">
                  <span class="dot dot_1">.</span><span class="dot dot_2">.</span><span class="dot dot_3">.</span>
                </div>
            </div>
            <ng-container *ngIf="!isAnimating">
              {{ 'pay' | translate }}
              <ng-container *ngIf="gameSum">{{ gameSum }} &#8372;</ng-container>
            </ng-container>
          </app-freaky-button>
        </div>

        <div class="drop drop_1" #drop></div>

      </app-game-control>
    </form>
  </div>
</div>
