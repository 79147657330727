import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@app/core/services/api.service';
import { IHelpResponse } from '@app/core/interfaces/i-help-response';

/**
 * Эффекты для работы с диалогом справки
 */
@Injectable()
export class HelpDialogEffects {
  /**
   * Наблюдаемая переменная в результате создания эффекта для открытия диалога справки
   * Сама переменная не используется
   */
  loadHelpDialog$ = createEffect(() => this.actions$.pipe(
    ofType('[Help Dialog] Open help dialog'),
    mergeMap((action: {alias: string}) => this.apiService.getPageInfo(
      action.alias,
      this.translateService.currentLang || this.translateService.defaultLang
    )
      .pipe(
        map((helpResponse: IHelpResponse) => ({
          type: '[Help Dialog] Open help dialog success',
          title: helpResponse.title,
          content: helpResponse.content
        }))
      ))
  ));

  /**
   * Конструктор эффекта
   * @param actions$ Наблюдаемая переменная действий
   * @param apiService Экземпляр API-сервиса
   * @param translateService Экземпляр сервиса переводов
   */
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(
              private readonly actions$: Actions,
              private readonly apiService: ApiService,
              private readonly translateService: TranslateService
              // eslint-disable-next-line no-empty-function
  ) { }
}
