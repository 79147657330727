import {
  Component, forwardRef, Input
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IGameParams } from '@app/shared/interfaces/i-game-params';

/**
 * Компонент выбора параметров игры
 */
@Component({
  selector: 'app-game-control',
  templateUrl: './game-control.component.html',
  styleUrls: ['./game-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => GameControlComponent),
      multi: true
    }
  ]
})
export class GameControlComponent implements ControlValueAccessor {
  /**
   * Минимальная ставка в грн
   */
  @Input() minBet = 1;

  /**
   * Максимальная ставка в грн
   */
  @Input() maxBet = 1000;

  /**
   * Минимально возможное количество тиражей,
   * на которые можно поставить
   */
  @Input() minDraws = 1;

  /**
   * Максимально возможное количество тиражей,
   * на которые можно поставить
   */
  @Input() maxDraws = 24;

  /**
   * Допустимые символы для ввода в поле ставки
   * @private
   */
  private allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];

  /**
   * Параметры игры, которые есть на каждом столе
   */
  gameParams: IGameParams = {
    bet: this.minBet,
    drawsCount: 1
  };

  /**
   * Функция, определяющая, конечно ли число?
   */
  isFinite = Number.isFinite;

  /**
   * Случайный идентификатор для уникального ID поля ввода
   */
  uid = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

  /**
   * Функция, вычисляющая процент ширины левой части ползунка
   * от полной ширины ползунка
   */
  get percentage(): number {
    return ((this.gameParams.drawsCount - 1) / (this.maxDraws - 1)) * 100;
  }

  /**
   * Унаследованное событие от базового класса.
   * Вызывается в коллбеке при изменении значения UI-элемента
   * @param v Передаваемое значение
   */
  // eslint-disable-next-line class-methods-use-this,@typescript-eslint/no-unused-vars
  onChange: any = (v: IGameParams) => {};

  /**
   * Унаследованное событие от базового класса
   */
  // eslint-disable-next-line class-methods-use-this
  onTouched: any = () => {};

  /**
   * Метод для программного присвоения значения компоненту
   * @param value Значение
   */
  writeValue(value: IGameParams) {
    if (value) {
      this.gameParams = value;
    }
  }

  /**
   * Метод, который вызывается при изменении значения UI-элемента
   * @param fn Передаваемая callback-функция
   */
  registerOnChange(fn: (v: IGameParams) => void): void {
    this.onChange = fn;
  }

  /**
   * Метод, который вызывается при касании к UI-элементу
   * @param fn Передаваемая callback-функция
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Обработчик, вызываемый при увеличении ставки на 1 грн
   */
  onBetIncrease(): void {
    if (this.gameParams.bet < this.maxBet) {
      this.gameParams.bet += 1;
      this.onChange(this.gameParams);
    }
  }

  /**
   * Обработчик, вызываемый при уменьшении ставки на 1 грн
   */
  onBetDecrease(): void {
    if (this.gameParams.bet > this.minBet) {
      this.gameParams.bet -= 1;
      this.onChange(this.gameParams);
    }
  }

  /**
   * Обработчик, вызываемый при удвоении ставки
   */
  onBetDouble(): void {
    if (this.gameParams.bet * 2 <= this.maxBet) {
      this.gameParams.bet *= 2;
      this.onChange(this.gameParams);
    }
  }

  /**
   * Обработчик, вызываемый при увеличении ставки на заданное значение
   * @param v Значение, но которое увеличить (в грн)
   */
  onAddToBet(v: number): void {
    if (this.gameParams.bet + v <= this.maxBet) {
      this.gameParams.bet += v;
      this.onChange(this.gameParams);
    }
  }

  /**
   * Обработчик, вызываемый при изменении значения компонента
   */
  onBetModelChange(): void {
    if (this.gameParams.bet > this.maxBet) {
      this.gameParams.bet = this.maxBet;
    }
    if (this.gameParams.bet < this.minBet) {
      this.gameParams.bet = this.minBet;
    }
    this.onChange(this.gameParams);
  }

  /**
   * Обработчик, вызываемый при вводе в поле ввода каких-либо символов
   * @param event Событие ввода
   */
  onBetFieldInput(event: Event): void {
    const elem = (event.target as HTMLInputElement);
    let inputValue = parseInt(elem.value, 10);
    // if (inputValue < this.minBet) {
    //   inputValue = this.minBet;
    // }
    if (inputValue > this.maxBet) {
      inputValue = this.maxBet;
    }
    elem.value = inputValue.toString();
  }

  /**
   * Обработчик, вызываемый при нажатии каких-либо кнопок в поле ввода
   * @param event Событие нажатия кнопки на клавиатуре
   */
  onBetFieldKeyDown(event: KeyboardEvent): void {
    if (!this.allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  /**
   * Обработчик, вызываемый при потере фокуса полем ввода
   * @param event Передаваемое событие
   */
  onBetFieldBlur(event: Event): void {
    const inputField = event.target as HTMLInputElement;
    inputField.value = this.gameParams.bet.toString();
  }
}
