import { InjectionToken } from '@angular/core';
import { IConfig } from '@app/core/interfaces/i-config';

/**
 * Токен объекта конфигурации приложения
 */
export const APP_CONFIG = new InjectionToken<IConfig>('app.config');

/**
 * Функция проверки, открыто ли веб-приложение в iframe
 */
export const inIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

/**
 * Функция для определения значения позиции вертикальной прокрутки в родительском окне
 */
export const parentScrollTop = (): number => {
  if (typeof window.parent.pageYOffset !== 'undefined') {
    // most browsers except IE before #9
    return window.parent.pageYOffset;
  }
  const docBody = window.parent.document.body; // IE 'quirks'
  let docElem = window.parent.document.documentElement; // IE with doctype
  docElem = docElem.clientHeight ? docElem : docBody;
  return docElem.scrollTop;
};

/**
 * Интервалы приема ставок в тиражах (5 мин 10 сек)
 */
export const DRAWS_INTERVALS: {[getLottData: number]: number} = {
  30: 5 * 60 + 10,
  52: 5 * 60 + 10
};

/**
 * Ограничение максимальной паузы между переподключениями к веб-сокету
 * или попытками отправить новый запрос при неудавшемся предыдущем
 */
export const MAX_TIMER_INTEGER = 20 * 60 * 1000;

/**
 * Длительность трансляции, начиная от момента показа в ней выигрышной комбинации
 */
export const TRAN_DELAY = 30000;

/**
 * Полная длительность трансляции
 */
export const TRAN_DURATION = 40000;

/**
 * Функция преобразования комбинации номеров из строкового вида в массив
 * Например: 1,2,3,4 => [1, 2, 3, 4]
 * @param comb Комбинация номеров
 */
export const convertComb = (comb: string | Array<number> | undefined): Array<number> => {
  if (!comb) {
    return [];
  }
  if (typeof comb === 'string') {
    return (comb as string).split(',').map((v: string) => +v);
  }
  return comb;
};
