import {
  Component, HostListener, OnDestroy, OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectErrorsDialogData } from '@app/store/selectors/errors-dialog.selectors';
import { IErrorsDialog } from '@app/core/interfaces/i-errors-dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Компонент диалогового окна ошибки
 */
@Component({
  selector: 'app-errors-dialog',
  templateUrl: './errors-dialog.component.html',
  styleUrls: ['./errors-dialog.component.scss']
})
export class ErrorsDialogComponent implements OnInit, OnDestroy {
  /**
   * Наблюдаемая переменная для выбора данных диалогового окна ошибки
   */
  dialogData$ = this.store.select(selectErrorsDialogData);

  /**
   * Переменная с данными диалогового окна ошибки
   */
  dialogData: IErrorsDialog | undefined;

  /**
   * Наблюдаемая переменная для уничтожения всех подписок
   */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   * Слушатель кликов по документу
   * @param event Событие клика
   */
  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    this.closeDialog(event);
  }

  /**
   * Конструктор компонента
   * @param store NgRx-хранилище приложения
   */
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(private store: Store) { }

  /**
   * Обработчик нажатия кнопки действия в диалоговом окне
   */
  onAction(): void {
    this.store.dispatch({
      type: '[Errors Dialog] Errors dialog action',
      data: this.dialogData
    });
  }

  /**
   * Обработчик нажатия кнопки закрытия диалогового окна
   * или клика по свободной области
   */
  closeDialog(event: Event): void {
    const elem = event.target as HTMLElement;
    if (!elem.closest('.dialog') || elem.className.includes('dialog__close')) {
      this.store.dispatch({
        type: '[Errors Dialog] Close errors dialog',
        data: this.dialogData
      });
    }
  }

  /**
   * Событие инициализации компонента
   */
  ngOnInit(): void {
    this.dialogData$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((dialogData: IErrorsDialog) => {
        this.dialogData = dialogData;
      });
  }

  /**
   * Событие уничтожения компонента
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
