import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп, отделяющий номер тиража в пределах дня от полного номера тиража
 */
@Pipe({
  name: 'onlyNumber'
})
export class OnlyNumberPipe implements PipeTransform {
  /**
   * Функция преобразования
   * @param value Преобразуемое значение
   */
  // eslint-disable-next-line class-methods-use-this
  transform(value: string): string {
    const parts = value.split('/');
    return parts[1] || parts[0];
  }
}
