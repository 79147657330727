import { createReducer, on } from '@ngrx/store';
import { IBetType } from '@app/core/interfaces/i-bet-type';
import { getLottData, getLottDataError, getLottDataSuccess } from '../actions/bet-types.actions';

/**
 * Начальное состояние хранилища данных лотереи
 */
export const initialState: Array<IBetType> = [];

/**
 * Редуктор данных по лотереи
 */
export const betTypesReducer = createReducer(
  initialState,
  on(getLottData, (state) => state),
  on(getLottDataSuccess, (state, { betTypes }) => betTypes || state),
  on(getLottDataError, (state) => state)
);
