import { LOCALE_ID, NgModule } from '@angular/core';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig, HammerModule
} from '@angular/platform-browser';

import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from '@app/app-routing.module';
import { EffectsModule } from '@ngrx/effects';
import { DrawsEffects } from '@app/store/effects/draws.effects';
import { BetTypesEffects } from '@app/store/effects/bet-types.effects';
import { helpDialogReducer } from '@app/store/reducers/help-dialog.reducer';
import { BetsEffects } from '@app/store/effects/bets.effects';
import { HelpDialogEffects } from '@app/store/effects/help-dialog.effects';
import localeRu from '@angular/common/locales/ru';
import localeUa from '@angular/common/locales/uk';
import { registerLocaleData } from '@angular/common';
import { drawsArchiveReducer } from '@app/store/reducers/draws-archive.reducer';
import { DrawsArchiveEffects } from '@app/store/effects/draws-archive.effects';
import { drawsExtraReducer } from '@app/store/reducers/draws-extra.reducer';
import { DrawsExtraEffects } from '@app/store/effects/draws-extra.effects';
import { errorsDialogReducer } from '@app/store/reducers/errors-dialog.reducer';
import { statsReducer } from '@app/store/reducers/stats.reducer';
import { StatsEffects } from '@app/store/effects/stats.effects';
import { translationReducer } from '@app/store/reducers/translation.reducer';
import { SharedModule } from '@app/shared/shared.module';
import { Pik4Module } from '@app/features/pik4/pik4.module';
import { preloaderReducer } from '@app/store/reducers/preloader.reducer';
import { extModules } from '@app/build-specifics';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { drawsReducer } from './store/reducers/draws.reducer';
import { betTypesReducer } from './store/reducers/bet-types.reducer';
import { betsReducer } from './store/reducers/bets.reducer';

registerLocaleData(localeRu);
registerLocaleData(localeUa);

/**
 * Функция-фабрика, которая загружает переводы
 * @param http
 */
export const httpLoaderFactory = (http: HttpClient): TranslateLoader => new TranslateHttpLoader(http, 'assets/i18n/', `.json?${Date.now()}`);

/**
 * Главный, базовый модуль приложения.
 * Содержит в себе все остальные модули
 */
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    StoreModule.forRoot({
      draws: drawsReducer,
      betTypes: betTypesReducer,
      tickets: betsReducer,
      archive: drawsArchiveReducer,
      helpDialog: helpDialogReducer,
      counters: drawsExtraReducer,
      errorsDialog: errorsDialogReducer,
      stats: statsReducer,
      translationStatus: translationReducer,
      loadingStage: preloaderReducer
    }),
    EffectsModule.forRoot([
      DrawsEffects,
      BetTypesEffects,
      BetsEffects,
      HelpDialogEffects,
      DrawsArchiveEffects,
      DrawsExtraEffects,
      StatsEffects
    ]),
    extModules,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'uk'
    }),
    HammerModule,
    Pik4Module
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ru-RU' },
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig, deps: [] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
