<app-caption class="bets-title"
             [title]="'bets.active-bets' | translate"
             [cornerColor]="'#FFFFFF'"
             [cornersTablet]="true"
>
  <button type="button" class="bets-title__to-reg-bets" routerLink="/"></button>
  <label *ngIf="(playedBets$ | async)?.length || (unplayedBets$ | async)?.length || (unplayedBetsMulti$ | async)?.length"
         class="bets-title__menu-btn">
    <input type="checkbox" class="bets-title__menu-control" [(ngModel)]="sortingBlockVisible" />
  </label>
</app-caption>

<div class="bets-body" data-cy="bets-body">
  <app-sorting-menu *ngIf="sortingBlockVisible"
                    class="bets-body__menu sorting-menu"
                    [items]="menuItems"
                    [(ngModel)]="currentMenuItem"
  ></app-sorting-menu>

  <div class="bets-body__inner" data-cy="bets-body-inner">

    <div *ngIf="!(playedBets$ | async)?.length && !(unplayedBets$ | async)?.length && !(unplayedBetsMulti$ | async)?.length"
         class="bets-body__empty" data-cy="bets-body-empty">
      {{ 'bets.make-first' | translate }}
    </div>

    <app-bets-spoiler *ngIf="(unplayedBets$ | async)?.length"
                      class="spoiler"
                      [style.order]="currentMenuItem.ordering[0]"
                      [title]="'bets.unplayed-bets'"
                      [bets]="(unplayedBets$ | async) || []"
                      [draw]="currentDraw$ | async"
                      [spoilerState]="spoilersStates[0]"
                      (spoilerStateChanged)="onSpoilerStateChange(0, $event)"
    ></app-bets-spoiler>

    <app-bets-spoiler *ngIf="(unplayedBetsMulti$ | async)?.length"
                      class="spoiler"
                      [style.order]="currentMenuItem.ordering[1]"
                      [title]="'bets.unplayed-multi-bets'"
                      [bets]="(unplayedBetsMulti$ | async) || []"
                      [spoilerState]="spoilersStates[1]"
                      (spoilerStateChanged)="onSpoilerStateChange(1, $event)"
    ></app-bets-spoiler>

    <app-bets-spoiler *ngIf="(playedBets$ | async)?.length"
                      class="spoiler"
                      [style.order]="currentMenuItem.ordering[2]"
                      [title]="'bets.played-bets'"
                      [bets]="(playedBets$ | async) || []"
                      [spoilerState]="spoilersStates[2]"
                      (spoilerStateChanged)="onSpoilerStateChange(2, $event)"
    ></app-bets-spoiler>

    <a *ngIf="apiService.token"
       target="_blank"
       href="{{ config.betsArchive }}"
       class="bets-body__archive">
      <app-freaky-button class="bets-body__button" [title]="'bets.bets-archive' | translate"></app-freaky-button>
    </a>
  </div>

</div>
