<div data-cy="menu-title" class="menu-title">{{ 'bets.sort' | translate }}</div>
<div data-cy="menu" class="menu">
  <label for="sorting_menu_{{ uid }}"
         class="menu__current"
         data-cy="menu-current"
         [innerHTML]="val.title | translate"
  ></label>
  <input id="sorting_menu_{{ uid }}"
         type="checkbox"
         class="menu__control"
         data-cy="menu-control"
         [(ngModel)]="sortingMenuVisible"
  />
  <ul class="menu__items" data-cy="menu-items">
    <li *ngFor="let item of items; let i = index"
        class="menu__item"
        data-cy="menu-item"
        [class.menu__item_current]="val.title === item.title"
    >
      <label class="menu__sorting"
             data-cy="menu-sorting"
      >
        <input id="item_{{ i }}_{{ uid }}"
               class="menu__item-control"
               data-cy="menu-item-control"
               type="radio"
               name="sorting_{{ uid }}"
               [value]="item.title"
               [(ngModel)]="val.title"
               (ngModelChange)="onSortingMenuChange(item)"
        />
        <div class="menu__radio"
             data-cy="menu-radio"
        ></div>
        <div class="menu__sorting"
              data-cy="menu-sorting"
             [innerHTML]="item.title | translate"
        ></div>
      </label>
    </li>
  </ul>
</div>
