<ng-container *ngIf="bet">
  <app-caption class="bet-item-header"
               [cornerColor]="'#F2F5FA'"
  >
    <div class="bet-item-header__inner">
      <div class="bet-item-header__icon icon icon_pik4"></div>
      {{ tableNames[bet.table - 1] | translate }}
      <!--<button type="button" class="bet-item-delete"></button>-->
    </div>
  </app-caption>
  <div class="bet-item-body">
    <div class="bet-item-body__line">
      <div class="bet-item-body__cost">{{ 'bets.cost' | translate }}</div>
      <div class="bet-item-body__bet">{{ bet.sum }} {{ 'uah' | translate }}</div>
    </div>
    <div class="bet-item-body__line">
      <div class="bet-item-body__choice">
        <ng-container *ngIf="bet.table === 1 && bet.comb">
          {{ bet.comb[0] }} {{ bet.comb[1] }} {{ bet.comb[2] }} {{ bet.comb[3] }}
        </ng-container>
        <div *ngIf="bet.table !== 1" class="bet-sketch bet-sketch_{{ bet.bt_code || '' | color }}">
          <div class="bet-sketch__balls bet-sketch__balls_{{ bet.bt_code || '' | color }}">
            <div *ngFor="let ball of (bet.bt_code || '' | balls)"
                 class="bet-sketch__ball"
                 [class.bet-sketch__ball_active]="ball"
            ></div>
          </div>
        </div>
      </div>

      <div class="bet-item-body__odd">
        <ng-container *ngIf="bet.coef && bet.status !== 'bets.lose'">
          <ng-container *ngIf="!((bet.status === 'bets.processing' || bet.status === 'bets.waiting') && bet.table === 1)">
            x {{ bet.coef }}
            <span *ngIf="bet.status === 'bets.win'"
                  class="bet-item-body__win"
            >({{ bet.coef * bet.sum | number:'1.0-2' }} {{ 'uah' | translate }})</span>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="bet.draws">
      <input id="multidraws_bet_{{ uid }}"
             class="multibet-control"
             type="checkbox"
             [(ngModel)]="spoilerState"
      />
      <label for="multidraws_bet_{{ uid }}" class="multibet-header">
        <span class="multibet-header__text">{{ 'bets.multibet' | translate }}</span>
      </label>
      <div class="multibet-body" [style.max-height.px]="spoilerState ? inner.offsetHeight + 40 : 0">
        <div #inner class="multibet-body__inner">
          <div *ngFor="let draw of bet.draws"
               class="multibet-body__draw"
               [innerHTML]="('bets.draw-no' | translate) + draw"
          ></div>
        </div>
      </div>
    </ng-container>
    <div class="bet-item-body__line">
      <div class="bet-item-body__status-name">{{ 'pik4.bet-status' | translate }}</div>
      <div class="bet-item-body__status-value"
           [class.bet-item-body__status-value_win]="bet.status === 'bets.win'"
           [class.bet-item-body__status-value_lose]="bet.status === 'bets.lose'"
      >{{ bet.status | translate }}</div>
    </div>
  </div>

</ng-container>
