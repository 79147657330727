import { createReducer, on } from '@ngrx/store';
import { IErrorsDialog } from '@app/core/interfaces/i-errors-dialog';
import { closeErrorsDialog, errorsDialogAction, openErrorsDialog } from '@app/store/actions/erros-dialog.actions';
import { ResponseCode } from '@app/core/enums/response-code';

/**
 * Начальное состояние для диалога ошибки
 */
export const initialState: IErrorsDialog = {
  lottCode: 0,
  errorCode: ResponseCode.Ok,
  title: 'error',
  content: '',
  buttonText: 'continue',
  isShown: false
};

/**
 * Редуктор для диалога ошибки
 */
export const errorsDialogReducer = createReducer(
  initialState,
  on(openErrorsDialog, (state, { data }) => ({ ...data, isShown: true })),
  on(closeErrorsDialog, (state) => ({
    ...state,
    isShown: false
  })),
  on(errorsDialogAction, (state) => ({
    ...state,
    isShown: false
  }))
);
