<div class="bets-carousel" [class.bets-carousel_scrolling]="hasScrolling">
  <div class="bets-carousel__inner">
    <div class="bets-carousel__items">
      <div *ngIf="viewBets.length" class="bets-carousel__cutter">
        <div #scroller class="bets-carousel__scroller" (swipe)="onSwipe($event)">
          <div class="bets-carousel__bets">
            <div *ngFor="let vb of viewBets"
                 [ngClass]="vb.color === 'none' ? 'bets-carousel__blank' : 'bets-carousel__bet bets-item bets-item_' + vb.color.toLowerCase()"
            >
              <span *ngIf="vb.value && vb.color !== 'b2y2'" class="bets-item__balls">
                <span *ngFor="let ball of range(vb.count)"
                      class="bets-item__ball"
                      [class.bets-item__ball_active]="ball === vb.position"
                ></span>
              </span>
              <span class="bets-item__value">{{ vb.value ? 'x ' + vb.value : '' }}</span>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="bets-carousel__add" (click)="onBlankAdd()"></button>
    </div>
  </div>
</div>
