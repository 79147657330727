import { createReducer, on } from '@ngrx/store';
import { incStage } from '@app/store/actions/preloader.actions';

/**
 * Начальное значение этапа загрузки приложения
 */
export const initialState = 0;

/**
 * Редуктор для этапа загрузки приложения
 */
export const preloaderReducer = createReducer(
  initialState,
  on(incStage, (state) => state + 1)
);
