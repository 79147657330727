<button type="button"
        class="button"
        [attr.disabled]="isDisabled || null"
        (click)="press.emit($event)">
  <span class="button__inner" [class.button__inner_busy]="isBusy" data-cy="button-inner">
    <span class="button__corner button__corner_lb"></span>
      {{ title }}
      <ng-content></ng-content>
    <span class="button__corner button__corner_rt"></span>
  </span>
</button>
<div class="shadow"></div>
