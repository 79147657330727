import { createAction, props } from '@ngrx/store';

/**
 * Действие для установки статуса трансляции по лотерее
 */
export const setState = createAction('[Translation] Set State', props<{
  lottCode: number;
  isPreTranslation?: boolean;
  isTranslation?: boolean;
}>());
