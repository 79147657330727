import {
  Component, ElementRef, EventEmitter, Input, OnInit, Output
} from '@angular/core';

/**
 * Компонент заголовка со срезанными углами
 */
@Component({
  selector: 'app-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.scss']
})
export class CaptionComponent implements OnInit {
  /**
   * Текст в заголовке
   */
  @Input() title = '';

  /**
   * Является ли заголовок частью спойлера
   */
  @Input() spoiler = false;

  /**
   * Цвет треугольника в нижнем левом углу.
   * Должен совпадать с цветом фона, чтоб заголовок казался срезанным
   */
  @Input() cornerColor = '';

  /**
   * Флаг видимости цветных углов на десктопе
   */
  @Input() corners = false;

  /**
   * Флаг видимости цветных углов на планшете
   */
  @Input() cornersTablet = false;

  /**
   * Флаг видимости цветных углов на мобайле
   */
  @Input() cornersMobile = false;

  /**
   * Флаг применения эффектов к заголовку: тени при наведении и затемнении при клике
   */
  @Input() effects = false;

  /**
   * Внутренние отступы в блоке заголовка
   */
  @Input() padding = '0';

  /**
   * Переменная, выводящая в событие смену состояния заголовка-спойлера
   */
  @Output() changeState = new EventEmitter<boolean>();

  /**
   * Развернут ли спойлер
   */
  spoilerExpanded = true;

  /**
   * Конструктор компонента
   * @param elementRef Ссылка на собственный DOM-элемент
   */
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(private readonly elementRef: ElementRef<HTMLElement>) { }

  /**
   * Обработчик нажатия на заголовок
   */
  onCaptionClick(): void {
    if (this.spoiler) {
      if (this.elementRef.nativeElement.classList.contains('expanded')) {
        this.elementRef.nativeElement.classList.remove('expanded');
        this.spoilerExpanded = false;
      } else {
        this.elementRef.nativeElement.classList.add('expanded');
        this.spoilerExpanded = true;
      }
      this.changeState.emit(this.spoilerExpanded);
    }
  }

  /**
   * Обработчик события инициализации компонента
   */
  ngOnInit(): void {
    this.spoilerExpanded = this.elementRef.nativeElement.classList.contains('expanded');
  }
}
