/**
 * Событие в обмене сообщениями с сервером
 */
export enum ApiEvent {
  /**
   * Запрос
   */
  Request = 'request',
  /**
   * Ответ
   */
  Response = 'response'
}
