import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TimeToEndPipe } from '@app/shared/pipes/time-to-end.pipe';
import { GameControlComponent } from './components/game-control/game-control.component';
import { DrawInfoComponent } from './components/draw-info/draw-info.component';
import { FixedInFrameDirective } from './directives/fixed-in-frame.directive';

@NgModule({
  declarations: [
    GameControlComponent,
    DrawInfoComponent,
    TimeToEndPipe,
    FixedInFrameDirective
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule
  ],
  exports: [
    TranslateModule,
    GameControlComponent,
    DrawInfoComponent,
    TimeToEndPipe,
    FixedInFrameDirective
  ]
})
export class SharedModule { }
