import { createAction, props } from '@ngrx/store';
import { IDraw } from '@app/core/interfaces/i-draw';

/**
 * Действие для запроса получения списка тиражей
 */
export const getDraws = createAction('[Draws List] Get List Of Draws');

/**
 * Действие при успешном получении списка тиражей
 */
export const getDrawsSuccess = createAction('[Draws List] List Of Draws Loaded Success', props<{ draws: Array<IDraw> }>());

/**
 * Действие при ошибке получения списка тиражей
 */
export const getDrawsError = createAction('[Draws List] Draws Loading Error');
