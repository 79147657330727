import {ApiAction} from "@app/core/enums/api-action";
import {ResponseCode} from "@app/core/enums/response-code";
import {MOCK_SEQ} from "@app/core/mocks/utils";

export const getStat = [
  {
    request: {
      action: ApiAction.getStat,
      lottCode: 30,
      type: 5,
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getStat,
      R: ResponseCode.Ok,
      lottCode: 30,
      seq: MOCK_SEQ,
      stat: {
        "1": {
          "1": {
            "s": [
              14,
              19,
              16,
              13
            ],
            "l": [
              22,
              8,
              3,
              14
            ]
          },
          "2": {
            "s": [
              7,
              17,
              18,
              18
            ],
            "l": [
              7,
              16,
              8,
              20
            ]
          },
          "3": {
            "s": [
              17,
              15,
              14,
              10
            ],
            "l": [
              4,
              7,
              10,
              1
            ]
          },
          "4": {
            "s": [
              14,
              16,
              10,
              16
            ],
            "l": [
              2,
              1,
              0,
              16
            ]
          },
          "5": {
            "s": [
              13,
              11,
              12,
              19
            ],
            "l": [
              1,
              20,
              7,
              0
            ]
          },
          "6": {
            "s": [
              18,
              17,
              13,
              19
            ],
            "l": [
              13,
              3,
              4,
              3
            ]
          },
          "7": {
            "s": [
              19,
              10,
              17,
              14
            ],
            "l": [
              0,
              22,
              6,
              2
            ]
          },
          "8": {
            "s": [
              19,
              4,
              16,
              16
            ],
            "l": [
              9,
              18,
              1,
              9
            ]
          },
          "9": {
            "s": [
              15,
              16,
              14,
              11
            ],
            "l": [
              5,
              0,
              2,
              5
            ]
          },
          "10": {
            "s": [
              11,
              22,
              17,
              11
            ],
            "l": [
              3,
              4,
              9,
              25
            ]
          }
        },
        "2": {
          "R1": {
            "s": 46,
            "l": 3
          },
          "R2": {
            "s": 8,
            "l": 15
          },
          "R3": {
            "s": 0,
            "l": 147
          },
          "R4": {
            "s": 0,
            "l": 147
          },
          "B1": {
            "s": 49,
            "l": 1
          },
          "B2": {
            "s": 24,
            "l": 7
          },
          "B3": {
            "s": 5,
            "l": 42
          },
          "B4": {
            "s": 1,
            "l": 79
          },
          "Y1": {
            "s": 62,
            "l": 5
          },
          "Y2": {
            "s": 42,
            "l": 0
          },
          "Y3": {
            "s": 8,
            "l": 19
          },
          "Y4": {
            "s": 2,
            "l": 13
          },
          "G1": {
            "s": 51,
            "l": 1
          },
          "G2": {
            "s": 53,
            "l": 0
          },
          "G3": {
            "s": 21,
            "l": 5
          },
          "G4": {
            "s": 3,
            "l": 9
          },
          "B2Y2": {
            "s": 4,
            "l": 7
          }
        },
        "3": {
          "R1": {
            "s": 14,
            "l": 22
          },
          "R2": {
            "s": 19,
            "l": 8
          },
          "R3": {
            "s": 16,
            "l": 3
          },
          "R4": {
            "s": 13,
            "l": 14
          },
          "B1": {
            "s": 24,
            "l": 4
          },
          "B2": {
            "s": 32,
            "l": 7
          },
          "B3": {
            "s": 32,
            "l": 8
          },
          "B4": {
            "s": 28,
            "l": 1
          },
          "Y1": {
            "s": 45,
            "l": 1
          },
          "Y2": {
            "s": 44,
            "l": 1
          },
          "Y3": {
            "s": 35,
            "l": 0
          },
          "Y4": {
            "s": 54,
            "l": 0
          },
          "G1": {
            "s": 64,
            "l": 0
          },
          "G2": {
            "s": 52,
            "l": 0
          },
          "G3": {
            "s": 64,
            "l": 1
          },
          "G4": {
            "s": 52,
            "l": 2
          }
        }
      }
    }
  },
  {
    request: {
      action: ApiAction.getStat,
      lottCode: 30,
      type: 4,
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getStat,
      R: ResponseCode.Ok,
      lottCode: 30,
      seq: MOCK_SEQ,
      stat: {
        "1": {
          "1": {
            "s": [
              37,
              31,
              25,
              30
            ],
            "l": [
              5,
              7,
              1,
              9
            ]
          },
          "2": {
            "s": [
              41,
              23,
              31,
              21
            ],
            "l": [
              0,
              13,
              13,
              29
            ]
          },
          "3": {
            "s": [
              26,
              28,
              27,
              26
            ],
            "l": [
              21,
              1,
              7,
              2
            ]
          },
          "4": {
            "s": [
              26,
              27,
              29,
              23
            ],
            "l": [
              2,
              14,
              10,
              0
            ]
          },
          "5": {
            "s": [
              27,
              36,
              29,
              33
            ],
            "l": [
              4,
              22,
              4,
              7
            ]
          },
          "6": {
            "s": [
              26,
              29,
              25,
              23
            ],
            "l": [
              23,
              3,
              9,
              15
            ]
          },
          "7": {
            "s": [
              23,
              31,
              34,
              33
            ],
            "l": [
              1,
              2,
              0,
              1
            ]
          },
          "8": {
            "s": [
              19,
              22,
              19,
              35
            ],
            "l": [
              3,
              9,
              5,
              4
            ]
          },
          "9": {
            "s": [
              31,
              29,
              26,
              19
            ],
            "l": [
              6,
              0,
              3,
              16
            ]
          },
          "10": {
            "s": [
              22,
              22,
              33,
              35
            ],
            "l": [
              12,
              21,
              2,
              5
            ]
          }
        },
        "2": {
          "R1": {
            "s": 89,
            "l": 1
          },
          "R2": {
            "s": 14,
            "l": 7
          },
          "R3": {
            "s": 2,
            "l": 211
          },
          "R4": {
            "s": 0,
            "l": 278
          },
          "B1": {
            "s": 110,
            "l": 0
          },
          "B2": {
            "s": 46,
            "l": 21
          },
          "B3": {
            "s": 7,
            "l": 13
          },
          "B4": {
            "s": 0,
            "l": 278
          },
          "Y1": {
            "s": 110,
            "l": 0
          },
          "Y2": {
            "s": 71,
            "l": 18
          },
          "Y3": {
            "s": 23,
            "l": 4
          },
          "Y4": {
            "s": 3,
            "l": 10
          },
          "G1": {
            "s": 95,
            "l": 4
          },
          "G2": {
            "s": 91,
            "l": 0
          },
          "G3": {
            "s": 44,
            "l": 5
          },
          "G4": {
            "s": 6,
            "l": 33
          },
          "B2Y2": {
            "s": 8,
            "l": 80
          }
        },
        "3": {
          "R1": {
            "s": 37,
            "l": 5
          },
          "R2": {
            "s": 31,
            "l": 7
          },
          "R3": {
            "s": 25,
            "l": 1
          },
          "R4": {
            "s": 30,
            "l": 9
          },
          "B1": {
            "s": 67,
            "l": 0
          },
          "B2": {
            "s": 51,
            "l": 1
          },
          "B3": {
            "s": 58,
            "l": 7
          },
          "B4": {
            "s": 47,
            "l": 2
          },
          "Y1": {
            "s": 79,
            "l": 2
          },
          "Y2": {
            "s": 92,
            "l": 3
          },
          "Y3": {
            "s": 83,
            "l": 4
          },
          "Y4": {
            "s": 79,
            "l": 0
          },
          "G1": {
            "s": 95,
            "l": 1
          },
          "G2": {
            "s": 104,
            "l": 0
          },
          "G3": {
            "s": 112,
            "l": 0
          },
          "G4": {
            "s": 122,
            "l": 1
          }
        }
      }
    }
  },
  {
    request: {
      action: ApiAction.getStat,
      lottCode: 30,
      type: 3,
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getStat,
      R: ResponseCode.Ok,
      lottCode: 30,
      seq: MOCK_SEQ,
      stat: {
        "1": {
          "1": {
            "s": [
              14,
              19,
              16,
              13
            ],
            "l": [
              23,
              9,
              4,
              15
            ]
          },
          "2": {
            "s": [
              7,
              18,
              18,
              18
            ],
            "l": [
              8,
              0,
              9,
              21
            ]
          },
          "3": {
            "s": [
              17,
              15,
              14,
              10
            ],
            "l": [
              5,
              8,
              11,
              2
            ]
          },
          "4": {
            "s": [
              14,
              16,
              10,
              17
            ],
            "l": [
              3,
              2,
              1,
              0
            ]
          },
          "5": {
            "s": [
              13,
              11,
              13,
              19
            ],
            "l": [
              2,
              21,
              0,
              1
            ]
          },
          "6": {
            "s": [
              19,
              17,
              13,
              19
            ],
            "l": [
              0,
              4,
              5,
              4
            ]
          },
          "7": {
            "s": [
              19,
              10,
              17,
              14
            ],
            "l": [
              1,
              23,
              7,
              3
            ]
          },
          "8": {
            "s": [
              19,
              4,
              16,
              16
            ],
            "l": [
              10,
              19,
              2,
              10
            ]
          },
          "9": {
            "s": [
              15,
              16,
              14,
              11
            ],
            "l": [
              6,
              1,
              3,
              6
            ]
          },
          "10": {
            "s": [
              11,
              22,
              17,
              11
            ],
            "l": [
              4,
              5,
              10,
              26
            ]
          }
        },
        "2": {
          "R1": {
            "s": 46,
            "l": 4
          },
          "R2": {
            "s": 8,
            "l": 16
          },
          "R3": {
            "s": 0,
            "l": 148
          },
          "R4": {
            "s": 0,
            "l": 148
          },
          "B1": {
            "s": 50,
            "l": 0
          },
          "B2": {
            "s": 24,
            "l": 8
          },
          "B3": {
            "s": 5,
            "l": 43
          },
          "B4": {
            "s": 1,
            "l": 80
          },
          "Y1": {
            "s": 62,
            "l": 6
          },
          "Y2": {
            "s": 42,
            "l": 1
          },
          "Y3": {
            "s": 9,
            "l": 0
          },
          "Y4": {
            "s": 2,
            "l": 14
          },
          "G1": {
            "s": 51,
            "l": 2
          },
          "G2": {
            "s": 53,
            "l": 1
          },
          "G3": {
            "s": 21,
            "l": 6
          },
          "G4": {
            "s": 3,
            "l": 10
          },
          "B2Y2": {
            "s": 4,
            "l": 8
          }
        },
        "3": {
          "R1": {
            "s": 14,
            "l": 23
          },
          "R2": {
            "s": 19,
            "l": 9
          },
          "R3": {
            "s": 16,
            "l": 4
          },
          "R4": {
            "s": 13,
            "l": 15
          },
          "B1": {
            "s": 24,
            "l": 5
          },
          "B2": {
            "s": 33,
            "l": 0
          },
          "B3": {
            "s": 32,
            "l": 9
          },
          "B4": {
            "s": 28,
            "l": 2
          },
          "Y1": {
            "s": 46,
            "l": 0
          },
          "Y2": {
            "s": 44,
            "l": 2
          },
          "Y3": {
            "s": 36,
            "l": 0
          },
          "Y4": {
            "s": 55,
            "l": 0
          },
          "G1": {
            "s": 64,
            "l": 1
          },
          "G2": {
            "s": 52,
            "l": 1
          },
          "G3": {
            "s": 64,
            "l": 2
          },
          "G4": {
            "s": 52,
            "l": 3
          }
        }
      }
    }
  },
  {
    request: {
      action: ApiAction.getStat,
      lottCode: 30,
      type: 2,
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getStat,
      R: ResponseCode.Ok,
      lottCode: 30,
      seq: MOCK_SEQ,
      stat: {
        "1": {
          "1": {
            "s": [
              352,
              376,
              346,
              355
            ],
            "l": [
              23,
              9,
              4,
              15
            ]
          },
          "2": {
            "s": [
              383,
              340,
              349,
              336
            ],
            "l": [
              8,
              0,
              9,
              21
            ]
          },
          "3": {
            "s": [
              343,
              318,
              355,
              339
            ],
            "l": [
              5,
              8,
              11,
              2
            ]
          },
          "4": {
            "s": [
              331,
              341,
              354,
              321
            ],
            "l": [
              3,
              2,
              1,
              0
            ]
          },
          "5": {
            "s": [
              360,
              387,
              321,
              368
            ],
            "l": [
              2,
              21,
              0,
              1
            ]
          },
          "6": {
            "s": [
              324,
              335,
              347,
              354
            ],
            "l": [
              0,
              4,
              5,
              4
            ]
          },
          "7": {
            "s": [
              376,
              376,
              345,
              354
            ],
            "l": [
              1,
              23,
              7,
              3
            ]
          },
          "8": {
            "s": [
              361,
              304,
              347,
              346
            ],
            "l": [
              10,
              19,
              2,
              10
            ]
          },
          "9": {
            "s": [
              327,
              349,
              342,
              326
            ],
            "l": [
              6,
              1,
              3,
              6
            ]
          },
          "10": {
            "s": [
              327,
              358,
              378,
              385
            ],
            "l": [
              4,
              5,
              10,
              26
            ]
          }
        },
        "2": {
          "R1": {
            "s": 1066,
            "l": 4
          },
          "R2": {
            "s": 165,
            "l": 16
          },
          "R3": {
            "s": 11,
            "l": 359
          },
          "R4": {
            "s": 0,
            "l": 3484
          },
          "B1": {
            "s": 1407,
            "l": 0
          },
          "B2": {
            "s": 539,
            "l": 8
          },
          "B3": {
            "s": 82,
            "l": 43
          },
          "B4": {
            "s": 8,
            "l": 80
          },
          "Y1": {
            "s": 1456,
            "l": 6
          },
          "Y2": {
            "s": 928,
            "l": 1
          },
          "Y3": {
            "s": 237,
            "l": 0
          },
          "Y4": {
            "s": 30,
            "l": 14
          },
          "G1": {
            "s": 1176,
            "l": 2
          },
          "G2": {
            "s": 1242,
            "l": 1
          },
          "G3": {
            "s": 531,
            "l": 6
          },
          "G4": {
            "s": 87,
            "l": 10
          },
          "B2Y2": {
            "s": 76,
            "l": 8
          }
        },
        "3": {
          "R1": {
            "s": 352,
            "l": 23
          },
          "R2": {
            "s": 376,
            "l": 9
          },
          "R3": {
            "s": 346,
            "l": 4
          },
          "R4": {
            "s": 355,
            "l": 15
          },
          "B1": {
            "s": 726,
            "l": 5
          },
          "B2": {
            "s": 658,
            "l": 0
          },
          "B3": {
            "s": 704,
            "l": 9
          },
          "B4": {
            "s": 675,
            "l": 2
          },
          "Y1": {
            "s": 1015,
            "l": 0
          },
          "Y2": {
            "s": 1063,
            "l": 2
          },
          "Y3": {
            "s": 1022,
            "l": 0
          },
          "Y4": {
            "s": 1043,
            "l": 0
          },
          "G1": {
            "s": 1391,
            "l": 1
          },
          "G2": {
            "s": 1387,
            "l": 1
          },
          "G3": {
            "s": 1412,
            "l": 2
          },
          "G4": {
            "s": 1411,
            "l": 3
          }
        }
      }
    }
  },
  {
    request: {
      action: ApiAction.getStat,
      lottCode: 30,
      type: 1,
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getStat,
      R: ResponseCode.Ok,
      lottCode: 30,
      seq: MOCK_SEQ,
      stat: {
        "1": {
          "1": {
            "s": [
              2791,
              2809,
              2837,
              2852
            ],
            "l": [
              23,
              9,
              4,
              15
            ]
          },
          "2": {
            "s": [
              2880,
              2802,
              2836,
              2864
            ],
            "l": [
              8,
              0,
              9,
              21
            ]
          },
          "3": {
            "s": [
              2754,
              2760,
              2868,
              2786
            ],
            "l": [
              5,
              8,
              11,
              2
            ]
          },
          "4": {
            "s": [
              2791,
              2830,
              2870,
              2867
            ],
            "l": [
              3,
              2,
              1,
              0
            ]
          },
          "5": {
            "s": [
              2829,
              2879,
              2794,
              2743
            ],
            "l": [
              2,
              21,
              0,
              1
            ]
          },
          "6": {
            "s": [
              2908,
              2859,
              2840,
              2813
            ],
            "l": [
              0,
              4,
              5,
              4
            ]
          },
          "7": {
            "s": [
              2857,
              2841,
              2751,
              2787
            ],
            "l": [
              1,
              23,
              7,
              3
            ]
          },
          "8": {
            "s": [
              2865,
              2768,
              2828,
              2908
            ],
            "l": [
              10,
              19,
              2,
              10
            ]
          },
          "9": {
            "s": [
              2838,
              2825,
              2814,
              2845
            ],
            "l": [
              6,
              1,
              3,
              6
            ]
          },
          "10": {
            "s": [
              2783,
              2923,
              2858,
              2831
            ],
            "l": [
              4,
              5,
              10,
              26
            ]
          }
        },
        "2": {
          "R1": {
            "s": 8332,
            "l": 4
          },
          "R2": {
            "s": 1343,
            "l": 16
          },
          "R3": {
            "s": 89,
            "l": 359
          },
          "R4": {
            "s": 1,
            "l": 18853
          },
          "B1": {
            "s": 11570,
            "l": 0
          },
          "B2": {
            "s": 4334,
            "l": 8
          },
          "B3": {
            "s": 684,
            "l": 43
          },
          "B4": {
            "s": 65,
            "l": 80
          },
          "Y1": {
            "s": 11657,
            "l": 6
          },
          "Y2": {
            "s": 7504,
            "l": 1
          },
          "Y3": {
            "s": 2134,
            "l": 0
          },
          "Y4": {
            "s": 239,
            "l": 14
          },
          "G1": {
            "s": 9733,
            "l": 2
          },
          "G2": {
            "s": 9913,
            "l": 1
          },
          "G3": {
            "s": 4317,
            "l": 6
          },
          "G4": {
            "s": 703,
            "l": 10
          },
          "B2Y2": {
            "s": 583,
            "l": 8
          }
        },
        "3": {
          "R1": {
            "s": 2791,
            "l": 23
          },
          "R2": {
            "s": 2809,
            "l": 9
          },
          "R3": {
            "s": 2837,
            "l": 4
          },
          "R4": {
            "s": 2852,
            "l": 15
          },
          "B1": {
            "s": 5634,
            "l": 5
          },
          "B2": {
            "s": 5562,
            "l": 0
          },
          "B3": {
            "s": 5704,
            "l": 9
          },
          "B4": {
            "s": 5650,
            "l": 2
          },
          "Y1": {
            "s": 8528,
            "l": 0
          },
          "Y2": {
            "s": 8568,
            "l": 2
          },
          "Y3": {
            "s": 8504,
            "l": 0
          },
          "Y4": {
            "s": 8423,
            "l": 0
          },
          "G1": {
            "s": 11343,
            "l": 1
          },
          "G2": {
            "s": 11357,
            "l": 1
          },
          "G3": {
            "s": 11251,
            "l": 2
          },
          "G4": {
            "s": 11371,
            "l": 3
          }
        }
      }
    }
  }
];
