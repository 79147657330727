import {concat, Observable, Subject, timer} from 'rxjs';
import {ApiEvent} from '@app/core/enums/api-event';
import {filter, map} from 'rxjs/operators';
import {ISocketMessage} from '../interfaces/i-socket-message';
import {MOCK_DATA} from './mock-data';
import {ApiAction} from "@app/core/enums/api-action";
import {IResponse} from "@app/core/interfaces/i-response";
import {
  DRAW_DURATION,
  DRAW_RESULT_DURATION,
  DRAWS_BEFORE_CURRENT,
  generateDraw,
  generateWinComb,
  getDrawNum,
  getDrawNumLeftPart, MAX_BET_SUM,
  MOCK_TOKEN, MOCK_TOKEN_ISE, MOCK_TOKEN_NO_BONUSES_PAY, MOCK_TOKEN_NO_MONEY, MOCK_TOKEN_OUTDATED, MOCK_TOKEN_OUTDATED2
} from "@app/core/mocks/utils";
import {getLottData} from "@app/core/mocks/get-lott-data";
import {IArchiveDraw} from "@app/core/interfaces/i-archive-draw";
import {IArchiveResponse} from "@app/core/interfaces/i-archive-response";
import {ResponseCode} from "@app/core/enums/response-code";

/**
 * Функция для получения сообщения от веб-сокета
 * @param message Сообщение
 * @private
 */
function getResponseMessage(message: any): ISocketMessage {
  const responseMessage: any = {
    event: ApiEvent.Response,
    data: {
      action: message.data.action,
      R: ResponseCode.Ok,
      seq: message.data.seq
    }
  };
  for (let i = 0; i < MOCK_DATA.length; i += 1) {
    const cases = {...MOCK_DATA[i]} as any;
    const obKeys = Object.keys(cases.request);
    let isMatch = true;
    for (const obKey of obKeys) {
      if ((obKey !== 'seq') && message.data[obKey] && (cases.request[obKey] !== message.data[obKey])) {
        isMatch = false;
        break;
      }
    }
    if (isMatch) {
      responseMessage.data = cases.response;
      responseMessage.data.seq = message.data.seq;
      break;
    }
  }

  return responseMessage;
}

/**
 * Класс для эмуляции веб-сокета
 */
export class MockWebSocket extends Subject<any> {
  /**
   * Метод для отправки сообщения веб-сокету
   * @param message Сообщение
   */
  next(message: any): void {
    const responseMessage = getResponseMessage(message);
    if (message.data.action === ApiAction.regBet) {
      switch (message.data.token) {
        case MOCK_TOKEN:
          responseMessage.data = {
            ...responseMessage.data,
            R: 0,
            lottCode: 30,
            tickets: []
          } as IResponse;
          break;
        case MOCK_TOKEN_OUTDATED:
          responseMessage.data = {
            ...responseMessage.data,
            R: ResponseCode.SessionExpired
          } as IResponse;
          break;
        case MOCK_TOKEN_OUTDATED2:
          responseMessage.data = {
            ...responseMessage.data,
            R: ResponseCode.SessionExpired2
          } as IResponse;
          break;
        case MOCK_TOKEN_NO_MONEY:
          responseMessage.data = {
            ...responseMessage.data,
            R: ResponseCode.NotEnoughMoney
          } as IResponse;
          break;
        case MOCK_TOKEN_NO_BONUSES_PAY:
          responseMessage.data = {
            ...responseMessage.data,
            R: ResponseCode.CannotPayWithBonuses
          } as IResponse;
          break;
        case MOCK_TOKEN_ISE:
          responseMessage.data = {
            ...responseMessage.data,
            R: ResponseCode.InternalServerError
          } as IResponse;
          break;
        default:
          responseMessage.data = {
            ...responseMessage.data,
            R: ResponseCode.SessionByTokenNotFound
          } as IResponse;
          break;
      }
      if (message.data.token === MOCK_TOKEN) {
        let fullAmount =  message.data.bet.sum * message.data.bet.draws;
        if (message.data.bet.table !== 1) {
          fullAmount *= message.data.bet.bt_code.length;
        }
        if (fullAmount > MAX_BET_SUM) {
          responseMessage.data = {
            ...responseMessage.data,
            R: ResponseCode.LimitsExceeded
          } as IResponse;
        } else {
          const tickets = [];
          for (let drawIndex = 0; drawIndex < message.data.bet.draws; drawIndex += 1) {
            const draw = generateDraw(drawIndex);
            for (let i = 0; i < message.data.bet.bt_code.length; i += 1) {
              const table = message.data.bet.bt_code === '2B2Y' ? 4 : message.data.bet.table;
              const getLottDataItem = getLottData[0].response.betTypes
                .filter((v) => v.table === table && v.bt_code === message.data.bet.bt_code[i]);

              const bet = [{
                table,
                comb: table === 1 ? message.data.bet.comb : message.data.bet.bt_code[i].substring(1).split('').join(';'),
                sum: message.data.bet.sum,
                bt_code: table === 4 ? 'B2Y2' : message.data.bet.bt_code[i]
              } as any];

              bet[0].coef = getLottDataItem[0].bt_coef;

              tickets.push({
                bet,
                bet_sum: message.data.bet.sum,
                description: `Чек Pick4 № ${Math.floor(Math.random() * 1000000) + 1} на тираж ${draw.number}`,
                draw_date: draw.game_start,
                draw_name: draw.number,
                mac_code: Math.floor(Math.random() * 1000000) + 1,
                regdate: new Date()
              });
            }
          }
          (responseMessage.data as any).tickets = [...tickets];
        }
      }
    }
    if (message.data.action === ApiAction.getDrawsArchive) {
      const dateStart = new Date(message.data.dateStart);
      const dateStartTS = dateStart.getTime();
      const dateEndTS = dateStartTS + 60 * 60 * 1000;
      const gameEndsCount = Math.floor((dateEndTS - DRAW_RESULT_DURATION) / DRAW_DURATION);
      let nearestGameEnd = gameEndsCount * DRAW_DURATION + DRAW_RESULT_DURATION;
      const history: Array<IArchiveDraw> = [];
      const currentDrawNumLeftPart = getDrawNumLeftPart(dateStart);
      while (nearestGameEnd > dateStartTS) {
        const {drawNum} = getDrawNum(new Date(nearestGameEnd - DRAW_DURATION));
        const drawNumLeftPart = getDrawNumLeftPart(new Date(nearestGameEnd - DRAW_DURATION - DRAW_RESULT_DURATION));
        if (drawNumLeftPart !== currentDrawNumLeftPart) {
          break;
        }
        history.push({
          comb: generateWinComb(),
          game_end: (new Date(nearestGameEnd)).toISOString(),
          number: `${drawNumLeftPart}/${drawNum}`
        });
        nearestGameEnd -= DRAW_DURATION;
      }
      responseMessage.data = {
        R: 0,
        action: ApiAction.getDrawsArchive,
        lottCode: message.data.lottCode,
        history,
        seq: 166072920161301
      } as IArchiveResponse;
    }
    const tmr = timer(100)
      .subscribe(() => {
        super.next(responseMessage);
        tmr.unsubscribe();
      });
  }

  /**
   * Метод для подписки на получение сообщений от веб-сокета
   * @param subMsg Функция, которая возвращает сообщение для подписки
   * @param unsubMsg Функция, которая возвращает сообщение для отписки
   * @param messageFilter Фильтр сообщений, которые приходят по данной подписке
   */
  multiplex(
    subMsg: () => any,
    unsubMsg: () => any,
    messageFilter: (value: any) => boolean
  ): Observable<any> {
    const message = subMsg();
    const responseMessage = getResponseMessage(message);

    const defaultValue = timer(100)
      .pipe(
        map(() => responseMessage),
        filter(messageFilter)
      );

    // Если пришел запрос на тиражи
    if (responseMessage.data.action === ApiAction.getDraws) {
      // Получаем историю тиражей
      const history = (responseMessage.data as IResponse).history;
      // Если история тиражей есть и в ней больше DRAWS_BEFORE_CURRENT тиражей
      if (history && (history.length > DRAWS_BEFORE_CURRENT)) {
        // Получаем текущий тираж
        const currentDraw = history[history.length - DRAWS_BEFORE_CURRENT - 1];
        // Получаем время до конца приема ставок на тираж
        const timeToEnd = currentDraw.end.getTime() - Date.now();
        return concat(
          defaultValue,
          timer(timeToEnd, 1000)
            .pipe(
              map((v) => v % DRAW_DURATION),
              filter((v) => (v === 7) || (v === 28)),
              map((v) => {
                if (v === 28) {
                  history[history.length - DRAWS_BEFORE_CURRENT].comb = generateWinComb();
                } else {
                  history.unshift(generateDraw(history.length - DRAWS_BEFORE_CURRENT - 1));
                  history.pop()
                }
                (responseMessage.data as IResponse).history = history;
                return responseMessage;
              })
            )
        );
      }
    }

    return defaultValue;
  }
}

/**
 * Функция для эмуляции веб-сокета (по типу функции из RxJS-библиотеки)
 */
export const mockWebSocket = () => new MockWebSocket();
