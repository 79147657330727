import {ApiAction} from "@app/core/enums/api-action";
import {DRAW_DURATION, generateDraw, MOCK_SEQ} from "@app/core/mocks/utils";
import {IDrawsExtra} from "@app/features/pik4/interfaces/i-draws-extra";

/**
 * Данные окончания приема ставок на тиражи для счетчиков в верхнем меню
 */
const extra: Array<IDrawsExtra> = [
  {
    lottCode: 30,
    history: []
  },
  {
    lottCode: 52,
    history: []
  }
];

/**
 * Текущий тираж
 */
const currentDraw = generateDraw();

for (let i = 0; i < 24; i += 1) {
  /**
   * Таймстамп конца тиража
   */
  const drawEndTS = currentDraw.start.getTime() + (i + 1) * DRAW_DURATION;
  extra[0].history.push(new Date(drawEndTS));
  extra[1].history.push(new Date(drawEndTS - 238 * 1000));
}

export const getDrawsExtra = [
  {
    request: {
      action: ApiAction.getDrawsExtra,
      extra: [30, 52],
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getDrawsExtra,
      R: 0,
      extra,
      seq: MOCK_SEQ
    }
  }
];
