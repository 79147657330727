import { createReducer, on } from '@ngrx/store';
import { IDraw } from '@app/core/interfaces/i-draw';
import { getDraws, getDrawsError, getDrawsSuccess } from '../actions/draws.actions';

/**
 * Начальное состояние хранилища списка тиражей
 */
export const initialState: Array<IDraw> = [];

/**
 * Редуктор для списка тиражей
 */
export const drawsReducer = createReducer(
  initialState,
  // Заменяем имеющиеся тиражи пришедшими
  on(getDraws, (state) => state),
  on(getDrawsSuccess, (state, { draws }) => draws || state),
  on(getDrawsError, (state) => state)
);
