import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { ModalsDirective } from './directives/modals.directive';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { ErrorsDialogComponent } from './components/errors-dialog/errors-dialog.component';

/**
 * Модуль ядра приложения
 */
@NgModule({
  declarations: [
    ModalsDirective,
    HelpDialogComponent,
    MainMenuComponent,
    ErrorsDialogComponent
  ],
  exports: [
    ModalsDirective,
    MainMenuComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    SharedModule,
    RouterModule
  ]
})
export class CoreModule {
  /**
   * Конструктор модуля.
   *
   * @param {CoreModule} parentModule
   */
  constructor(
    @Optional()
    @SkipSelf() parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
