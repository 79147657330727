import {IDraw} from "@app/core/interfaces/i-draw";
import {ApiAction} from "@app/core/enums/api-action";
import {ResponseCode} from "@app/core/enums/response-code";
import {
  DRAWS_BEFORE_CURRENT, generateDraw, generateWinComb, MOCK_SEQ,
} from "@app/core/mocks/utils";

/**
 * Фейковая история тиражей
 */
const getDrawsHistory: Array<IDraw> = [];

// До текущего тиража еще 5 прошедших тиражей (всего - 29)
for (let drawIndex = 0; drawIndex < 29; drawIndex += 1) {
  /**
   * Тираж для истории тиражей
   */
  const historyItem = generateDraw(drawIndex - DRAWS_BEFORE_CURRENT);

  if (drawIndex < 5) {
    /**
     * Выигрышная комбинация
     */
    historyItem.comb = generateWinComb();
  }

  getDrawsHistory.unshift(historyItem);
}

// console.log('getDrawsHistory =', getDrawsHistory);

export const getDraws = [
  {
    request: {
      action: ApiAction.getDraws,
      lottCode: 30,
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getDraws,
      R: ResponseCode.Ok,
      lottCode: 30,
      history: getDrawsHistory,
      seq: MOCK_SEQ
    }
  }
];
