<app-caption class="stats-title"
             data-cy="stats-title"
             [title]="'stats.statistics' | translate"
             [cornerColor]="'#FFFFFF'"
             [corners]="true"
             [cornersTablet]="true"
>
  <button type="button" data-cy="stats-to-reg-bets" class="stats__to-reg-bets" routerLink="/">
    <span class="stats__back-to" data-cy="stats-back-to">
      {{ 'pik4.back-to-bets' | translate }}
    </span>
  </button>
</app-caption>

<div class="stats" data-cy="stats">

  <div class="stats__pills" data-cy="stats-pills">
    <div class="stats__pill stats__pill_1" data-cy="stats-pill">
      <input id="section2" type="radio" class="stats__table" data-cy="stats-table" name="table" [value]="2" [(ngModel)]="table" />
      <label for="section2" class="stats__section" data-cy="stats-section">{{ 'stats.color-and-count' | translate }}</label>
    </div>
    <div class="stats__pill stats__pill_2" data-cy="stats-pill">
      <input id="section3" type="radio" class="stats__table" data-cy="stats-table" name="table" [value]="3" [(ngModel)]="table"/>
      <label for="section3" class="stats__section" data-cy="stats-section">{{ 'stats.color-and-position' | translate }}</label>
    </div>
    <div class="stats__pill stats__pill_3" data-cy="stats-pill">
      <input id="section1" type="radio" class="stats__table" data-cy="stats-table" name="table" [value]="1" [(ngModel)]="table" />
      <label for="section1" class="stats__section" data-cy="stats-section">{{ 'stats.numbers' | translate }}</label>
    </div>
  </div>

  <div class="stats__top" data-cy="stats-top">
    <div class="stats__legend stats__legend_start" data-cy="stats-legend">
      <div class="stats__legend-text" data-cy="stats-legend-text">{{ 'stats.legend-1' | translate }}</div>
    </div>
    <div class="stats__legend stats__legend_last" data-cy="stats-legend">
      <div class="stats__legend-text" data-cy="stats-legend-text">{{ 'stats.legend-2' | translate }}</div>
    </div>
  </div>

  <div class="stats__note" data-cy="stats-note">
    {{ 'stats.note' | translate }}
    {{ config.startDates[30] | date:'d MMMM YYYY':undefined:translateService.currentLang === 'uk' ? 'uk-UA' : 'ru-RU' }}
    {{ 'stats.of-year' | translate }}
  </div>

  <div class="stats__spoilers" data-cy="stats-spoilers">
    <div class="stats__spoiler stats__spoiler_2" data-cy="stats-spoiler">
      <app-caption class="stats__header expanded"
                   data-cy="stats-header"
                   [title]="'stats.color-and-count' | translate"
                   [corners]="true"
                   [cornersTablet]="true"
                   [spoiler]="true"
      ></app-caption>
      <div class="stats__tab" data-cy="stats-tab" [class.stats__tab_mobile-visible]="table === 2">
        <div class="stats__inner" data-cy="stats-inner">

          <h3 class="stats__special" data-cy="stats-special" [innerHTML]="'stats.patriotic' | translate"></h3>

          <div class="patriotic" data-cy="patriotic">
            <div class="patriotic__left" data-cy="patriotic-left">
              <div data-cy="patriotic-value" class="patriotic__value patriotic__value_s">{{ specialItem.s }}</div>
              <div data-cy="patriotic-bar" class="patriotic__bar patriotic__bar_left"
                   [style.width]="isMobile ? ((specialItem.sp / 163) * 100) + '%' : specialItem.sp + 'px'"
              ></div>
            </div>
            <div class="patriotic__square" data-cy="patriotic-square">
              <div class="patriotic__b" data-cy="patriotic-b"></div>
              <div class="patriotic__y" data-cy="patriotic-y"></div>
              <div class="patriotic__inner" data-cy="patriotic-inner"></div>
            </div>
            <div class="patriotic__right" data-cy="patriotic-right">
              <div class="patriotic__bar patriotic__bar_right"
                   data-cy="patriotic-bar"
                   [style.width]="isMobile ? ((specialItem.lp / 163) * 100) + '%' : specialItem.lp + 'px'"
              ></div>
              <div class="patriotic__value patriotic__value_l"
                   data-cy="patriotic-value"
              >{{ specialItem.l }}</div>
            </div>
          </div>

          <div class="stats__inner-top"
                data-cy="stats-inner-top"
          >

            <app-sorting-menu class="stats__sorting-menu"
                              data-cy="stats-sorting-menu"
                              [items]="sortingItems2"
                              [(ngModel)]="sortingItem2"
                              (ngModelChange)="filterStats()"
            ></app-sorting-menu>

            <div class="stats__filters filters"
                 data-cy="stats-filters"
            >
              <input class="filters__control"
                     data-cy="filters-control"
                     type="radio"
                     id="c0"
                     name="quantity"
                     [value]="0"
                     [(ngModel)]="filters2"
                     (ngModelChange)="filterStats()"
              />
              <label for="c0" class="filters__btn" data-cy="filters-btn">{{ 'stats.all' | translate }}</label>

              <ng-container *ngFor="let num of [1, 2, 3, 4]">
                <input class="filters__control"
                       data-cy="filters-control"
                       type="radio"
                       id="c{{ 5 - num }}"
                       name="quantity"
                       [value]="5 - num"
                       [(ngModel)]="filters2"
                       (ngModelChange)="filterStats()"
                />
                <label for="c{{ 5 - num }}" class="filters__btn" data-cy="filters-btn">
                  <ng-container *ngFor="let count of [1, 2, 3, 4]">
                    <span *ngIf="count <= 5 - num" class="filters__ball" data-cy="filters-ball"></span>
                  </ng-container>
                </label>
              </ng-container>

            </div>
          </div>

          <div class="stats__items stats-items" data-cy="stats-items">

            <ng-container *ngFor="let statsItem of statsSlice[2]">
              <div class="stats-items__left" data-cy="stats-items-left">
                <div class="stats-items__inner stats-items__inner_s"
                     data-cy="stats-items-inner"
                     [class.stats-items__inner_s_grayed]="statsItem.grayed"
                >{{ statsItem.s }}</div>
                <div class="stats-items__value stats-items__value_s"
                     data-cy="stats-items-value"
                >
                  <div class="stats-items__bar stats-items__bar_left"
                       [class.stats-items__bar_left_grayed]="statsItem.grayed"
                       [style.width.%]="isMobile ? (statsItem.sp / 115) * 100 : 100"
                       [style.height]="isMobile ? '100%' : statsItem.sp + 'px'"
                       data-cy="stats-items-bar"
                  ></div>
                </div>
              </div>
              <div class="stats-items__square stats-items__square_color stats-items__square_{{ statsItem.itemCode | color }}"
                   [class.stats-items__square_grayed]="statsItem.grayed"
                   data-cy="stats-items-square"
              >
                <div *ngFor="let i of (statsItem.itemCode | balls)" class="stats-items__ball"
                     data-cy="stats-items-ball"
                ></div>
              </div>
              <div class="stats-items__right"
                    data-cy="stats-items-right"
              >
                <div class="stats-items__value stats-items__value_l" data-cy="stats-items-value">
                  <div class="stats-items__bar stats-items__bar_right"
                        data-cy="stats-items-bar"
                       [class.stats-items__bar_right_grayed]="statsItem.grayed"
                       [style.width.%]="isMobile ? (statsItem.lp / 115) * 100 : 100"
                       [style.height]="isMobile ? '100%' : statsItem.lp + 'px'"
                  >
                  </div>
                </div>
                <div class="stats-items__inner stats-items__inner_l"
                      data-cy="stats-items-inner"
                     [class.stats-items__inner_l_grayed]="statsItem.grayed"
                >{{ statsItem.l }}</div>
              </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>

    <div class="stats__spoiler" data-cy="stats-spoiler">
      <app-caption class="stats__header expanded"
                   [title]="'stats.color-and-position' | translate"
                   [corners]="true"
                   [spoiler]="true"
                   [cornersTablet]="true"
                   data-cy="stats-header"
      ></app-caption>
      <div class="stats__tab"
           [class.stats__tab_mobile-visible]="table === 3"
            data-cy="stats-tab"
      >
        <div class="stats__inner"
              data-cy="stats-inner"
        >
          <div class="stats__inner-top"
                data-cy="stats-inner-top"
          >
            <app-sorting-menu class="stats__sorting-menu"
                              [items]="sortingItems3"
                              [(ngModel)]="sortingItem3"
                              (ngModelChange)="filterStats()"
                              data-cy="stats-sorting-menu"
            ></app-sorting-menu>

            <div class="stats__filters filters"
                  data-cy="stats-filters"
            >
              <input class="filters__control"
                     type="radio"
                     id="cp0"
                     name="position"
                     [value]="0"
                     [(ngModel)]="filters3"
                     (ngModelChange)="filterStats()"
                     data-cy="filters-control"
              />
              <label for="cp0" class="filters__btn"
                      data-cy="filters-btn"
              >{{ 'stats.all' | translate }}</label>

              <ng-container *ngFor="let num of [1, 2, 3, 4]">
                <input class="filters__control"
                       type="radio"
                       id="cp{{ num }}"
                       name="position"
                       [value]="num"
                       [(ngModel)]="filters3"
                       (ngModelChange)="filterStats()"
                        data-cy="filters-control"
                />
                <label for="cp{{ num }}"
                       class="filters__btn"
                        data-cy="filters-btn"
                >
                  <span *ngFor="let pos of [1, 2, 3, 4]" class="filters__ball"
                        [class.filters__ball_active]="num === pos"
                          data-cy="filters-ball"
                  ></span>
                </label>
              </ng-container>

            </div>
          </div>

          <div class="stats__items stats-items"
                data-cy="stats-items"
          >
            <ng-container *ngFor="let statsItem of statsSlice[3]">
              <div class="stats-items__left"
                    data-cy="stats-items-left"
              >
                <div class="stats-items__inner stats-items__inner_s"
                     [class.stats-items__inner_s_grayed]="statsItem.grayed"
                      data-cy="stats-items-inner"
                >
                  {{ statsItem.s }}
                </div>
                <div class="stats-items__value stats-items__value_s"
                        data-cy="stats-items-value"
                >
                  <div class="stats-items__bar stats-items__bar_left"
                       [class.stats-items__bar_left_grayed]="statsItem.grayed"
                       [style.width.%]="isMobile ? (statsItem.sp / 115) * 100 : 100"
                       [style.height]="isMobile ? '100%' : statsItem.sp + 'px'"
                        data-cy="stats-items-bar"
                  >
                  </div>
                </div>

              </div>
              <div class="stats-items__square stats-items__square_color stats-items__square_{{ statsItem.itemCode | color }}"
                   [class.stats-items__square_grayed]="statsItem.grayed"
                    data-cy="stats-items-square"
              >
                <div *ngFor="let i of (statsItem.itemCode | balls)"
                     class="stats-items__ball"
                     [class.stats-items__ball_active]="i"
                      data-cy="stats-items-ball"
                ></div>
              </div>
              <div class="stats-items__right"
                    data-cy="stats-items-right"
              >
                <div class="stats-items__value stats-items__value_l"
                        data-cy="stats-items-value"
                >
                  <div class="stats-items__bar stats-items__bar_right"
                       [class.stats-items__bar_right_grayed]="statsItem.grayed"
                       [style.width.%]="isMobile ? (statsItem.lp / 115) * 100 : 100"
                       [style.height]="isMobile ? '100%' : statsItem.lp + 'px'"
                        data-cy="stats-items-bar"
                  >
                  </div>
                </div>
                <div class="stats-items__inner stats-items__inner_l"
                     [class.stats-items__inner_l_grayed]="statsItem.grayed"
                      data-cy="stats-items-inner"
                >
                  {{ statsItem.l }}
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <div class="stats__spoiler" data-cy="stats-spoiler">
      <app-caption class="stats__header expanded"
                   [title]="'stats.numbers' | translate"
                   [corners]="true"
                   [cornersTablet]="true"
                   [spoiler]="true"
                   data-cy="stats-header"
      ></app-caption>
      <div class="stats__tab"
           [class.stats__tab_mobile-visible]="table === 1"
            data-cy="stats-tab"
      >
        <div class="stats__inner"
              data-cy="stats-inner"
        >

          <div class="stats__inner-top"
                data-cy="stats-inner-top"
          >
            <app-sorting-menu class="stats__sorting-menu"
                              [items]="sortingItems1"
                              [(ngModel)]="sortingItem1"
                              (ngModelChange)="filterStats()"
                              data-cy="stats-sorting-menu"
            ></app-sorting-menu>
            <div class="stats__filters filters"
                  data-cy="stats-filters"
            >
              <input class="filters__control"
                     type="radio"
                     id="p0"
                     name="pos"
                     [value]="0"
                     [(ngModel)]="filters1"
                     (ngModelChange)="filterStats()"
                      data-cy="filters-control"
              />
              <label for="p0" class="filters__btn"
                      data-cy="filters-btn"
              >{{ 'stats.all' | translate }}</label>

              <ng-container *ngFor="let num of [1, 2, 3, 4]">
                <input class="filters__control"
                       type="radio"
                       id="p{{ num }}"
                       name="pos"
                       [value]="num"
                       [(ngModel)]="filters1"
                       (ngModelChange)="filterStats()"
                        data-cy="filters-control"
                />
                <label for="p{{ num }}" class="filters__btn"
                        data-cy="filters-btn"
                >
                  <span *ngFor="let pos of [1, 2, 3, 4]" class="filters__ball"
                        [class.filters__ball_active]="num === pos"
                        data-cy="filters-ball"
                  ></span>
                </label>
              </ng-container>

            </div>
          </div>

          <div class="stats__items stats-items"
                data-cy="stats-items"
          >
            <ng-container *ngFor="let statsItem of statsSlice[1]">
              <div class="stats-items__left stats-items__left_numbers"
                    data-cy="stats-items-left"
              >
                <div class="stats-items__inner stats-items__inner_s"
                     [class.stats-items__inner_s_grayed]="statsItem.grayed"
                      data-cy="stats-items-inner"
                >
                  {{ statsItem.s }}
                </div>
                <div class="stats-items__value stats-items__value_s"
                        data-cy="stats-items-value"
                >
                  <div class="stats-items__bar stats-items__bar_left"
                       [class.stats-items__bar_left_grayed]="statsItem.grayed"
                       [style.width.%]="isMobile ? (statsItem.sp / 115) * 100 : 100"
                       [style.height]="isMobile ? '100%' : statsItem.sp + 'px'"
                        data-cy="stats-items-bar"
                  >
                  </div>
                </div>
              </div>
              <div class="stats-items__circle stats-items__circle_{{ ballsColors[+statsItem.itemCode - 1] }}"
                   [class.stats-items__circle_grayed]="statsItem.grayed"
                    data-cy="stats-items-circle"
              >{{ statsItem.itemCode }}</div>
              <div class="stats-items__right stats-items__right_numbers"
                    data-cy="stats-items-right"
              >
                <div class="stats-items__value stats-items__value_l"
                     data-cy="stats-items-value"
                >
                  <div class="stats-items__bar stats-items__bar_right"
                       [class.stats-items__bar_right_grayed]="statsItem.grayed"
                       [style.width.%]="isMobile ? (statsItem.lp / 115) * 100 : 100"
                       [style.height]="isMobile ? '100%' : statsItem.lp + 'px'"
                        data-cy="stats-items-bar"
                  >
                  </div>
                </div>
                <div class="stats-items__inner stats-items__inner_l"
                     [class.stats-items__inner_l_grayed]="statsItem.grayed"
                      data-cy="stats-items-inner"
                >
                  {{ statsItem.l }}
                </div>
              </div>
            </ng-container>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="periods"
        data-cy="periods"
  >
    <input id="today"
           type="radio"
           class="periods__control"
           [(ngModel)]="period"
           [value]="5"
           (ngModelChange)="changePeriod($event)"
           data-cy="periods-control"
    >
    <label for="today" class="periods__button periods__button_active"
            data-cy="periods-button"
    >{{ 'stats.today' | translate }}</label>

    <input id="yesterday"
           type="radio"
           class="periods__control"
           [(ngModel)]="period"
           [value]="4"
           (ngModelChange)="changePeriod($event)"
           data-cy="periods-control"
    >
    <label for="yesterday" class="periods__button"
            data-cy="periods-button"
    >{{ 'stats.yesterday' | translate }}</label>

    <input id="week"
           type="radio"
           class="periods__control"
           [(ngModel)]="period"
           [value]="3"
           (ngModelChange)="changePeriod($event)"
            data-cy="periods-control"
    >
    <label for="week" class="periods__button"
            data-cy="periods-button"
    >{{ 'stats.week' | translate }}</label>

    <input id="month"
           type="radio"
           class="periods__control"
           [(ngModel)]="period"
           [value]="2"
           (ngModelChange)="changePeriod($event)"
            data-cy="periods-control"
    >
    <label for="month" class="periods__button"
            data-cy="periods-button"
    >{{ 'stats.month' | translate }}</label>

    <input id="alltime"
           type="radio"
           class="periods__control"
           [(ngModel)]="period"
           [value]="1"
           (ngModelChange)="changePeriod($event)"
            data-cy="periods-control"
    >
    <label for="alltime" class="periods__button"
            data-cy="periods-button"
    >{{ 'stats.all-time' | translate }}</label>
  </div>
</div>
