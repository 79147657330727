import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп, вычисляющий время до какой-либо даты.
 * Возвращает значение в формате 05:10
 */
@Pipe({
  name: 'timeToEnd'
})
export class TimeToEndPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: Date | string | undefined | null): string {
    if (value) {
      const endDrawTS = typeof value === 'string' ? (new Date(value)).getTime() : value.getTime();
      const msDiff = endDrawTS - Date.now();
      if (msDiff > 0) {
        const minutes = Math.floor(msDiff / (60 * 1000));
        const seconds = Math.floor((msDiff - minutes * 60 * 1000) / 1000);
        const sMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const sSeconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${sMinutes}:${sSeconds}`;
      }
    }
    return '';
  }
}
