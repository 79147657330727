import { Inject, Injectable } from '@angular/core';
import { ResponseCode } from '@app/core/enums/response-code';
import { SiteService } from '@app/core/services/site.service';
import { MobileService } from '@app/core/services/mobile.service';
import { filter, take } from 'rxjs/operators';
import { IErrorsDialog } from '@app/core/interfaces/i-errors-dialog';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG, inIframe } from '@app/core/utils';
import { IConfig } from '@app/core/interfaces/i-config';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';

/**
 * Сервис обработки ошибок
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
  /**
   * Объект с переводами
   * @private
   */
  private translations: any = {};

  /**
   * Показан ли экран ошибки
   */
  showErrorScreen = false;

  /**
   * Конструктор сервиса
   * @param errorsDialogAction$ Наблюдаемая переменная действий при ошибках
   * @param closeErrorsDialog$ Наблюдаемая переменная действий при закрытии окна
   * @param store NgRx-хранилище приложения
   * @param translateService Сервис переводов
   * @param config Объект конфигурации приложения
   */
  constructor(
              private readonly errorsDialogAction$: Actions,
              private readonly closeErrorsDialog$: Actions,
              private readonly store: Store,
              private readonly translateService: TranslateService,
              @Inject(APP_CONFIG) private readonly config: IConfig
  ) {
    errorsDialogAction$.pipe(
      ofType('[Errors Dialog] Errors dialog action'),
      filter((v: {data: IErrorsDialog}) => !!v.data?.errorCode)
    ).subscribe(this.onErrorsDialogAction.bind(this));
    closeErrorsDialog$.pipe(
      ofType('[Errors Dialog] Close errors dialog')
    ).subscribe((v: {data: IErrorsDialog}) => {
      switch (v.data.errorCode) {
        case ResponseCode.SessionExpired:
        case ResponseCode.SessionExpired2:
          if (inIframe()) {
            // window.parent.document.location.reload();
            SiteService.updateBalance();
          } else {
            document.location.reload();
          }
          break;
        default:
      }
    });
  }

  /**
   * Метод по обработке ошибок
   * @param error Ошибка
   */
  handleError(error: Error): void {
    if (error?.message) {
      this.translateService.get('errors')
        .pipe(
          take(1)
        )
        .subscribe((v) => {
          this.translations = v;
          const isSessionExpired = [
            ResponseCode.SessionExpired,
            ResponseCode.SessionExpired2
          ].includes(+error.message);
          const content = isSessionExpired || [
            ResponseCode.LimitsExceeded,
            ResponseCode.LimitsExceeded2
          ].includes(+error.message)
            ? this.translateService.instant(`errors.${error.message}.description`, { href: this.config.cabinetURL })
            : (this.translations[+error.message]?.description || `${this.translations.unknown} ${error.message}`);
          if (isSessionExpired && environment.isMobile) {
            MobileService.refreshAuthToken();
          } else {
            this.store.dispatch({
              type: '[Errors Dialog] Open errors dialog',
              data: {
                lottCode: 30, // TODO: подставить параметр
                errorCode: +error.message,
                title: this.translations[+error.message]?.title || `${this.translations.error}`,
                content,
                buttonText: this.translations[+error.message]?.buttonText
                  || this.translations.continue,
                isShown: true
              }
            });
          }
        });
    }
  }

  /**
   * Обработчик действия при нажатии на главную кнопку в диалоговом окне
   * @param v Данные диалогового окна
   * @private
   */
  private onErrorsDialogAction(v: {data: IErrorsDialog}): void {
    switch (v.data.errorCode) {
      case ResponseCode.TokenIsNotProvided:
      case ResponseCode.SessionByTokenNotFound:
      case ResponseCode.SessionExpired:
      case ResponseCode.SessionExpired2:
        if (environment.isMobile) {
          MobileService.refreshAuthToken();
        } else {
          SiteService.showLoginDialog();
        }
        break;
      case ResponseCode.NotEnoughMoney:
      case ResponseCode.CannotPayWithBonuses:
        window.open(this.config.fillBalanceURL, '_blank');
        break;
      case ResponseCode.LimitsExceeded:
      case ResponseCode.LimitsExceeded2:
        window.open(this.config.cabinetURL, '_blank');
        break;
      default:
    }
  }
}
