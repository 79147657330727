import { createReducer, on } from '@ngrx/store';
import { ITicket } from '@app/core/interfaces/i-ticket';
import {
  getTicketsList,
  getTicketsListError,
  getTicketsListSuccess,
  regBet,
  regBetError,
  regBetSuccess
} from '../actions/bets.actions';

/**
 * Начальное состояние для хранилища списка билетов
 */
export const initialState: Array<ITicket> = [];

/**
 * Редуктор для хранилища списка билетов
 */
export const betsReducer = createReducer(
  initialState,
  on(regBet, (state) => state),
  on(regBetSuccess, (state, { tickets }) => [...state, ...tickets]),
  on(regBetError, (state) => state),
  on(getTicketsList, (state) => state),
  on(getTicketsListSuccess, (state, { tickets }) => {
    const ticketsObj: {[mac_code: string]: ITicket} = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const ticket of state) {
      ticketsObj[ticket.mac_code] = ticket;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const ticket of tickets) {
      ticketsObj[ticket.mac_code] = ticket;
    }
    return Object.keys(ticketsObj).map((macCode: string) => ticketsObj[macCode]);
  }),
  on(getTicketsListError, (state) => state)
);
