import { createAction, props } from '@ngrx/store';
import { IArchiveDraw } from '@app/core/interfaces/i-archive-draw';

/**
 * Действие для запроса получения архива тиражей по коду лотереи и дате
 */
export const getDrawsArchive = createAction('[Draws Archive] Get Draws Archive', props<{ lottCode: number; dateStart: string; }>());

/**
 * Действие при успешном получении тиражей
 */
export const getDrawsArchiveSuccess = createAction('[Draws Archive] Get Draws Archive Success', props<{
  lottCode: number;
  dateStart: string;
  draws: Array<IArchiveDraw> }>());

/**
 * Действие при ошибке получения тиражей
 */
export const getDrawsArchiveError = createAction('[Draws Archive] Get Draws Archive Error');
