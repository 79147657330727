import { Injectable } from '@angular/core';
import {
  Actions, createEffect, ofType
} from '@ngrx/effects';
import { ApiService } from '@app/core/services/api.service';
import {
  catchError,
  map, mergeMap
} from 'rxjs/operators';
import { ErrorsService } from '@app/core/services/errors.service';
import { of } from 'rxjs';
import { IExtraDrawsResponse } from '@app/core/interfaces/i-extra-draws-response';

/**
 * Эффекты для работы с текущим тиражом по указанным лотереям
 */
@Injectable()
export class DrawsExtraEffects {
  /**
   * Наблюдаемая переменная в результате создания эффекта получения текущих тиражей
   * Сама переменная не используется
   */
  loadDrawsExtra$ = createEffect(() => this.actions$.pipe(
    ofType('[Draws Extra] Get Extra Draws'),
    mergeMap((action: {extra: Array<number>}) => this.apiService.getDrawsExtra(action.extra)
      .pipe(
        map((drawsExtraResponse: IExtraDrawsResponse) => ({
          type: '[Draws Extra] Get Extra Draws Success',
          extra: drawsExtraResponse.extra
        })),
        catchError((error: Error) => {
          this.errorsService.handleError(error);
          return of({ type: '[Draws Extra] Get Extra Draws Error' });
        })
      ))
  ));

  /**
   * Конструктор эффекта
   * @param actions$ Наблюдаемая переменная действий
   * @param apiService Экземпляр API-сервиса
   * @param errorsService Экземпляр сервиса обработки ошибок
   */
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly errorsService: ErrorsService
    // eslint-disable-next-line no-empty-function
  ) { }
}
