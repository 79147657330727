import { Injectable } from '@angular/core';
import {
  Actions, createEffect, ofType
} from '@ngrx/effects';
import { ApiService } from '@app/core/services/api.service';
import {
  catchError,
  map, mergeMap
} from 'rxjs/operators';
import { IResponse } from '@app/core/interfaces/i-response';
import { ErrorsService } from '@app/core/services/errors.service';
import { combineLatest, interval, of } from 'rxjs';
import { IDraw } from '@app/core/interfaces/i-draw';

/**
 * Эффекты по работе с тиражами
 */
@Injectable()
export class DrawsEffects {
  /**
   * Наблюдаемая переменная в результате создания эффекта получения списка тиражей
   * Сама переменная не используется
   */
  loadDraws$ = createEffect(() => this.actions$.pipe(
    ofType('[Draws List] Get List Of Draws'),
    mergeMap((action: {lottCode: number}) => combineLatest([
      this.apiService.getDraws(action.lottCode),
      interval(500)
    ])
      .pipe(
        map(([drawsResponse]: [IResponse, number]) => ({
          type: '[Draws List] List Of Draws Loaded Success',
          draws: drawsResponse.history?.map((draw: IDraw) => ({
            ...draw,
            betData: {
              bet_sum: draw.betData.bet_sum / 100,
              max_bet: draw.betData.max_bet / 100,
              max_win: draw.betData.max_win / 100,
              min_bet: draw.betData.min_bet / 100
            }
          }))
        })),
        catchError((error: Error) => {
          this.errorsService.handleError(error);
          return of({ type: '[Draws List] Draws Loading Error' });
        })
      ))
  ));

  /**
   * Конструктор эффекта
   * @param actions$ Наблюдаемая переменная действий
   * @param apiService Экземпляр API-сервиса
   * @param errorsService Экземпляр сервиса обработки ошибок
   */
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly errorsService: ErrorsService
    // eslint-disable-next-line no-empty-function
  ) { }
}
