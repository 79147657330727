import { createAction, props } from '@ngrx/store';
import { IErrorsDialog } from '@app/core/interfaces/i-errors-dialog';

/**
 * Действие для открытия диалога ошибки
 */
export const openErrorsDialog = createAction('[Errors Dialog] Open errors dialog', props<{ data: IErrorsDialog }>());

/**
 * Действие диалогового окна (при нажатии на его кнопку)
 */
export const errorsDialogAction = createAction('[Errors Dialog] Errors dialog action', props<{ data: IErrorsDialog }>());

/**
 * Действие при закрытии диалогового окна (при нажатии на кнопку закрыть или на свободную область)
 */
export const closeErrorsDialog = createAction('[Errors Dialog] Close errors dialog');
