import { createReducer, on } from '@ngrx/store';
import { IArchiveDraws } from '@app/core/interfaces/i-archive-draws';
import { getDrawsArchive, getDrawsArchiveError, getDrawsArchiveSuccess } from '@app/store/actions/draws-archive';

/**
 * Начальное состояние хранилища архива тиражей
 */
export const initialState: IArchiveDraws = {};

/**
 * Редуктор для хранилища тиражей
 */
export const drawsArchiveReducer = createReducer(
  initialState,
  on(getDrawsArchive, (state, { lottCode, dateStart }) => {
    const newState = { ...state };
    if (!newState[lottCode]) {
      newState[lottCode] = {};
    }
    if (!newState[lottCode][dateStart]) {
      const newStateSlice = { ...newState[lottCode] };
      newStateSlice[dateStart] = [];
      newState[lottCode] = newStateSlice;
    }
    return newState;
  }),
  on(getDrawsArchiveSuccess, (state, { lottCode, dateStart, draws }) => {
    const newState = { ...state };
    if (draws) {
      const newStateSlice = { ...newState[lottCode] };
      newStateSlice[dateStart] = [...draws];
      newState[lottCode] = newStateSlice;
    }
    return newState;
  }),
  on(getDrawsArchiveError, (state) => state)
);
