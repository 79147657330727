import { Pipe, PipeTransform } from '@angular/core';

/**
 * Пайп для преобразования комбинации в массив ставок
 * с цветом и метрикой по отдельности
 */
@Pipe({
  name: 'comb'
})
export class CombPipe implements PipeTransform {
  /**
   * Функция преобразования
   * @param value Преобразуемое значение
   */
  // eslint-disable-next-line class-methods-use-this
  transform(value: string): Array<{color: string, num: number}> {
    return value.split(',').map((v: string) => ({
      color: v.substring(0, 1).toLowerCase(),
      num: +v.substring(1)
    }));
  }
}
