import { createAction, props } from '@ngrx/store';
import { IDrawsExtra } from '@app/features/pik4/interfaces/i-draws-extra';

/**
 * Действие для запроса получения текущих тиражей по указанным лотереям
 */
export const getDrawsExtra = createAction('[Draws Extra] Get Extra Draws', props<{ extra: Array<number> }>());

/**
 * Действие при успешном получении текущих тиражей
 */
export const getDrawsExtraSuccess = createAction('[Draws Extra] Get Extra Draws Success', props<{ extra: Array<IDrawsExtra> }>());

/**
 * Действие при ошибке получения текущих тиражей
 */
export const getDrawsExtraError = createAction('[Draws Extra] Get Extra Draws Error');
