import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError, map, mergeMap, switchMap
} from 'rxjs/operators';
import { IResponse } from '@app/core/interfaces/i-response';
import { ApiService } from '@app/core/services/api.service';
import { IBet } from '@app/core/interfaces/i-bet';
import { ErrorsService } from '@app/core/services/errors.service';
import { combineLatest, interval, of } from 'rxjs';
import { ITicket } from '@app/core/interfaces/i-ticket';

/**
 * Функция для перевода суммы ставки из копеек в гривны в каждом билете
 * @param tickets Билеты
 */
const fixSum = (tickets: Array<ITicket> | undefined): Array<ITicket> => (tickets || [])
  .map((ticket: ITicket) => ({
    ...ticket,
    bet: ticket.bet.map((bet: IBet) => ({
      ...bet,
      sum: (bet?.sum || 0) / 100
    }))
  }));

/**
 * Эффекты для работы со ставками
 */
@Injectable()
export class BetsEffects {
  /**
   * Наблюдаемая переменная в результате создания эффекта регистрации ставки
   * Сама переменная не используется
   */
  regBet$ = createEffect(() => this.actions$.pipe(
    ofType('[Bet Registration] Reg Bet'),
    switchMap((action: {lottCode: number, bet: IBet}) => this.apiService.regBet(
      action.lottCode,
      {
        ...action.bet,
        sum: (action.bet?.sum || 0) * 100
      }
    )
      .pipe(
        map((regBetResponse: IResponse) => ({
          type: '[Bet Registration] Reg Bet Success', tickets: fixSum(regBetResponse.tickets)
        })),
        catchError((error: Error) => {
          this.errorsService.handleError(error);
          return of({ type: '[Bet Registration] Reg Bet Error' });
        })
      ))
  ));

  /**
   * Наблюдаемая переменная в результате создания эффекта получения списка ставок
   * Сама переменная не используется
   */
  getTicketsList$ = createEffect(() => this.actions$.pipe(
    ofType('[Tickets List] Get List Of Tickets'),
    mergeMap((action: {lottCode: number}) => combineLatest([
      this.apiService.getTicketsList(action.lottCode),
      interval(500)
    ])
      .pipe(
        map(([ticketsResponse]: [IResponse, number]) => ({
          type: '[Tickets List] Get List Of Tickets Success',
          tickets: fixSum(ticketsResponse.tickets)
        })),
        catchError((error: Error) => {
          this.errorsService.handleError(error);
          return of({ type: '[Tickets List] Get List Of Tickets Error' });
        })
      ))
  ));

  /**
   * Конструктор эффекта
   * @param actions$ Наблюдаемая переменная действий
   * @param apiService Экземпляр API-сервиса
   * @param errorsService Экземпляр сервиса обработки ошибок
   */
  // eslint-disable-next-line no-empty-function,no-useless-constructor
  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly errorsService: ErrorsService
    // eslint-disable-next-line no-empty-function
  ) { }
}
