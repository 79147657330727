import { createReducer, on } from '@ngrx/store';
import { setState } from '@app/store/actions/translation.actions';
import { ITranslationState } from '@app/core/interfaces/i-translation-state';

/**
 * Начальное состояние хранилища данных о трансляции
 */
export const initialState: {[lottCode: number]: ITranslationState} = { };

/**
 * Редуктор хранилища данных о трансляции
 */
export const translationReducer = createReducer(
  initialState,
  on(setState, (state, { lottCode, isPreTranslation, isTranslation }) => {
    const newState = { ...state };
    if (!newState[lottCode]) {
      newState[lottCode] = {};
    }
    if (typeof isPreTranslation !== 'undefined') {
      newState[lottCode] = { ...newState[lottCode], isPreTranslation };
    }
    if (typeof isTranslation !== 'undefined') {
      newState[lottCode] = { ...newState[lottCode], isTranslation };
    }
    return newState;
  })
);
