import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { WebSocketService } from '@app/core/services/web-socket.service';
import { ApiEvent } from '@app/core/enums/api-event';
import { ResponseCode } from '@app/core/enums/response-code';
import { HttpService } from '@app/core/services/http.service';
import { IHelpResponse } from '@app/core/interfaces/i-help-response';
import { sha256 } from 'js-sha256';
import { APP_CONFIG } from '@app/core/utils';
import { IConfig } from '@app/core/interfaces/i-config';
import { IArchiveResponse } from '@app/core/interfaces/i-archive-response';
import { IExtraDrawsResponse } from '@app/core/interfaces/i-extra-draws-response';
import { HttpHeaders } from '@angular/common/http';
import { SiteService } from '@app/core/services/site.service';
import { ISocketMessage } from '../interfaces/i-socket-message';
import { IResponse } from '../interfaces/i-response';
import { IRequest } from '../interfaces/i-request';
import { IBet } from '../interfaces/i-bet';

/**
 * Генератор ID запроса, нужен для обратной маршрутизации ответов.
 */
const RequestSequenceId = () => {
  let cycleCounter = 0;

  return () => {
    cycleCounter += 1;
    if (cycleCounter > 99) {
      cycleCounter -= 99;
    }

    return Date.now() * 100 + cycleCounter;
  };
};

/**
 * ID запроса
 */
const getRequestSequenceId = RequestSequenceId();

/**
 * Функция подготовки запросов
 * @param params Параметры запроса
 */
const prepareRequest = (params: {
  [paramName: string | number]: any
}): ISocketMessage => {
  const seq = getRequestSequenceId();
  return {
    event: ApiEvent.Request,
    data: { ...params, seq } as IRequest
  };
};

/**
 * Сервис по обращению к серверному API или к mock-данным (в зависимости от окружения)
 */
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  /**
   * Токен для пользовательской сессии
   */
  token = '';

  /**
   * Длительность розыгрыша тиража
   */
  playingDuration = 20000;

  /**
   * Код терминала (в случае мобильного канала)
   */
  termCode: number | null = null;

  /**
   * Конструктор сервиса
   * @param httpService Сервис http-запросов
   * @param webSocketService Сервис для работы с web-сокетом
   * @param config Конфигурация приложения
   */
  // eslint-disable-next-line no-useless-constructor
  constructor(
              private readonly httpService: HttpService,
              private readonly webSocketService: WebSocketService,
              @Inject(APP_CONFIG) private readonly config: IConfig
              // eslint-disable-next-line no-empty-function
  ) { }

  /**
   * Метод для получения тиражей
   */
  getDraws(lottCode: number): Observable<IResponse> {
    const request = prepareRequest({ action: 'getDraws', lottCode });
    return this.webSocketService.multiplex(
      () => request,
      () => { },
      (message) => message.data.action === 'getDraws'
    ).pipe(map((v) => v.data)) as Observable<IResponse>;
  }

  /**
   * Метод для получения архива тиражей
   */
  getDrawsArchive(lottCode: number, dateStart: string): Observable<IArchiveResponse> {
    return this.makeRequest({ action: 'getDrawsArchive', lottCode, dateStart }) as Observable<IArchiveResponse>;
  }

  /**
   * Метод для получения дат для счетчиков
   */
  getDrawsExtra(extra: Array<number>): Observable<IExtraDrawsResponse> {
    return this.makeRequest({ action: 'getDrawsExtra', extra }) as Observable<IExtraDrawsResponse>;
  }

  /**
   * Метод для получения коэффициентов по игре
   *
   * @param lottCode {number} - код игры
   */
  getLottData(lottCode: number): Observable<IResponse> {
    const request = prepareRequest({ action: 'getLottData', lottCode });
    return this.webSocketService.multiplex(
      () => request,
      () => { },
      (message) => message.data.action === 'getLottData'
    ).pipe(map((v) => v.data)) as Observable<IResponse>;
  }

  /**
   * Метод для регистрации ставок по игре
   *
   * @param lottCode {number} - код игры
   * @param bet {IBet} - ставка
   */
  regBet(lottCode: number, bet: IBet): Observable<IResponse> {
    const regBetRequest: {
      [paramName: string | number]: any
    } = {
      action: 'regBet', lottCode, bet, token: this.token
    };
    if (this.termCode) {
      regBetRequest.termCode = this.termCode;
    }
    return this.makeRequest(regBetRequest) as Observable<IResponse>;
  }

  /**
   * Метод для получения результатов по игре
   *
   * @param lottCode - код игры
   */
  getTicketsList(lottCode: number): Observable<IResponse> {
    const request = prepareRequest({ action: 'getTicketList', lottCode, token: this.token });
    return this.webSocketService.multiplex(
      () => request,
      () => { },
      (message) => message.data.action === 'getTicketList'
    ).pipe(
      map((v) => v.data),
      tap((v) => {
        if (v.R === ResponseCode.Ok) {
          SiteService.updateBalance();
        }
      })
    ) as Observable<IResponse>;
  }

  /**
   * Метод для получения справочной информации
   * @param alias Алиас материала на сайте
   * @param lang Язык материала
   */
  getPageInfo(alias: string, lang: string): Observable<IHelpResponse> {
    const sign = sha256(`sign::${this.config.salt}`);
    const body = new URLSearchParams();
    body.set('sign', sign);
    body.set('alias', alias);
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    // @ts-ignore
    return this.httpService.post(this.config.helpURLs[lang], body.toString(), options);
  }

  /**
   * Метод для получения статистике по игре
   * @param lottCode Код игры
   * @param type Тип статистики
   */
  getStat(lottCode: number, type: number): Observable<IResponse> {
    return this.makeRequest({
      action: 'getStat', lottCode, type
    }) as Observable<IResponse>;
  }

  /**
   * Метод для отправки запроса на веб-сокет или Mock-веб-сокет (в зависимости от окружения)
   * @param params - объект с передаваемыми параметрами
   * @private
   */
  private makeRequest(params: {
    [paramName: string | number]: any
  }): Observable<IResponse | IArchiveResponse | IExtraDrawsResponse> {
    const packet = prepareRequest(params);
    this.webSocketService.send(packet);

    return this.webSocketService.socket$$
      .pipe(
        filter((message: ISocketMessage) => this.config.mocks
          || message?.data.seq === packet.data.seq || message?.data.action === params.action),
        map((message: ISocketMessage) => {
          const responseData = message?.data as IResponse;
          if (responseData.R !== ResponseCode.Ok) {
            throw new Error(responseData.R.toString());
          }
          return responseData;
        })
      );
  }
}
