/**
 * Коды ответов сервера
 *
 * {@link Ok} - операция успешна
 *
 * {@link Unknown} - неизвестная ошибка
 */
export enum ResponseCode {
  /**
   *  Успех
   */
  Ok = 0,

  /**
   * Внутренняя ошибка сервера
   */
  InternalServerError = 500,
  /**
   *  Неопределенная ошибка, что-то в коде пошло не так
   */
  Unknown = 1000,
  /** Ошибка проверки параметров(например в запросе) */
  RequestParamValidation = 1001,
  /** Ответ сайта не может быть понят(не соответствует шаблону) или отсутствует */
  ApiHasErrorOrMalformed = 1002,
  /** Невозможно дешифровать сообщение */
  UnableToDecode = 1003,
  /** Сетевая ошибка или превышен интервал ожидания ответа сетевой операции */
  NetworkErrorOrTimeout = 1004,
  /** Нарушена очередность команд
  * (команды действий должны дожидаться выполнения предыдущей команды)
  */
  ActionsOrderViolations = 1005,
  /** код или действие лотереи не обрабатывается сервисом */
  LotteryRegistryError = 1006,
  /** действие отсутствует в списке доступных действий */
  ActionNotValid = 1007,
  /** токен отсутсвует в запросе (если он требуется в запросе) */
  TokenIsNotProvided = 1008,
  /** сайт отвечает что авторизационный токен неверен */
  SessionByTokenNotFound = 1009,
  /** Недупустимое значение ставки для лоттереи */
  WrongBetAmount = 1010,
  /** Превышено максимальное количество тиражей */
  WrongMaxDrawsCount = 1011,
  /** Код ставки некорректный или принадлежит другому столу */
  WrongBetCode = 1012,
  /** Коэффициент указан неверно для данного стола */
  WrongCoef = 1013,

  ApiBRSRegBet = 1020,
  ApiBRSGetDrawInfo = 1021,

  /** Брс или Сайт недоступны - ECONNREFUSED */
  RemoteApiIsUnreachable = 1030,
  /** Ответ БРС не может быть понят(не соответствует шаблону) */
  ApiSiteHasErrorOrMalformed = 1031,
  /** Ошибка при запросе профиля пользователя, с сайта(по токену) */
  SaveTicketRequestFailed = 1032,
  /** Ответ БРС не может быть понят(не соответствует шаблону) или отсутствует */
  ApiBRSHasErrorOrMalformed = 1035,

  UserProfileSaveFailed = 1050,
  /** Ошибка при запросе профиля пользователя, с сайта(по токену) */
  UserProfileRequestFailed = 1051,

  /** Для переданного кода, лотерея не найдена */
  CantFindLotteryForGameCode = 1100,
  /** Параметр "Лоттерея" пустой */
  LotteryIsEmpty = 1101,
  /** Произошла ошибка при попытке сохранить тираж */
  CantSaveLottery = 1102,

  /** Тираж с таким кодом уже сохранен */
  DrawAlreadyExists = 1150,

  CantConvertXMLtoJSON = 1500,

  /** Внутренние ошибки сохранения/обновления в БД */
  /** Произошла ошибка при попытке сохранить тираж */
  CantSaveDraw = 1600,
  CantUpdateDraw = 1601,

  CantSaveCoef = 1602,
  CantUpdateCoef = 1603,

  CantInsertTransaction = 1604,
  CantUpdateTransaction = 1605,

  CantSaveTicketWithBets = 1606,

  CantDeleteQueue = 1610,
  /**
   * Сессия истекла
   */
  SessionExpired = 4313,
  /**
   * Недостаточно денег на счету
   */
  NotEnoughMoney = 4347,
  /**
   * Сессия истекла
   */
  SessionExpired2 = 4390,
  /**
   * Превышено лимиты
   */
  LimitsExceeded = 4377,
  /**
   * Превышено лимиты
   */
  LimitsExceeded2 = 4378,
  /**
   * Невозможно оплатить бонусами
   */
  CannotPayWithBonuses = 4393
}
