<ng-template appModals></ng-template>

<div *ngIf="((loadingStage$ | async) || 0) < 2" class="preloader">
  <img class="preloader__image" src="./assets/img/preloader.gif" alt="{{ 'loading' | translate }}">
</div>

<ng-container *ngIf="errorsService.showErrorScreen; else appContent">
  <div class="error">
    <h1 class="error__title">{{ 'errors.error-title' | translate }}</h1>
    <h2 class="error__subtitle">{{ 'errors.error-subtitle' | translate }}</h2>

    <svg class="error__pic" width="850" height="453" viewBox="0 0 850 453" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1219_1434)">
        <path d="M799.264 413.814C794.216 404.17 773.719 396.454 764.536 394.678C715.551 385.313 664.231 386.632 614.612 386.785C560.578 386.962 509.006 395.617 455.403 399.627C427.524 401.708 398.072 397.952 370.142 396.987C324.15 395.388 278.158 393.51 232.166 391.378C192.517 389.526 151.37 393.688 116.413 415.54C112.43 418.027 108.422 421.022 106.621 425.489C95.2563 453.508 236.834 449.98 255.277 450.462C348.326 452.873 441.426 454.142 534.475 451.629C603.653 449.751 675.774 449.878 744.394 440.107C759.564 437.95 780.213 437.163 793.328 428.23C800.583 423.281 801.649 418.331 799.264 413.814Z" fill="#E7F5FE"/>
        <path d="M739.041 31.3945C740.259 31.3945 741.502 31.4199 742.77 31.4708" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10"/>
        <path d="M750.254 32.0036C774.379 34.4655 803.831 44.6427 800.406 65.5301C794.927 98.8027 647.057 86.5443 611.72 86.8743C540.69 87.5595 468.924 87.8387 399.29 103.371C382.369 107.153 358.092 115.477 359.944 137.1C360.578 144.638 365.043 151.491 370.7 156.592C391.045 174.967 419.381 176.718 445.688 179.941C485.464 184.814 674.734 178.317 736.581 198.621C748.757 202.605 763.978 207.757 764.485 225.193C765.779 269.455 655.885 267.298 622.907 268.135C534.932 270.369 446.829 269.48 358.853 267.754C287.747 266.359 216.413 265.115 145.434 260.521C118.975 258.821 75.6977 258.288 55.5049 238.416C44.2162 227.299 42.7448 207.097 60.5784 196.768C73.1609 189.484 110.046 192.682 124.252 191.463C167.174 187.758 154.186 161.693 119.406 158.369" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10" stroke-dasharray="29.57 29.57"/>
        <path d="M115.652 158.089C114.434 158.039 113.191 158.013 111.923 158.013" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10"/>
        <path d="M741.553 63.7789L730.213 3.8577C729.528 0.27918 725.241 -1.21822 722.476 1.14209L676.281 40.9119C673.516 43.2976 674.378 47.7644 677.803 48.9572L735.337 69.1086C738.787 70.3268 742.237 67.3574 741.553 63.7789Z" fill="#F0FAFF"/>
        <path d="M732.826 62.8652C732.496 62.8652 732.166 62.8144 731.862 62.7129L684.551 46.1401C683.562 45.7847 682.826 44.9472 682.623 43.9067C682.42 42.8661 682.801 41.8255 683.587 41.1403L721.588 8.42602C722.374 7.74077 723.491 7.53773 724.48 7.86767C725.469 8.22298 726.205 9.06051 726.408 10.1011L735.718 59.3882C735.921 60.4288 735.54 61.4693 734.754 62.1546C734.221 62.6114 733.536 62.8652 732.826 62.8652ZM687.088 43.0691L731.786 58.7283L722.983 12.1568L687.088 43.0691Z" fill="#B5DDF7"/>
        <path d="M712.278 42.7392C711.39 42.4346 710.883 41.4702 711.162 40.5566L715.855 24.0852C716.312 22.5624 717.935 21.7249 719.432 22.2579C720.929 22.7909 721.69 24.4405 721.106 25.9125L714.511 41.724C714.155 42.6123 713.191 43.0691 712.278 42.7392Z" fill="#B5DDF7"/>
        <path d="M712.633 47.2314C712.303 48.1704 711.263 48.678 710.325 48.3481C709.386 48.0182 708.879 46.9776 709.209 46.0385C709.538 45.0995 710.578 44.5919 711.517 44.9218C712.456 45.2518 712.963 46.2923 712.633 47.2314Z" fill="#B5DDF7"/>
        <path d="M108.321 137.862L71.0807 119.309C68.8483 118.193 66.2608 119.918 66.413 122.406L68.9752 163.952C69.1274 166.439 71.9179 167.81 73.9726 166.439L108.65 143.445C110.756 142.075 110.553 138.979 108.321 137.862Z" fill="#F0FAFF"/>
        <path d="M104.236 142.227C104.11 142.405 103.932 142.557 103.754 142.684L75.2411 161.592C74.6576 161.998 73.8712 162.023 73.237 161.719C72.6028 161.389 72.1715 160.754 72.1208 160.044L69.9899 125.883C69.9392 125.172 70.2943 124.487 70.8778 124.081C71.4612 123.675 72.2476 123.649 72.8818 123.954L103.526 139.207C104.16 139.537 104.592 140.171 104.642 140.882C104.668 141.39 104.516 141.846 104.236 142.227ZM74.5815 158.952L101.547 141.085L72.6028 126.669L74.5815 158.952Z" fill="#B5DDF7"/>
        <path d="M84.8046 145.12C84.2719 145.476 83.5363 145.349 83.1557 144.816L76.1289 135.476C75.4947 134.588 75.723 133.37 76.6108 132.761C77.5241 132.151 78.7417 132.431 79.2998 133.37L85.1598 143.471C85.515 144.029 85.3627 144.765 84.8046 145.12Z" fill="#B5DDF7"/>
        <path d="M87.3922 146.77C87.7727 147.328 87.6205 148.115 87.037 148.471C86.4789 148.851 85.6925 148.699 85.3374 148.115C84.9569 147.557 85.1091 146.77 85.6925 146.415C86.2506 146.059 87.0117 146.212 87.3922 146.77Z" fill="#B5DDF7"/>
        <path d="M134.12 221.64C135.829 221.64 137.215 220.254 137.215 218.544C137.215 216.833 135.829 215.447 134.12 215.447C132.411 215.447 131.025 216.833 131.025 218.544C131.025 220.254 132.411 221.64 134.12 221.64Z" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10"/>
        <path d="M594.571 164.206C596.281 164.206 597.666 162.82 597.666 161.11C597.666 159.399 596.281 158.013 594.571 158.013C592.862 158.013 591.476 159.399 591.476 161.11C591.476 162.82 592.862 164.206 594.571 164.206Z" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10"/>
        <path d="M350.279 77.2554C351.988 77.2554 353.374 75.8692 353.374 74.1591C353.374 72.4491 351.988 71.0628 350.279 71.0628C348.57 71.0628 347.184 72.4491 347.184 74.1591C347.184 75.8692 348.57 77.2554 350.279 77.2554Z" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10"/>
        <path d="M346.55 313.26C348.259 313.26 349.645 311.874 349.645 310.164C349.645 308.454 348.259 307.067 346.55 307.067C344.841 307.067 343.455 308.454 343.455 310.164C343.455 311.874 344.841 313.26 346.55 313.26Z" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10"/>
        <path d="M418.848 127.431V134.461" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M422.349 130.933H415.322" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M544.8 37.7141V44.7442" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M548.3 41.2418H541.299" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M739.65 227.122V234.127" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M743.176 230.624H736.149" stroke="#D2E8F9" stroke-width="7.3656" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M481.837 427.823C481.837 427.823 468.772 433.407 468.772 436.199C468.772 437.798 486.733 435.513 487.823 434.422C489.548 432.696 487.113 428.077 487.113 428.077L481.837 427.823Z" fill="#26387A"/>
        <path d="M480.999 420.032L481.837 428.077C481.837 428.077 482.268 429.372 484.094 429.524C485.921 429.676 487.113 428.077 487.113 428.077L486.479 418.027" fill="#FFB288"/>
        <path d="M503.805 235.751C508.549 254.912 506.799 264.633 499.822 279.074C495.611 327.65 492.669 376.049 486.986 423.407L480.974 423.763C467.352 377.648 454.794 308.717 456.393 245.674" fill="#26387A"/>
        <path d="M500.863 242.324C502.588 246.766 503.044 251.791 502.892 256.511C502.664 263.77 499.696 270.369 497.793 277.246C495.231 286.434 495.053 295.596 494.343 305.012C493.252 319.757 492.136 334.503 491.02 349.248C489.193 373.283 487.341 397.317 485.49 421.377C485.439 422.088 485.388 422.798 485.312 423.509" stroke="#0061A6" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M533.917 429.702C533.917 429.702 520.852 435.285 520.852 438.077C520.852 439.676 538.813 437.392 539.904 436.3C541.629 434.574 539.193 429.955 539.193 429.955L533.917 429.702Z" fill="#26387A"/>
        <path d="M533.08 421.885L533.917 429.93C533.917 429.93 534.348 431.224 536.175 431.377C538.001 431.529 539.193 429.93 539.193 429.93L538.559 419.88" fill="#FFB288"/>
        <path d="M547.26 234.127C553.272 252.933 552.156 262.755 546.169 277.627C545.18 326.381 541.831 377.293 539.295 424.93H533.13C516.489 379.831 498.63 310.164 496.068 247.172" fill="#26387A"/>
        <path d="M544.267 241.918C544.267 241.918 551.243 258.212 539.447 281.13C538.077 302.753 537.392 424.93 537.392 424.93" stroke="#0061A6" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M527.042 247.096C528.234 248.948 530.112 250.243 532.141 251.08C534.17 251.892 536.352 252.248 538.508 252.527C541.096 252.857 543.683 253.06 546.271 252.933" stroke="#0061A6" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M527.905 250.141C530.137 252.171 532.978 253.39 535.895 254.075C538.813 254.76 541.832 254.887 544.8 254.912" stroke="#0061A6" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M475.951 143.293C455.175 179.459 432.826 212.224 419.559 251.765L415.601 249.659C416.616 212.935 428.513 159.561 444.115 126.314" fill="#FFB288"/>
        <path d="M465.804 105.528C465.804 105.528 459.361 107.33 451.826 112.355C444.723 117.101 439.32 124.131 435.667 131.847C432.014 139.562 430.01 147.988 428.716 156.414C428.716 156.414 437.417 160.754 447.387 161.998C457.357 163.241 467.96 161.998 467.96 161.998" fill="#D9F0FF"/>
        <path d="M450.304 126.898C448.097 166.008 449.772 206.234 450.761 244.304C450.761 244.304 464.384 247.933 497.514 247.933C530.517 247.933 552.131 237.857 552.131 237.857C545.738 198.747 540.665 150.78 524.987 118.599C524.987 118.599 507.331 99.5387 489.878 98.7519C486.377 98.5996 482.877 99.8686 479.528 101.442C477.067 102.584 465.779 105.351 465.779 105.351L450.304 126.898Z" fill="#D9F0FF"/>
        <path d="M450.127 130.375C449.264 137.887 449.264 161.11 449.264 161.11" stroke="#94C1F2" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M483 256.407C538.289 249.804 561.485 325.249 574.084 354.499C603.845 423.668 716.147 415.946 767.329 415.946C834.355 415.946 863.762 440.258 832.479 453" stroke="#B4E8FD" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M502.131 259.785C502.131 288.261 480.594 305.443 462.177 305.443H431.964V214.102H462.177C480.594 214.102 502.131 231.284 502.131 259.785Z" fill="#FDAE2B"/>
        <path d="M486.58 259.785C486.58 287.601 470.117 310.164 449.797 310.164H416.464V209.381H449.797C470.117 209.381 486.58 231.944 486.58 259.785Z" fill="#EF8417"/>
        <path opacity="0.5" d="M493.962 232.248C486.758 242.07 479.706 252.019 472.907 262.12C473.871 261.943 474.784 261.46 475.495 260.775C475.495 261.537 474.277 262.831 475.342 263.338C476.408 263.821 477.549 262.577 478.64 262.907C479.68 262.653 480.67 261.359 481.431 260.597C481.076 261.384 481.025 261.943 480.974 262.374C488.331 259.506 494.901 252.654 500.609 247.146C499.214 241.512 496.905 236.538 493.962 232.248Z" fill="#FDAE2B"/>
        <path d="M418.848 310.164C439.163 310.164 455.632 287.608 455.632 259.785C455.632 231.962 439.163 209.407 418.848 209.407C398.533 209.407 382.065 231.962 382.065 259.785C382.065 287.608 398.533 310.164 418.848 310.164Z" fill="#FFC840"/>
        <path d="M421.918 277.627C421.918 281.764 419.102 285.114 415.601 285.114C412.126 285.114 409.285 281.764 409.285 277.627C409.285 273.49 412.101 270.14 415.601 270.14C419.102 270.14 421.918 273.49 421.918 277.627Z" fill="#EF8417"/>
        <path d="M421.918 241.918C421.918 246.055 419.102 249.405 415.601 249.405C412.126 249.405 409.285 246.055 409.285 241.918C409.285 237.781 412.101 234.431 415.601 234.431C419.102 234.431 421.918 237.781 421.918 241.918Z" fill="#EF8417"/>
        <path d="M535.084 155.754C535.084 155.754 537.418 179.56 526.789 199.357C517.681 216.285 499.62 238.187 485.033 250.268L481.532 249.405C486.606 229.888 502.664 195.27 503.653 182.276C504.642 169.282 504.414 154.232 504.642 146.998" fill="#FFB288"/>
        <path d="M489.904 98.7519C489.904 98.7519 509.488 100.097 520.852 109.691C537.062 123.395 539.472 150.222 539.472 156.465C534.576 161.769 523.846 162.023 516.058 162.023C508.245 162.023 502.537 158.292 502.537 158.292L500.939 128.7" fill="#D9F0FF"/>
        <path d="M482.927 246.512C480.695 246.74 478.539 247.806 476.662 249.278C474.937 250.623 472.831 252.857 473.059 253.897C473.288 254.989 476.104 252.907 476.433 253.314C476.763 253.72 471.613 261.511 472.603 262.095C473.567 262.679 477.955 255.623 477.955 255.623C477.955 255.623 473.694 263.161 475.343 263.338C476.712 263.465 480.492 256.334 480.492 256.334C480.492 256.334 477.296 262.704 478.387 262.958C479.477 263.186 482.446 256.841 482.446 256.841C482.446 256.841 480.036 262.273 480.949 262.374C481.405 262.425 483.79 259.709 484.779 256.461C485.769 253.237 486.91 250.446 486.022 247.933" fill="#FFB288"/>
        <path d="M503.349 131.796V158.775" stroke="#94C1F2" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M449.34 73.8799C449.239 75.0474 449.188 76.2149 449.188 77.4077C449.214 79.9457 449.543 82.6105 450.989 84.6916C452.004 86.1129 453.501 87.1788 455.2 87.6357C456.393 87.9656 457.94 87.8133 458.473 86.6966C458.65 86.2905 458.676 85.8591 458.65 85.4023C458.625 81.0624 457.788 76.7224 456.164 72.6871" fill="#26387A"/>
        <path d="M478.869 76.3164C477.194 69.7684 471.918 60.581 463.318 62.4337C457.915 63.6012 452.943 67.2812 451.37 72.4333C449.467 78.5752 452.537 85.7322 456.241 90.7574C457.61 92.6101 459.209 94.3866 461.314 95.4526C466.286 98.0159 473.009 95.9094 476.383 91.6203C479.731 87.3311 480.213 81.4938 478.869 76.3164Z" fill="#FFB288"/>
        <path d="M466.819 93.4476L469.635 103.726L465.804 105.528C465.804 105.528 468.722 109.411 474.48 108.574C486.2 106.873 489.904 98.7519 489.904 98.7519L483.283 98.0667L475.951 85.5545" fill="#FFB288"/>
        <path d="M484.602 69.1593C483.79 64.6672 480.568 60.8348 476.662 58.4492C469.711 54.2361 459.843 53.8808 453.653 59.6928C450.127 62.9921 443.81 74.7428 451.37 77.0524C453.729 77.7884 456.164 76.3418 458.219 74.9713C460.299 73.5754 462.405 72.1795 464.485 70.7836C465.246 70.276 466.032 69.7684 466.946 69.7177C468.417 69.6669 469.66 70.9359 470.193 72.3318C470.726 73.7277 470.751 75.2251 471.106 76.6717C471.36 77.6869 471.791 78.7274 472.679 79.3112C473.567 79.8695 474.962 79.7172 475.393 78.7528C475.698 78.0929 475.495 77.3316 475.469 76.5955C475.444 75.8595 475.875 74.9713 476.585 75.022C476.839 75.022 477.093 75.1743 477.296 75.3266C479.122 76.7478 478.539 79.5903 479.148 81.8491C479.224 82.1537 478.615 86.6712 478.818 86.8997C484.652 85.5545 485.185 72.4079 484.602 69.1593Z" fill="#26387A"/>
        <path d="M475.977 75.6057C475.85 74.7682 476.636 74.0068 477.473 73.9307C478.31 73.8292 479.122 74.2606 479.756 74.7936C480.847 75.7073 481.634 77.0524 481.659 78.4736C481.684 79.5903 481.228 80.6817 480.619 81.6207C480.213 82.2552 479.655 82.8643 478.945 83.0927C478.006 83.3973 476.966 82.9658 476.306 82.2552" fill="#FFB288"/>
        <path d="M267.91 427.544C267.91 427.544 280.518 429.651 280.847 432.417C281.05 434.016 263.191 435.894 261.974 434.955C260.046 433.458 262.405 427.874 262.405 427.874L267.91 427.544Z" fill="#26387A"/>
        <path d="M266.895 419.804L267.935 427.773C267.935 427.773 267.681 429.118 265.88 429.499C264.079 429.879 262.684 428.458 262.684 428.458L261.213 418.509" fill="#FFB288"/>
        <path d="M219.812 238.213C217.555 257.806 217.428 267.247 226.205 280.673C230.365 299.631 236.733 319.072 241.553 338.487C239.472 361.684 254.008 398.612 261.821 425.006L267.478 424.042C275.063 376.557 277.879 303.743 271.055 239.989" fill="#26387A"/>
        <path d="M223.465 264.785C223.465 264.785 245.155 333.64 245.155 337.371C245.155 341.101 245.738 353.969 246.702 362.293C248.453 377.572 262.278 422.57 262.278 422.57" stroke="#0061A6" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M261.618 426.072L260.756 422.621L268.037 422.341L268.138 425.514L261.618 426.072Z" fill="#0061A6"/>
        <path d="M169.914 427.925C169.914 427.925 179.782 435.057 178.894 437.569C178.387 439.016 162.354 433.052 161.72 431.757C160.705 429.651 165.094 425.895 165.094 425.895L169.914 427.925Z" fill="#26387A"/>
        <path d="M172.324 420.92L169.838 428.153C169.838 428.153 169.051 429.194 167.352 428.762C165.652 428.331 165.069 426.529 165.069 426.529L168.011 417.418" fill="#FFB288"/>
        <path d="M177.448 234.127C171.436 252.933 172.552 262.755 178.539 277.627C178.894 295.444 179.072 313.463 179.275 331.483C166.591 363.232 164.587 391.734 166.185 421.961L172.298 424.22C172.298 424.22 208.701 360.72 215.398 335.619C223.085 306.839 227.448 276.054 228.615 247.146" fill="#26387A"/>
        <path d="M178.945 238.213C178.945 238.213 172.324 257.603 181 276.307C181 281.536 183.993 324.909 182.801 331.609C182.243 334.833 176.281 350.162 172.298 365.085C167.91 381.607 166.971 420.54 166.971 420.54" stroke="#0061A6" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M165.069 421.961L166.058 417.875L173.871 421.504L172.324 425.006L165.069 421.961Z" fill="#0061A6"/>
        <path d="M279.3 88.9554L274.404 84.7678C274.404 84.7678 274.404 77.4331 274.404 76.088C274.404 74.7428 272.577 66.6721 270.928 66.6721C269.28 66.6721 268.899 64.6418 268.087 65.4793C267.276 66.2914 268.772 75.4281 268.087 76.1133C267.402 76.7986 266.54 71.7734 265.931 72.4079C265.297 73.0424 265.246 76.6971 265.246 78.5752C265.246 80.4532 266.743 83.9302 268.24 85.4023C269.736 86.8997 276.306 94.0059 276.306 94.0059L279.3 88.9554Z" fill="#EF9467"/>
        <path d="M276.839 86.189L272.73 90.8589C272.73 90.8589 288.94 112.305 294.064 119.487C289.371 117.837 282.674 113.726 278.792 111.188C271.182 106.188 263.521 103.549 258.245 103.549C247.032 103.549 252.892 140.552 265.906 145.958C290.589 149.663 318.265 147.328 322.501 138.649C328.792 125.781 292.618 95.4526 276.839 86.189Z" fill="#D9F0FF"/>
        <path d="M294.064 119.487C294.064 119.487 297.159 128.116 302.638 130.933" stroke="#94C1F2" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M201.548 113.345C172.121 146.415 172.552 203.29 172.552 241.943C172.552 241.943 194.165 256.714 227.169 256.714C260.299 256.714 273.922 248.568 273.922 248.568C274.911 209.407 267.935 192.529 274.379 127.735C274.379 127.735 263.851 103.549 258.219 103.549C252.613 103.549 230.644 101.391 230.644 101.391L201.548 113.345Z" fill="#D9F0FF"/>
        <path d="M272.755 146.821C272.755 146.821 273.415 135.502 276.18 132.761" stroke="#94C1F2" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M240.386 76.5702C241.476 69.8953 246.22 60.3526 254.947 61.4439C260.426 62.1292 264.663 65.9108 267.732 70.3522C270.37 74.1591 269.381 77.7884 265.627 87.4072C264.434 89.3615 261.872 92.8892 259.868 94.1582C255.15 97.153 248.25 95.6556 244.521 91.671C240.792 87.7118 239.523 81.8745 240.386 76.5702Z" fill="#FFB288"/>
        <path d="M241.553 75.758C242.821 75.5804 241.553 74.7428 242.111 75.8849C242.466 76.6209 242.567 76.824 243.278 77.0778C243.962 77.3062 243.988 77.9407 244.571 78.3721C245.485 79.0574 246.246 78.1183 246.626 77.3569C247.336 75.9103 247.311 74.1591 247.159 72.611C247.032 71.3927 246.626 69.4385 247.818 68.5756C248.808 67.865 250.989 68.8802 252.004 69.1847C253.577 69.6415 255.124 70.1491 256.672 70.6313C260.426 71.7734 264.409 72.7886 268.366 72.2556C271.816 71.7988 275.393 69.9969 277.042 66.7737C279.021 62.9413 275.063 57.9162 271.03 57.9162C269.964 57.9162 268.975 58.3476 267.935 58.5507C267.555 58.6268 267.149 58.6776 266.768 58.5761C265.88 58.3223 265.5 57.3325 264.992 56.5457C263.496 54.2108 260.934 53.5001 258.295 53.7793C255.302 54.1092 252.74 55.5559 249.899 56.3173C248.376 56.7233 247.083 56.3426 245.586 56.3934C244.039 56.4442 242.44 57.4594 241.755 58.906C240.538 57.637 237.798 60.7587 237.138 61.5962C234.145 65.2255 233.206 70.4537 233.13 75.0474C233.054 79.0574 233.739 84.8947 238.128 86.519C239.98 87.2042 241.172 87.5595 241.248 85.8591C240.436 84.0825 240.233 80.5801 240.081 79.1081C239.904 77.6361 240.335 75.8849 241.705 75.3012" fill="#26387A"/>
        <path d="M255.885 92.5085L254.921 102.584L258.245 103.523C258.245 103.523 256.494 108.041 250.71 108.828C238.965 110.401 230.67 101.366 230.67 101.366L234.602 100.605L240.538 81.8745" fill="#FFB288"/>
        <path d="M243.531 75.7073C243.582 74.8697 242.745 74.1845 241.908 74.1591C241.071 74.1337 240.31 74.6413 239.701 75.2251C236.403 78.499 240.538 86.4682 243.785 82.3567" fill="#FFB288"/>
        <path d="M265.677 87.2296C265.145 88.4732 263.065 88.9554 261.923 89.0823C256.393 89.6914 251.801 86.1129 246.525 85.453C245.485 85.3261 244.292 85.25 243.506 85.9352C242.77 86.6205 242.694 87.7372 242.669 88.727C242.643 89.9706 242.618 91.2142 242.745 92.4324C242.846 93.4984 243.049 94.5897 243.607 95.5033C245.992 99.4879 253.957 100.148 258.016 99.9194C259.64 99.8179 261.34 99.5133 262.633 98.5489C264.46 97.1784 264.84 94.7673 265.525 92.6608C266.312 90.0721 265.677 87.2296 265.677 87.2296Z" fill="#26387A"/>
        <path d="M261.745 91.5949C261.568 91.468 261.365 91.3918 261.162 91.3918C258.574 91.3918 257.154 90.7827 255.124 90.6051C254.769 90.5797 254.439 90.5543 254.11 90.6558C253.399 90.8843 252.993 91.6203 252.841 92.3309C252.689 93.0669 253.07 93.6252 253.729 93.9552C254.921 94.5389 256.266 94.7673 257.61 94.7927C258.777 94.8181 260.274 94.9196 261.238 94.1328C261.745 93.7014 262.557 92.1786 261.745 91.5949Z" fill="#FFB288"/>
        <path d="M237.443 237.553C173.136 234.177 135.718 267.399 109.132 327.853C74.6322 406.327 26.408 412.596 4.66772 412.164" stroke="#B4E8FD" stroke-width="20" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M212.785 242.806C215.145 271.181 238.052 286.535 256.393 284.987L286.504 282.475L278.919 191.464L248.808 193.976C230.441 195.499 210.401 214.407 212.785 242.806Z" fill="#FDAE2B"/>
        <path d="M228.285 241.512C230.594 269.252 248.884 290.368 269.127 288.667L302.334 285.901L293.963 185.474L260.756 188.24C240.512 189.915 225.977 213.772 228.285 241.512Z" fill="#EF8417"/>
        <path d="M299.964 286.07C320.208 284.381 334.745 260.535 332.433 232.809C330.121 205.083 311.836 183.977 291.592 185.666C271.348 187.356 256.812 211.202 259.124 238.927C261.435 266.653 279.72 287.76 299.964 286.07Z" fill="#FFC840"/>
        <path d="M294.216 253.923C294.571 258.034 297.641 261.156 301.116 260.851C304.592 260.572 307.128 256.994 306.773 252.857C306.418 248.745 303.349 245.623 299.873 245.928C296.398 246.233 293.861 249.811 294.216 253.923Z" fill="#FDAE2B"/>
        <path d="M291.248 218.34C291.603 222.452 294.673 225.574 298.148 225.269C301.624 224.99 304.16 221.411 303.805 217.275C303.45 213.138 300.381 210.041 296.905 210.346C293.43 210.625 290.893 214.204 291.248 218.34Z" fill="#FDAE2B"/>
        <path d="M298.554 222.985C302.334 222.68 345.485 219.076 345.485 219.076C348.351 218.848 350.482 216.31 350.228 213.391C349.975 210.498 347.463 208.341 344.597 208.595C344.597 208.595 301.243 212.224 297.666 212.503C294.089 212.808 294.774 223.315 298.554 222.985Z" fill="#E2E8ED"/>
        <path d="M301.522 258.592C305.302 258.288 348.453 254.684 348.453 254.684C351.319 254.456 353.45 251.918 353.196 249.024C352.943 246.131 350.431 243.974 347.565 244.228C347.565 244.228 304.211 247.857 300.634 248.136C297.057 248.415 297.742 258.897 301.522 258.592Z" fill="#E2E8ED"/>
        <path d="M185.87 155.754C185.87 155.754 183.536 179.56 194.165 199.357C203.272 216.285 221.334 238.187 235.921 250.268L239.422 249.405C234.348 229.888 218.29 195.27 217.301 182.276C216.312 169.282 216.54 154.232 216.312 146.998" fill="#FFB288"/>
        <path d="M230.67 101.366C230.67 101.366 210.046 102.559 198.174 111.518C177.651 127.025 181.684 168.647 187.646 189.002C193.607 209.356 222.349 239.888 231.634 246.486C233.156 246.486 237.621 246.106 237.468 242.603C232.344 226.741 217.707 184.992 217.707 184.992L220.015 128.674" fill="#D9F0FF"/>
        <path d="M238.026 246.512C240.259 246.74 242.415 247.806 244.292 249.278C246.017 250.623 248.123 252.857 247.895 253.897C247.666 254.989 244.85 252.907 244.521 253.314C244.191 253.72 249.341 261.511 248.351 262.095C247.387 262.679 242.999 255.623 242.999 255.623C242.999 255.623 247.26 263.161 245.611 263.338C244.242 263.465 240.462 256.334 240.462 256.334C240.462 256.334 243.658 262.704 242.567 262.958C241.476 263.186 238.508 256.841 238.508 256.841C238.508 256.841 240.918 262.273 240.005 262.374C239.549 262.425 237.164 259.709 236.175 256.461C235.185 253.237 234.044 250.446 234.932 247.933" fill="#FFB288"/>
        <path d="M223.491 201.59C220.193 192.123 217.707 185.017 217.707 185.017L222.045 137.735" stroke="#94C1F2" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M182.953 149.232C182.826 162.049 184.373 177.835 187.671 189.052C190.538 198.849 198.681 211.006 207.407 221.716" stroke="#94C1F2" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M624.201 79.5903L627.524 77.3316C627.524 77.3316 629.072 70.1238 629.909 68.804C630.797 67.4335 632.522 64.591 634.45 63.2967C635.261 62.7637 638.94 60.6572 639.878 61.5962C640.335 62.0531 638.787 66.2407 638.077 66.9513C637.367 67.6619 635.261 70.8598 635.591 71.875C635.921 72.8901 637.671 70.6821 638.077 70.276C638.483 69.87 639.041 69.337 639.244 69.87C639.574 70.7582 637.697 75.7834 636.251 77.2554C634.779 78.7274 632.42 80.0979 630.771 80.0979L625.622 84.6409" fill="#FFB288"/>
        <path d="M664.155 55.8351C669.635 59.0583 673.034 65.1494 674.353 71.2658C674.582 72.3572 674.784 73.55 675.546 74.3621C676.154 75.022 677.017 75.3519 677.829 75.7326C682.369 77.8391 685.845 81.976 687.443 86.6966C688.128 88.7016 687.595 91.5695 688.382 93.2953C689.346 95.4018 692.948 95.884 694.876 96.5185C700.634 98.422 704.871 104.234 704.947 110.325C704.998 116.391 700.888 122.304 695.155 124.335C696.702 126.492 696.905 129.41 696.195 131.948C695.485 134.512 693.937 136.745 692.161 138.725C684.906 146.948 673.719 151.338 662.76 150.856C659.615 150.729 656.444 150.196 653.526 149.003C650.203 147.658 647.311 145.476 644.521 143.242C641.147 140.552 637.798 137.583 635.972 133.674C634.145 129.766 634.272 124.639 637.342 121.619C633.79 122.355 630.01 120.426 628.006 117.406C626.002 114.386 625.647 110.477 626.459 106.924C627.271 103.396 629.122 100.173 631.304 97.2545C632.826 95.2242 634.576 93.143 634.906 90.6305C635.439 86.4174 633.08 82.6359 632.471 78.4483C631.507 71.9257 631.811 66.3168 637.392 62.8398C639.193 58.1954 641.35 54.5915 646.499 53.221C652.613 51.5459 658.676 52.6119 664.155 55.8351Z" fill="#26387A"/>
        <path d="M650.71 105.452C632.065 100.579 610.731 108.269 605.987 108.269C612.506 101.747 624.455 87.255 628.59 82.2298C628.59 78.7782 626.509 77.8138 626.509 77.8138C626.509 77.8138 575.393 104.259 580.518 121.543C585.87 139.562 635.972 130.73 639.98 135.908" fill="#B6E9FE"/>
        <path d="M654.363 273.287C654.313 274.81 647.641 307.651 647.134 311.179C645.713 321.432 651.116 331.736 650.584 347.649C649.949 366.989 641.73 418.966 640.665 421.276C639.625 423.585 634.602 421.479 634.602 421.479C634.602 421.479 620.142 351.736 619.33 323.209C618.671 299.707 622.07 248.847 622.07 248.847" fill="#FFB288"/>
        <path d="M641.197 422.164C641.197 422.164 642.035 423.94 642.009 424.727C641.908 426.884 641.832 428.382 641.832 428.382L640.893 428.433L640.563 426.656C640.563 426.656 638.052 427.087 635.515 428.763C634.272 429.575 628.082 429.499 627.144 428.991C626.078 428.433 626.383 427.417 626.738 426.986C627.093 426.58 633.79 423.636 633.79 423.636" fill="#26387A"/>
        <path d="M634.627 421.453C634.094 422.773 628.793 425.641 629.275 426.047C629.757 426.428 632.851 426.453 636.251 425.311C639.371 424.27 641.121 423.103 641.223 422.291L640.639 420.108" fill="#FFB288"/>
        <path d="M700.736 274.658C700.736 276.181 695.053 309.199 694.673 312.753C693.557 323.057 699.29 333.183 699.239 349.096C699.188 368.435 692.542 420.666 691.578 423.001C690.614 425.336 685.54 423.382 685.54 423.382C685.54 423.382 668.975 354.121 667.276 325.62C665.88 302.144 667.758 251.207 667.758 251.207" fill="#FFB288"/>
        <path d="M692.136 423.864C692.136 423.864 693.024 425.615 693.024 426.402C692.973 428.559 692.948 430.057 692.948 430.057L692.009 430.158L691.629 428.407C691.629 428.407 689.143 428.915 686.657 430.666C685.439 431.504 679.249 431.63 678.285 431.148C677.194 430.615 677.473 429.6 677.829 429.169C678.184 428.737 684.779 425.615 684.779 425.615" fill="#26387A"/>
        <path d="M685.54 423.357C685.033 424.676 679.833 427.722 680.34 428.103C680.847 428.483 683.917 428.407 687.291 427.164C690.36 426.022 692.085 424.829 692.161 423.991L691.502 421.809" fill="#FFB288"/>
        <path d="M683.891 190.22C683.891 190.22 698.706 199.915 704.338 223.213C711.948 254.608 700.736 316.103 700.736 316.103C700.736 316.103 677.55 322.879 652.309 322.676C627.068 322.473 612.126 313.92 612.075 312.397C612.024 310.874 608.321 264.328 613.445 232.756C619.204 197.301 633.866 187.758 633.866 187.758" fill="#2858AD"/>
        <path d="M663.8 104.843C666.083 103.98 672.045 106.087 683.207 110.35C688.103 115.655 690.259 127.279 691.299 137.557C688.407 162.607 685.185 176.261 686.733 184.713C687.113 186.718 688.94 188.24 688.635 191.032C688.026 196.819 678.59 197.682 660.629 199.712C642.694 201.742 626.535 193.595 627.499 191.057C628.463 188.519 631.532 186.286 632.014 184.586C632.496 182.911 633.968 161.439 632.014 158.902C630.086 156.364 625.063 149.029 625.063 141.897C625.063 131.847 630.594 127.355 634.196 121.822C640.233 112.635 644.8 106.848 650.634 105.427C660.046 103.143 663.8 104.843 663.8 104.843Z" fill="#B6E9FE"/>
        <path d="M633.612 117.533C633.612 117.533 634.272 121.847 632.065 125.426C631.38 126.543 627.321 130.679 626.509 133.319" stroke="#6CB1EF" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M659.513 92.6354C664.409 87.7626 665.195 78.5244 662.963 72.3064C660.452 65.2763 650.457 60.0227 643.557 64.4134C630.036 73.0424 643.76 108.295 659.513 92.6354Z" fill="#FFB288"/>
        <path d="M650.203 91.7725L650.457 106.797C650.457 106.797 649.899 110.147 655.759 110.35C663.8 110.63 663.8 104.843 663.8 104.843L659.133 89.3615" fill="#FFB288"/>
        <path d="M656.291 57.7639C654.642 56.6726 652.867 56.6218 651.167 57.637C650.127 58.2715 649.391 59.2867 648.275 59.7943C647.489 60.1496 646.55 60.2004 645.713 60.4288C641.806 61.4947 638.103 64.4641 636.454 68.1949C634.805 71.9257 635.337 78.0168 638.432 80.707C638.305 77.8138 639.168 73.6515 640.715 71.1897C641.527 69.8953 642.796 68.6264 644.318 68.804C646.017 69.0071 646.931 70.809 648.148 72.0019C650 73.8038 652.664 74.1591 655.099 74.5398C657.864 74.9459 659.995 75.4281 661.365 78.1691C661.948 79.3619 662.278 80.6563 662.608 81.9253C662.988 83.448 663.369 84.9962 663.445 86.5443C663.496 87.5088 663.445 88.5239 663.749 89.4376C664.358 91.2903 666.236 92.407 668.087 93.0415C669.508 93.5237 671.893 93.9552 672.78 92.3563C673.516 91.0111 674.176 89.1077 674.429 87.6103C675.292 82.5598 673.668 77.3316 670.954 72.9917C667.96 68.2457 663.724 65.0478 659.868 61.0886C658.701 59.8704 657.534 58.6014 656.291 57.7639Z" fill="#26387A"/>
        <path d="M660.046 80.8847C660.35 79.7426 661.187 78.5752 662.38 78.3975C666.21 77.8138 665.627 83.5242 664.155 85.5292C663.293 86.6966 661.06 88.2194 660.071 84.9708" fill="#FFB288"/>
        <path d="M700.634 197.885L698.833 201.742C698.833 201.742 692.035 203.367 691.324 204.559C690.183 206.463 688.483 209.052 688.483 210.549C688.483 212.046 688.635 218.569 690.487 218.569C691.654 218.569 694.394 213.239 694.952 212.503C696.271 210.752 701.649 209.483 701.649 204.839L704.947 197.91" fill="#FFB288"/>
        <path d="M663.8 104.64C663.8 104.64 679.655 100.605 692.009 108.422C701.624 114.487 726.383 144.714 726.383 162.023C726.383 173.698 703.222 202.605 703.222 202.605L699.366 199.864C699.366 199.864 701.598 177.149 708.346 164.637C703.374 159.663 684.018 144.257 679.173 139.385" fill="#B6E9FE"/>
        <path d="M676.687 137.76C680.137 140.831 689.269 148.166 689.269 148.166" stroke="#6CB1EF" stroke-width="3.6828" stroke-miterlimit="10"/>
        <path d="M209.312 77.6046L190.16 62.1739C190.16 62.1739 198.759 30.3732 187.369 19.257C181.306 13.3436 173.645 9.3082 165.73 6.31341C148.125 -0.386795 127.07 -1.88419 111.392 8.52144C99.8247 16.2114 92.7724 29.3834 89.6268 42.9361C86.8617 54.8645 87.09 68.4172 94.5228 78.1122C97.8713 82.4775 102.463 85.7515 107.308 88.3148C122.453 96.2586 162.509 99.2534 179.886 87.6042C184.705 84.381 184.274 80.0157 186.304 73.1886L209.312 77.6046Z" fill="#E7F5FE"/>
        <path d="M107.834 40.96C107.834 39.9566 108.009 39.07 108.359 38.3C108.709 37.53 109.176 36.8883 109.759 36.375C110.319 35.885 110.949 35.5116 111.649 35.255C112.372 34.9983 113.096 34.87 113.819 34.87C114.542 34.87 115.254 34.9983 115.954 35.255C116.677 35.5116 117.331 35.885 117.914 36.375C118.474 36.8883 118.929 37.53 119.279 38.3C119.629 39.07 119.804 39.9566 119.804 40.96V54.12C119.804 55.17 119.629 56.0683 119.279 56.815C118.929 57.5616 118.474 58.18 117.914 58.67C117.331 59.1833 116.677 59.5683 115.954 59.825C115.254 60.0816 114.542 60.21 113.819 60.21C113.096 60.21 112.372 60.0816 111.649 59.825C110.949 59.5683 110.319 59.1833 109.759 58.67C109.176 58.18 108.709 57.5616 108.359 56.815C108.009 56.0683 107.834 55.17 107.834 54.12V40.96ZM111.404 54.12C111.404 54.9833 111.637 55.625 112.104 56.045C112.594 56.4416 113.166 56.64 113.819 56.64C114.472 56.64 115.032 56.4416 115.499 56.045C115.989 55.625 116.234 54.9833 116.234 54.12V40.96C116.234 40.0966 115.989 39.4666 115.499 39.07C115.032 38.65 114.472 38.44 113.819 38.44C113.166 38.44 112.594 38.65 112.104 39.07C111.637 39.4666 111.404 40.0966 111.404 40.96V54.12Z" fill="#B5DDF7"/>
        <path d="M122.421 48.205C122.421 47.4816 122.468 46.8166 122.561 46.21C122.655 45.6033 122.83 45.0666 123.086 44.6C123.506 43.83 124.101 43.2116 124.871 42.745C125.665 42.2783 126.621 42.045 127.741 42.045C128.861 42.045 129.806 42.2783 130.576 42.745C131.37 43.2116 131.976 43.83 132.396 44.6C132.653 45.0666 132.828 45.6033 132.921 46.21C133.015 46.8166 133.061 47.4816 133.061 48.205V54.05C133.061 54.7733 133.015 55.4383 132.921 56.045C132.828 56.6516 132.653 57.1883 132.396 57.655C131.976 58.425 131.37 59.0433 130.576 59.51C129.806 59.9766 128.861 60.21 127.741 60.21C126.621 60.21 125.665 59.9766 124.871 59.51C124.101 59.0433 123.506 58.425 123.086 57.655C122.83 57.1883 122.655 56.6516 122.561 56.045C122.468 55.4383 122.421 54.7733 122.421 54.05V48.205ZM125.991 54.61C125.991 55.2866 126.143 55.8 126.446 56.15C126.773 56.4766 127.205 56.64 127.741 56.64C128.278 56.64 128.698 56.4766 129.001 56.15C129.328 55.8 129.491 55.2866 129.491 54.61V47.645C129.491 46.9683 129.328 46.4666 129.001 46.14C128.698 45.79 128.278 45.615 127.741 45.615C127.205 45.615 126.773 45.79 126.446 46.14C126.143 46.4666 125.991 46.9683 125.991 47.645V54.61Z" fill="#B5DDF7"/>
        <path d="M135.375 48.205C135.375 47.4816 135.422 46.8166 135.515 46.21C135.609 45.6033 135.784 45.0666 136.04 44.6C136.46 43.83 137.055 43.2116 137.825 42.745C138.619 42.2783 139.575 42.045 140.695 42.045C141.815 42.045 142.76 42.2783 143.53 42.745C144.324 43.2116 144.93 43.83 145.35 44.6C145.607 45.0666 145.782 45.6033 145.875 46.21C145.969 46.8166 146.015 47.4816 146.015 48.205V54.05C146.015 54.7733 145.969 55.4383 145.875 56.045C145.782 56.6516 145.607 57.1883 145.35 57.655C144.93 58.425 144.324 59.0433 143.53 59.51C142.76 59.9766 141.815 60.21 140.695 60.21C139.575 60.21 138.619 59.9766 137.825 59.51C137.055 59.0433 136.46 58.425 136.04 57.655C135.784 57.1883 135.609 56.6516 135.515 56.045C135.422 55.4383 135.375 54.7733 135.375 54.05V48.205ZM138.945 54.61C138.945 55.2866 139.097 55.8 139.4 56.15C139.727 56.4766 140.159 56.64 140.695 56.64C141.232 56.64 141.652 56.4766 141.955 56.15C142.282 55.8 142.445 55.2866 142.445 54.61V47.645C142.445 46.9683 142.282 46.4666 141.955 46.14C141.652 45.79 141.232 45.615 140.695 45.615C140.159 45.615 139.727 45.79 139.4 46.14C139.097 46.4666 138.945 46.9683 138.945 47.645V54.61Z" fill="#B5DDF7"/>
        <path d="M156.309 60V48.73H156.239L152.284 60H148.644V42.255H152.004V53.525H152.074L155.994 42.255H159.669V60H156.309ZM152.389 35.675C152.413 35.815 152.424 35.99 152.424 36.2C152.448 36.3866 152.506 36.585 152.599 36.795C152.716 36.9816 152.891 37.145 153.124 37.285C153.358 37.425 153.696 37.495 154.139 37.495C154.583 37.495 154.921 37.425 155.154 37.285C155.388 37.145 155.551 36.9816 155.644 36.795C155.761 36.585 155.819 36.3866 155.819 36.2C155.843 35.99 155.866 35.815 155.889 35.675H158.864C158.748 36.9816 158.316 37.985 157.569 38.685C156.846 39.385 155.703 39.735 154.139 39.735C152.576 39.735 151.421 39.385 150.674 38.685C149.951 37.9616 149.531 36.9583 149.414 35.675H152.389Z" fill="#B5DDF7"/>
        <path d="M162.624 60V56.43H166.194V60H162.624Z" fill="#B5DDF7"/>
        <path d="M169.118 60V56.43H172.688V60H169.118Z" fill="#B5DDF7"/>
        <path d="M175.612 60V56.43H179.182V60H175.612Z" fill="#B5DDF7"/>
      </g>
      <defs>
        <clipPath id="clip0_1219_1434">
          <rect width="850" height="453" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
</ng-container>

<ng-template #appContent>
  <ng-container *ngIf="currentRoute !== 'not-found'">
    <div class="player player_route_{{ currentRoute }}"
         [class.player_expanded]="playerOpened"
         (click)="onCloseVideoHandler($event)"
    >
      <div class="player__box" appFixedInFrame [top]="'0px'" [applyOnDesktop]="false">
        <div class="player__splash" [class.hidden]="isTranslation">
          <div *ngIf="!isPreTranslation" class="player__info">
            <div class="player__draw">{{ 'pik4.draw-no' | translate }}{{ (playingDraw?.number || '') | onlyNumber }}</div>
            <time class="player__date">{{ playingDraw?.game_end | date:'dd.MM.YYYY' }}</time>
          </div>
          <div *ngIf="isPreTranslation" class="player__pre">
            <div class="player__title">{{ 'pik4.translation-starts' | translate }}</div>
            <time class="player__countdown">{{ videoButtonTime }}</time>
          </div>
          <svg class="player__pic" viewBox="0 0 1920 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1660_568)">

              <path d="M960 1290C1374.21 1290 1710 954.214 1710 540C1710 125.786 1374.21 -210 960 -210C545.786 -210 210 125.786 210 540C210 954.214 545.786 1290 960 1290Z" fill="white"/>
              <path d="M961 1248C1352.57 1248 1670 930.57 1670 539C1670 147.43 1352.57 -170 961 -170C569.43 -170 252 147.43 252 539C252 930.57 569.43 1248 961 1248Z" fill="#95C700"/>
              <path d="M960 1080C1258.23 1080 1500 838.234 1500 540C1500 241.766 1258.23 0 960 0C661.766 0 420 241.766 420 540C420 838.234 661.766 1080 960 1080Z" fill="#FF6666"/>
              <path d="M960 957C1190.3 957 1377 770.303 1377 540C1377 309.697 1190.3 123 960 123C729.697 123 543 309.697 543 540C543 770.303 729.697 957 960 957Z" fill="#0044CC"/>
              <g *ngIf="!isPreTranslation" clip-path="url(#clip1_1660_568)">
                <path d="M1389.64 183.729L697.379 236.682L656.833 208.341L509.527 219.155L486.092 409.338L528.126 439.17L686.219 426.865L669.852 557.755L851.381 666.271L1171.66 644.269L1191 491.75L1336.82 484.665L1352.44 360.487L1417.54 354.893L1433.91 215.799L1389.64 183.729Z" fill="#FFCC00" stroke="#003399" stroke-width="12" stroke-miterlimit="10"/>
                <path d="M647.905 267.633L632.282 379.878L599.175 382.489L612.195 291.499L579.832 293.737C579.088 292.618 577.972 292.245 576.484 292.245C575.368 292.245 574.624 292.618 573.508 293.737C572.764 294.482 572.392 295.601 572.392 296.72C572.392 297.839 572.764 298.957 573.508 299.33C574.252 300.076 575.368 300.449 576.484 300.449C577.972 300.449 579.46 299.33 580.204 297.839L593.223 296.72L580.948 384.353L547.841 386.963L560.117 295.601L547.469 296.347L549.701 283.295L562.349 282.549V280.685L595.083 278.075V279.939L614.054 278.447L614.798 270.244L647.905 267.633Z" fill="#003399"/>
                <path d="M720.814 290.753L714.49 303.059L692.171 304.551L672.456 305.67C671.712 304.551 670.596 304.178 669.48 304.178C668.364 304.178 667.62 304.551 666.876 305.297C666.132 306.043 665.76 306.788 665.76 307.907C665.76 309.026 666.132 309.772 666.876 310.518C667.62 311.263 668.364 311.636 669.48 311.263C670.968 311.263 672.084 310.518 672.828 308.653L684.36 307.907L681.012 329.536L708.911 327.298V339.231L679.152 341.469L678.036 349.673L709.283 347.435L708.911 359.368L646.417 364.216L649.393 344.079L646.045 344.452V332.519L651.253 332.146L656.461 295.974L720.814 290.753Z" fill="#003399"/>
                <path d="M803.767 284.787L797.071 333.265C792.235 333.638 785.167 334.011 775.496 334.756C765.824 335.502 758.756 335.875 753.921 336.248L749.829 364.216L720.07 366.454L730.858 290.008C743.133 289.262 755.409 288.143 767.312 287.397L803.767 284.787ZM772.148 298.584L758.012 299.703L746.853 300.449C746.109 299.33 744.993 298.584 743.877 298.584C742.761 298.584 742.017 298.957 741.273 299.703C740.529 300.449 740.157 301.195 740.157 302.314C740.157 303.432 740.529 304.178 741.273 304.924C742.017 305.67 742.761 306.043 743.877 305.67C745.365 305.67 746.481 304.924 746.853 303.805L758.384 302.686L755.409 324.315L768.428 323.196L772.148 298.584Z" fill="#003399"/>
                <path d="M878.535 279.193L871.84 291.499L849.521 292.991L829.805 294.11C829.061 292.991 827.945 292.618 826.83 292.618C825.714 292.618 824.97 292.991 824.226 293.737C823.482 294.482 823.11 295.228 823.11 296.347C823.11 297.466 823.482 298.212 824.226 298.957C824.97 299.703 825.714 300.076 826.83 299.703C828.317 299.703 829.433 298.957 830.177 297.093L841.709 296.347L838.361 317.976L866.26 315.738V327.671L836.501 329.909L835.385 338.113L866.632 335.875L866.26 347.808L803.767 352.656L806.742 332.519L803.395 332.892V320.959L808.602 320.586L813.81 284.414L878.535 279.193Z" fill="#003399"/>
                <path d="M967.44 285.16L950.7 324.315L948.097 341.469L927.265 342.96L930.241 323.196L922.43 287.024L903.83 288.143C903.086 287.024 901.97 286.651 900.854 286.651C899.739 286.651 898.995 287.024 898.251 287.77C897.507 288.516 897.135 289.635 897.135 290.381C897.135 291.499 897.507 292.245 898.251 292.991C898.995 293.737 899.739 294.11 900.854 294.11C902.342 294.11 903.458 293.364 903.83 292.245L915.734 291.126L908.294 344.825L878.535 347.062L888.579 278.447L933.589 275.091L941.773 292.991L966.324 272.481L1004.64 269.498L993.479 345.944L963.72 348.181L973.019 283.295L967.44 285.16Z" fill="#003399"/>
                <path d="M1090.19 263.531C1088.33 275.091 1086.85 286.279 1084.99 297.839L1079.78 332.146L1006.5 337.367L1016.54 268.752L1090.19 263.531ZM1058.58 277.329C1053.74 277.702 1048.9 278.075 1044.44 278.447C1042.95 278.447 1041.09 278.82 1038.49 278.82C1035.88 278.82 1034.02 278.82 1032.54 279.193C1031.79 278.075 1030.68 277.702 1029.56 277.702C1028.45 277.702 1027.7 278.075 1026.96 278.82C1026.21 279.566 1025.84 280.312 1025.84 281.431C1025.84 282.549 1026.21 283.295 1026.96 284.041C1027.7 284.787 1028.45 285.16 1029.56 284.787C1031.05 284.787 1032.16 284.041 1032.91 282.549L1044.07 281.431L1038.12 322.823L1052.25 321.705L1058.58 277.329Z" fill="#003399"/>
                <path d="M1215.93 253.836L1212.58 273.6L1193.98 297.466L1208.11 306.416L1205.88 322.823L1176.12 325.061L1179.1 304.924L1164.96 306.043L1153.8 306.416C1153.06 305.297 1151.94 304.924 1150.83 304.924C1149.71 304.924 1148.97 305.297 1148.22 306.043C1147.48 306.788 1147.11 307.534 1147.11 308.653C1147.11 309.772 1147.48 310.518 1148.22 311.263C1148.97 312.009 1149.71 312.382 1150.83 312.009C1152.32 312.009 1153.06 311.263 1153.8 310.145L1165.71 308.653L1163.48 325.434L1133.72 327.671L1144.13 250.107L1173.89 247.869L1167.57 296.347L1182.45 283.295L1186.54 255.7L1215.93 253.836Z" fill="#003399"/>
                <path d="M1137.81 307.907L1123.67 309.026L1120.7 328.79L1090.94 331.027L1093.17 314.992L1109.91 303.805L1098.38 280.685L1100.98 263.158L1131.11 260.921L1126.65 288.516L1138.18 297.839" fill="#003399"/>
                <path d="M1302.6 247.496L1292.55 316.111L1262.8 318.349L1265.77 298.212L1240.48 299.703C1239.73 298.584 1238.62 298.212 1237.5 298.212C1236.38 298.212 1235.64 298.584 1234.9 299.33C1234.15 300.076 1233.78 300.822 1233.78 301.941C1233.78 303.059 1234.15 303.805 1234.9 304.551C1235.64 305.297 1236.38 305.297 1237.5 305.297C1238.99 305.297 1240.1 304.551 1240.85 302.686L1252.38 301.941L1250.15 319.094L1220.39 321.332L1222.99 301.195L1220.02 301.568V289.635L1224.48 289.262L1229.69 252.717L1259.45 250.479L1254.24 287.024L1267.26 285.906L1272.84 249.361L1302.6 247.496Z" fill="#003399"/>
                <path d="M1386.67 241.53L1376.99 310.145L1347.24 312.382L1350.21 292.245L1335.7 293.364L1332.73 313.128L1302.97 315.365L1313.01 247.123L1386.67 241.53ZM1355.05 255.7L1340.54 256.819L1329.01 257.565C1328.26 256.446 1327.15 256.073 1326.03 256.073C1324.92 256.073 1324.17 256.446 1323.43 257.192C1322.68 257.938 1322.31 258.683 1322.31 259.802C1322.31 260.921 1322.68 261.667 1323.43 262.412C1324.17 263.158 1324.92 263.531 1326.03 263.158C1327.52 263.158 1328.26 262.412 1329.01 260.921L1340.17 260.175L1337.19 281.431L1351.7 280.312L1355.05 255.7Z" fill="#003399"/>
                <path d="M1114.37 373.166L1084.99 595.046L989.759 605.114L998.686 543.957L860.308 557.009L888.207 405.982L980.087 400.015L964.092 499.954L931.357 502.192C929.125 498.09 926.149 496.225 921.686 496.225C918.338 496.598 915.734 497.717 913.502 499.954C911.27 502.192 910.154 505.175 910.154 508.531C910.154 511.887 911.27 514.498 913.502 516.362C915.734 518.6 918.338 519.346 921.686 519.346C926.149 518.973 929.497 517.108 931.729 513.379C936.565 513.006 941.401 512.633 946.608 511.887C951.444 511.142 956.652 510.769 961.488 510.396L1005.01 507.413L1023.24 379.132L1114.37 373.166Z" fill="#003399"/>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_1660_568">
                <rect width="1920" height="1080" fill="white"/>
              </clipPath>
              <clipPath id="clip1_1660_568">
                <rect width="956" height="490" fill="white" transform="translate(482 180)"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="player__player" [class.hidden]="!isTranslation">
          <iframe #mediaFrame src="/mediaplayer/" frameborder="0" scrolling="no" class="player__embedded"></iframe>
        </div>
        <div class="player__controls">
          <button *ngIf="!playerOpened && (isTranslation || isPreTranslation)"
                  class="player__control player__control_size_full"
                  (click)="onShowVideoHandler()"
          ></button>
          <button *ngIf="playerOpened" class="player__control player__control_size_part"
                  (click)="onCloseVideoHandler($event)"
          ></button>
        </div>
      </div>
    </div>

    <button type="button"
            class="show-video show-video_route_{{ currentRoute }}"
            appFixedInFrame
            [top]="'140px'"
            [class.show-video_visible]="(isPreTranslation || isTranslation) && !playerOpened"
            (click)="onShowVideoHandler()"
    >
      <span class="show-video__status"></span>
      <svg class="show-video__icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.9444 5.19442L14.4444 7.16665V4.44442C14.4444 4.14974 14.3274 3.86712 14.119 3.65875C13.9106
                3.45038 13.628 3.33331 13.3333 3.33331H3.33334C2.74397 3.33331 2.17874 3.56744 1.76199 3.98419C1.34524
                4.40093 1.11111 4.96617 1.11111 5.55554V14.4444C1.11111 15.0338 1.34524 15.599 1.76199 16.0158C2.17874
                16.4325 2.74397 16.6666 3.33334 16.6666H13.3333C13.628 16.6666 13.9106 16.5496 14.119 16.3412C14.3274
                16.1328 14.4444 15.8502 14.4444 15.5555V12.8222L17.9444 14.7944C18.0342 14.8531 18.1379 14.8871 18.245
                14.893C18.3521 14.8989 18.4589 14.8766 18.5546 14.8281C18.6503 14.7797 18.7316 14.707 18.7903
                14.6172C18.8489 14.5274 18.883 14.4237 18.8889 14.3166V5.66665C18.882 5.56002 18.8474 5.45706 18.7883
                5.36802C18.7293 5.27899 18.6479 5.20699 18.5523 5.15921C18.4568 5.11143 18.3504 5.08953 18.2437
                5.09571C18.137 5.10189 18.0339 5.13593 17.9444 5.19442Z" fill="white"/>
      </svg>
      <time *ngIf="isPreTranslation" class="show-video__time">{{ videoButtonTime }}</time>
      <ng-container *ngIf="isTranslation">
        <span class="show-video__text">{{ 'pik4.translation-started' | translate }}</span>
        <svg class="show-video__fullscreen" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 23H1L1 15" stroke="white" stroke-width="2"/>
          <path d="M23 15V23H15" stroke="white" stroke-width="2"/>
          <path d="M1 9L1 1H9" stroke="white" stroke-width="2"/>
          <path d="M15 1H23V9" stroke="white" stroke-width="2"/>
        </svg>
      </ng-container>
    </button>
  </ng-container>

  <app-main-menu class="app-menu" data-cy="main-menu"></app-main-menu>

  <div class="app-main">
    <router-outlet></router-outlet>
  </div>
</ng-template>
