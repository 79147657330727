import {ApiAction} from "@app/core/enums/api-action";
import {ResponseCode} from "@app/core/enums/response-code";
import {MOCK_SEQ} from "@app/core/mocks/utils";

export const getLottData = [
  {
    request: {
      action: ApiAction.getLottData,
      lottCode: 30,
      seq: MOCK_SEQ
    },
    response: {
      action: ApiAction.getLottData,
      R: ResponseCode.Ok,
      betTypes: [
        {
          "table": 1,
          "bt_code": "1",
          "bt_coef": "1000"
        },
        {
          "table": 1,
          "bt_code": "1",
          "bt_coef": "40"
        },
        {
          "table": 1,
          "bt_code": "1",
          "bt_coef": "3"
        },
        {
          "table": 1,
          "bt_code": "1",
          "bt_coef": "1"
        },
        {
          "table": 2,
          "bt_code": "2R4",
          "bt_coef": "7000"
        },
        {
          "table": 2,
          "bt_code": "2R3",
          "bt_coef": "200"
        },
        {
          "table": 2,
          "bt_code": "2R2",
          "bt_coef": "14"
        },
        {
          "table": 2,
          "bt_code": "2R1",
          "bt_coef": "2.3"
        },
        {
          "table": 2,
          "bt_code": "2R3+",
          "bt_coef": "185"
        },
        {
          "table": 2,
          "bt_code": "2R2+",
          "bt_coef": "13"
        },
        {
          "table": 2,
          "bt_code": "2R1+",
          "bt_coef": "2"
        },
        {
          "table": 2,
          "bt_code": "2B4",
          "bt_coef": "430"
        },
        {
          "table": 2,
          "bt_code": "2B3",
          "bt_coef": "27"
        },
        {
          "table": 2,
          "bt_code": "2B2",
          "bt_coef": "4.5"
        },
        {
          "table": 2,
          "bt_code": "2B1",
          "bt_coef": "1.7"
        },
        {
          "table": 2,
          "bt_code": "2B0",
          "bt_coef": "1.7"
        },
        {
          "table": 2,
          "bt_code": "2B3+",
          "bt_coef": "25"
        },
        {
          "table": 2,
          "bt_code": "2B2+",
          "bt_coef": "3.8"
        },
        {
          "table": 2,
          "bt_code": "2B1+",
          "bt_coef": "1.2"
        },
        {
          "table": 2,
          "bt_code": "2Y4",
          "bt_coef": "85"
        },
        {
          "table": 2,
          "bt_code": "2Y3",
          "bt_coef": "9"
        },
        {
          "table": 2,
          "bt_code": "2Y2",
          "bt_coef": "2.6"
        },
        {
          "table": 2,
          "bt_code": "2Y1",
          "bt_coef": "1.7"
        },
        {
          "table": 2,
          "bt_code": "2Y0",
          "bt_coef": "2.9"
        },
        {
          "table": 2,
          "bt_code": "2Y3+",
          "bt_coef": "8"
        },
        {
          "table": 2,
          "bt_code": "2Y2+",
          "bt_coef": "2"
        },
        {
          "table": 2,
          "bt_code": "2G4",
          "bt_coef": "27"
        },
        {
          "table": 2,
          "bt_code": "2G3",
          "bt_coef": "4.5"
        },
        {
          "table": 2,
          "bt_code": "2G2",
          "bt_coef": "2"
        },
        {
          "table": 2,
          "bt_code": "2G1",
          "bt_coef": "2"
        },
        {
          "table": 2,
          "bt_code": "2G0",
          "bt_coef": "5.5"
        },
        {
          "table": 2,
          "bt_code": "2G3+",
          "bt_coef": "3.8"
        },
        {
          "table": 2,
          "bt_code": "2G2+",
          "bt_coef": "1.3"
        },
        {
          "table": 2,
          "bt_code": "2O4",
          "bt_coef": "20"
        },
        {
          "table": 2,
          "bt_code": "2O3",
          "bt_coef": "2.6"
        },
        {
          "table": 2,
          "bt_code": "2O2",
          "bt_coef": "2.3"
        },
        {
          "table": 3,
          "bt_code": "3R1",
          "bt_coef": "7"
        },
        {
          "table": 3,
          "bt_code": "3R2",
          "bt_coef": "7"
        },
        {
          "table": 3,
          "bt_code": "3R3",
          "bt_coef": "7"
        },
        {
          "table": 3,
          "bt_code": "3R4",
          "bt_coef": "7"
        },
        {
          "table": 3,
          "bt_code": "3B1",
          "bt_coef": "3.5"
        },
        {
          "table": 3,
          "bt_code": "3B2",
          "bt_coef": "3.5"
        },
        {
          "table": 3,
          "bt_code": "3B3",
          "bt_coef": "3.5"
        },
        {
          "table": 3,
          "bt_code": "3B4",
          "bt_coef": "3.5"
        },
        {
          "table": 3,
          "bt_code": "3Y1",
          "bt_coef": "2.3"
        },
        {
          "table": 3,
          "bt_code": "3Y2",
          "bt_coef": "2.3"
        },
        {
          "table": 3,
          "bt_code": "3Y3",
          "bt_coef": "2.3"
        },
        {
          "table": 3,
          "bt_code": "3Y4",
          "bt_coef": "2.3"
        },
        {
          "table": 3,
          "bt_code": "3G1",
          "bt_coef": "1.7"
        },
        {
          "table": 3,
          "bt_code": "3G2",
          "bt_coef": "1.7"
        },
        {
          "table": 3,
          "bt_code": "3G3",
          "bt_coef": "1.7"
        },
        {
          "table": 3,
          "bt_code": "3G4",
          "bt_coef": "1.7"
        },
        {
          "table": 4,
          "bt_code": "2B2Y",
          "bt_coef": "31"
        }
      ],
      lottCode: 30,
      seq: MOCK_SEQ
    }
  }
];
