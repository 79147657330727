import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { IConfig } from '@app/core/interfaces/i-config';
import { APP_CONFIG } from '@app/core/utils';
import { environment } from './environments/environment';
import 'hammerjs';

/**
 * Промис для получения конфигурации приложения
 */
const configPromise = environment.production
  ? fetch('/fdl/app-config.json').then((r) => r.json())
  : new Promise((resolve) => { resolve(environment); });

configPromise
  .then((config: IConfig) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: config }
    ]).bootstrapModule(AppModule)
      // eslint-disable-next-line no-console
      .catch((err) => console.error(err));
  });
